import React, { useEffect, useState } from 'react'
import {
	Tabs, Tab, Box, Typography, Checkbox, Grid, Card, CardContent, CardActions, Icon, Button, FormControl, Avatar, Input, InputLabel, TextField, Paper, Dialog, DialogTitle, DialogContent, DialogActions
  } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import axios from "@fuse/utils/axios";
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab';
import ActivitieLogs from '@fuse/components/ActivitieLogs';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations'
import { useRouter } from '@fuse/hooks';
import SubscriptionTab from "./tabs/SubscriptionTab"
import AccessoriesTab from "./tabs/AccessoriesTab";
import AssetsTab from "./tabs/AssetsTab"
import AddressTab from "./tabs/AddressTab"
import InfoTab from './tabs/InfoTab';
import headerImg from "./header-pattern.png";
import QuickAddUser from 'app/fuse-layouts/shared-components/QuickAddUser';
import { closeFormViewerDialog, openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import lodash from "lodash"
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';

const useStyle = makeStyles({
	root: {
		padding: "20px"
	},
	header: {
		height: '10px',
		minHeight: '0px'
	},
	topBg: {
		background: '#fcfcfc'
	},
	headerBg: {
		height: "100px",
		background: `url(${headerImg})`,
		backgroundSize: "cover"
		// background:"",
	},
	activeTab: {
		// borderBottom: "2px solid",
		// borderBottomLeftRadius: "0px !important",
		// borderBottomRightRadius: "0px !important",
		background: "rgba(107, 44, 87, 0.04);",
		borderRadius: "0",
	},
	userImg: {
		width: "100px", height: "100px", borderRadius: "50%", objectFit: "cover", transform: "translateY(25%)"
	}
})

function TabButton(props) {
	const { activeClass, tab, tabState, tabStateChange, ...restProps } = props;
	const isActive = tab == tabState;

	return (
		<Button {...restProps} onClick={() => tabStateChange(tab)} className={restProps.className && isActive ? activeClass : ""}>
			{props.children}
		</Button>
	)
}

function TabContainer(props) {
	const { tabState, tab } = props;
	const shouldRender = tab == tabState;
	return shouldRender ? props.children : <></>
}

const UserDetailsDialog = ({ }) => {
	const router = useRouter();
	const classes = useStyle();
	const [tabState, setTabState] = useState("information");
	const [userDetails, setUserDetails] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [logs, setLogs] = useState([]);
	const dispatch = useDispatch()
	const openState = useSelector((state) => state.usersApp.formViewDialog)
	const [userTabValue, setUserTabValue] = useState(0);
	const { open: openAddUserForm } = useSelector((state) => state.usersApp.formViewDialog)

	const handleChangeUserTabValue = (event, newValue) => {
		setUserTabValue(newValue);
	};

	function getUserInfo(){
		axios.get(`/end-user/view-client/${router.params.id}`)
			.then(results=>{
				setUserDetails(formatInvoices(results.data.data.client))
				setImageUrl(results.data.data?.imageUrl)
			}
		)
	}

	function formatInvoices(item){
			return {
				miltiple_role: item.multiple_role_end_users.map((item)=>{
					return {id:item.id,name:item.roles_end_user?.display_name}
				}),
				original_info:{
					phone: item.phone,
					emp_no: item.emp_no,
					address: item.address,
					address_2: item.address_2,
					city: item.city,
					state: item.state,
					zip: item.zip,
					country: item.country,
					start_date: item.start_date,
				},
				user_start_date: Boolean(item.start_date) ? dateReformatTo_mmm_dd_yyyy(item.start_date) : "N/A",
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
					"sales_lead_name",
					"is_set_password",
					"photoURL",
					"password_reset_id",
					"reset_token",
					"microsoft_id",
					"google_id",
					"portal_user",
					"company_id",
					"user_companies_id",
					"identifier",
					"avatar",
					"name"
				]),
				miltiple_role_name: (()=>{
					let role_name = ''
					item.multiple_role_end_users.forEach((data)=>{
						role_name += `, ${data.roles_end_user?.display_name || ''}`
					})
					// item.multiple_role_end_users.forEach((item)=>{
					// 	role_name += item.roles_end_user?display_name
					// })
					role_name = role_name.slice(1)
					return role_name
					
				})(),
				role_name: item.multiple_role_end_users.roles_end_user ? item.multiple_role_end_users.roles_end_user?.display_name : 'N/A',
				department_name: item.department ? item.department.department_name : 'N/A',
				last_accessed: item.last_login ? formatDateTo_MM_DD_YYYY_HH_MM_SS(item.last_login) : 'Not accessed',
				source_ :item.source || 'No Source',
				assigned_user :item.assigned_user,
				address :getLocation(item)
			}
	}

	useEffect(() => {
		getUserInfo()
	}, [])

	function formatLogs(data) {
		let {length} = data
		return data.map((item) => {
			const obj = {
				id: item.id,
				action_id: `act${length}`,
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
			}
			length--;
			return obj
		})
	}

	useEffect(() => {
		setLoading(true)
		axios.get(`/user/fetch-user-activities/${router.params.id}`)
			.then((res) => {
				setLogs(formatLogs(res.data.data.activities));
			}).catch((err) => {
				console.log('err', err);
			}).finally(() => {
				setLoading(false)
			})
	}, [userDetails]);

	function getLocation(userDetails) {
		let final_loc = ''
		if (userDetails?.address) {
			final_loc += userDetails?.address
		}
		if (userDetails?.city) {
			final_loc += `, ${userDetails?.city}`
		}
		if (userDetails?.state) {
			final_loc += `, ${userDetails?.state}`
		}
		if (userDetails?.zip) {
			final_loc += `, ${userDetails?.zip}`
		} if (userDetails?.country) {
			final_loc += `, ${userDetails?.country}`
		}
		if (final_loc[0] == ',') {
			return final_loc.slice(1)
		}
		return final_loc
	}

	const handelEdit = async (data) => {
        dispatch(setFromData(userDetails));
        dispatch(openFormViewerDialog());
    };

	function handleAddUserFormClose(){
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
		getUserInfo()
	}

	return (
		<>
			{/* <div style={{ background: "#F5F5F5", padding: "5px" }}>
				<Card>
					<div style={{ height: "150px", background: "" }}>
						<div className={classes.headerBg} >
							<Grid container alignItems='center' style={{ alignItems: "center", height: "100%" }}>
								<Grid item lg={2} style={{ color: "#fff", display: "flex", justifyContent: "center" }}>
									{
										loading ? <><Skeleton variant="circle" style={{ background: "#efefef" }} className={classes.userImg} width={100} height={100} /></> : <>
											<img className={classes.userImg} src={imageUrl || 'assets/images/avatars/profile.jpg'} alt="loading..." style={{}} /></>
									}
								</Grid>
								<Grid item lg={10} style={{ color: "#fff" }}>
									{
										loading ? <><Skeleton variant="text" animation='wave' width={250} height={25} style={{ background: "#efefef" }} /></> : <><Typography variant='h6'>{`${userDetails?.first_name || 'N/A'} ${userDetails?.last_name || 'N/A'}`}</Typography></>
									}
									{
										loading ? <><Skeleton variant="text" animation='wave' width={150} height={20} style={{ background: "#efefef" }} /></> : <><Typography variant='subtitle2'>{userDetails?.title || 'N/A'}</Typography></>
									}
								</Grid>
							</Grid>
							<Grid container style={{ color: "#000", height: "50%", display: "flex", alignItems: "center" }}>
								<Grid item lg={2} style={{ color: "#fff", display: "flex", justifyContent: "center" }}>
									''
								</Grid>
								<Grid item lg={10} style={{ color: "#000", height: "50%", display: "flex", alignItems: "center" }}>
									<Grid container>
										<Grid lg={6}>
											{
												loading ? <><Skeleton variant="text" animation='wave' width={280} height={20} /></> : <>
													<Typography variant='body1' style={{ display: "flex", alignItems: "center" }}>
														<Icon>
															<span className="material-symbols-outlined mr-10" style={{ fontSize: "21px" }}>
																mail
															</span>
														</Icon>
														{userDetails?.email}
													</Typography></>
											}
										</Grid>
										<Grid lg={6}>
											{
												loading ? <><Skeleton variant="text" animation='wave' width={280} height={20} /></> : <>
													<Typography variant='body1' style={{ display: "flex", alignItems: "center" }}>
														<Icon>
															<span className="material-symbols-outlined" style={{ fontSize: "21px" }}>
																location_on
															</span>
														</Icon>
														{(userDetails?.address || userDetails?.city || userDetails?.state || userDetails?.city || userDetails?.country) ? getLocation(userDetails) : 'N/A'}
													</Typography>
												</>
											}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</div>
				</Card>

				<Grid container style={{ marginTop: "30px", background: "#fff" }}>
					<Grid item lg={3}>
						<Card style={{ borderRadius: '0', boxShadow: "none" }}>
							<CardActions disableSpacing style={{ display: "flex", flexDirection: "column", borderRadius: '0', boxShadow: 'none' }}>
								<TabButton
									tab="information"
									tabState={tabState}
									tabStateChange={setTabState}
									activeClass={classes.activeTab}
									variant="text"
									color="primary"
									className={`w-full d-flex justify-content-start ${classes.tabButton}`}
									style={{ width: "100%", margin: "5px 0", display: "flex", justifyContent: "start" }}
								>
									My Information
								</TabButton>
								<TabButton
									tab="address"
									tabState={tabState}
									tabStateChange={setTabState}
									activeClass={classes.activeTab}
									variant="text"
									color="primary"
									className={`w-full d-flex justify-content-start ${classes.tabButton}`}
									style={{ width: "100%", display: "flex", justifyContent: "start", margin: "5px 0" }}
								>
									Address
								</TabButton>
								<TabButton
									tab="assets"
									tabState={tabState}
									tabStateChange={setTabState}
									activeClass={classes.activeTab}
									variant="text"
									color="primary"
									className={`w-full d-flex justify-content-start ${classes.tabButton}`}
									style={{ width: "100%", display: "flex", justifyContent: "space-between", margin: "5px 0" }}
								>
									Assets
									<Badge badgeContent={userDetails?.assigned_user?.length} color="primary" />
								</TabButton>
								< TabButton
									tab="Accessories"
									tabState={tabState}
									tabStateChange={setTabState}
									activeClass={classes.activeTab}
									variant="text"
									color="primary"
									className={`w-full d-flex justify-content-start ${classes.tabButton}`}
									style={{ width: "100%", display: "flex", justifyContent: "space-between", margin: "5px 0" }}
								>
									Accessories
									<Badge badgeContent={userDetails?.multiple_accessory_users?.length} color="primary"> </Badge>
								</TabButton>
								< TabButton
									tab="Activities"
									tabState={tabState}
									tabStateChange={setTabState}
									activeClass={classes.activeTab}
									variant="text"
									color="primary"
									className={`w-full d-flex justify-content-start ${classes.tabButton}`}
									style={{ width: "100%", display: "flex", justifyContent: "space-between", margin: "5px 0" }}
								>
									Activities

								</TabButton>
							</CardActions>
						</Card>
					</Grid>
					<Grid lg={9}>
						<div>
							<Card className={classes.card} style={{ width: "100%", borderRadius: "0", overflow: "auto" }}>
								<CardContent className={classes.cardContainer} style={{ width: "100%" }}>
									<TabContainer tab="information" tabState={tabState}>
										<InfoTab />
									</TabContainer>
									<TabContainer tab="address" tabState={tabState}>
										<AddressTab />
									</TabContainer>
									<TabContainer tab="assets" tabState={tabState}>
										<AssetsTab />
									</TabContainer>
									<TabContainer tab="subscription" tabState={tabState}>
										<SubscriptionTab />
									</TabContainer>
									<TabContainer tab="Accessories" tabState={tabState}>
										<AccessoriesTab />
									</TabContainer>
									<TabContainer tab="Activities" tabState={tabState}>
										<ActivitieLogs logs={logs} height="290px" />
									</TabContainer>
								</CardContent>
							</Card>
						</div>
					</Grid>
				</Grid>
			</div> */}

			<div className='user_profile_holder'>
				<div className='left_holder'>
					<div className='top_user_info_holder'>
						<div className='image_container'>
							{
								loading ? <><Skeleton variant="circle" style={{ background: "#efefef" }} className={classes.userImg} width={100} height={100} /></> : <>
									<img src={imageUrl || 'assets/images/avatars/profile.jpg'} alt="loading..." className='profile_image' /></>
							}
							{userDetails?.emp_no &&
								<div className='id_holder'>
									ID: #{userDetails?.emp_no} 
								</div>
							}
						</div>
						<h4 className='user_name_holder'>{`${userDetails?.first_name || 'N/A'} ${userDetails?.last_name || 'N/A'}`}</h4>
						<div className='user_badge_holder'>
							<div className='each_badge job_holder'>
								<span>{userDetails?.title || 'N/A'}</span>
							</div>
							{userDetails?.multiple_role_end_users.map((eachRole, index) => {
								return (
									<div className='each_badge role_holder'>
										<span>{eachRole.roles_end_user.display_name}</span>
									</div>
								)
							})}
						</div>
						<Button
							variant='contained'
							className='primary_maroon_outlined_btn w-full'
							onClick={handelEdit}
						>
              				<i className='ti ti-pencil icon' />
							Edit user
						</Button>
					</div>
					<div className='bottom_user_info_holder'>
						<div className='each_info_holder'>
							<i className='ti ti-users-group icon' />
							<div className='information_holder'>
								<p className='label_holder'>Department</p>
								<p className='info_holder'>
									{userDetails?.department_name || 'N/A'}
								</p>
							</div>
						</div>
						<div className='each_info_holder'>
							<i className='ti ti-phone-call icon' />
							<div className='information_holder'>
								<p className='label_holder'>Phone</p>
								<p className='info_holder'>
									{userDetails?.original_info?.phone || 'N/A'}
								</p>
							</div>
						</div>
						<div className='each_info_holder'>
							<i className='ti ti-mail icon' />
							<div className='information_holder'>
								<p className='label_holder'>Email Address</p>
								<p className='info_holder' style={{color: '#000fe6'}}>
									{userDetails?.email || 'N/A'}
								</p>
							</div>
						</div>
						<div className='each_info_holder'>
							<i className='ti ti-map-pin icon' />
							<div className='information_holder'>
								<p className='label_holder'>Address</p>
								<p className='info_holder'>
									{userDetails?.address || 'N/A'}
									
								</p>
							</div>
						</div>
						<div className='each_info_holder'>
							<i className='ti ti-calendar-event icon' />
							<div className='information_holder'>
								<p className='label_holder'>Start Date</p>
								<p className='info_holder'>
									{userDetails?.user_start_date || 'N/A'}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='right_holder'>
					<Box className='cstm_tabs_holder'>
						<Tabs value={userTabValue} onChange={handleChangeUserTabValue} aria-label="basic tabs example" className='tabs_main_holder'>
							<Tab className='each_tab_holder' label="Assets" />
							<Tab className='each_tab_holder' label="Activities" />
						</Tabs>
					</Box>

					{userTabValue == 0 && <AssetsTab userDetails={userDetails} />
					}
					{/* {userTabValue == 1 && <ActivitieLogs logs={logs} height="290px" />} */}
					{userTabValue == 1 && <ActivityAgGridListing module={'user details'} logs={logs} loadingActivityLog={false}  />}
				</div>
			</div>

			{openAddUserForm && <QuickAddUser open={ openAddUserForm } close={handleAddUserFormClose}/>}

		</>
	)
}

export default UserDetailsDialog
