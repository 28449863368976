import SalesOrderTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import CsvUpload from './CsvUpload';
import {Menu,MenuItem} from '@material-ui/core';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import lodash from "lodash"
import useToast from '@fuse/hooks/useToast';
import AddUserDialog from './addUserDialog';
import { usePermission } from '@fuse/hooks';
import ShowLogs from './logs';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { dateReformatTo_mmm_dd_yyyy, formatDateTo_MM_DD_YYYY_HH_MM_SS } from '@fuse/utils/dateOperations';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
// import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import SaveReportDialog from '../../reports/components/saveReportDialog';
import { useDispatch, useSelector } from 'react-redux';
import QuickAddUser from 'app/fuse-layouts/shared-components/QuickAddUser';
import { closeFormViewerDialog, setFromData } from '../store/formViewDialogReducer';


const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [invoices, setManufacturers] = useState([]);
	const router = useRouter();
	const agents = getAgents(invoices);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [logs,setLogs] = useState([]);
	const [ logsLoading,setLogsLoading ] = useState(false)
	const { hasPermission } = usePermission()
	const viewUserPermission = hasPermission('view-end-user', true)
	const addUserPermission = hasPermission('add-end-user', true)
	const updateUserPermission = hasPermission('update-end-user', true)
	const deleteUserPermission = hasPermission('delete-end-user', true)
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();
	const { open: openAddUserForm } = useSelector((state) => state.usersApp.formViewDialog)
	const dialogState = useSelector(({assetsApp}) => assetsApp.reportViewDialogReducer);

	function handleAddUserFormClose(){
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
	}
	
	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function getAgents(invoices){
		const agentSet = new Set();
		for(let i = 0; i < invoices.length ; i++){
			agentSet.add(invoices[i].Agent);
		}
		return Array.from(agentSet);
	}

	const handleTabChange = (event, value) => {
		if(value ==1){
			// getLogs()
		}
		setSelectedTab(value);
	};
	
	function gotoDetailsPage(id){
		router.push(`manufacturers/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	async function getUsers(){
		// if(!viewManufacturersPermission){
		// 	return;
		// }
		try {
			const res = await axios.get("/user/list");
			const { users,permission_list } = res.data.data;

			//.........................if permission change...........................................//

			if((viewUserPermission != 'all' && permission_list['view-end-user'])){
				if(!viewUserPermission?.length){
					window.location.reload();
				}
			}
			if(((updateUserPermission == 'all' || updateUserPermission?.length) && !permission_list['update-end-user'])){
				window.location.reload();

			}else if((updateUserPermission != 'all' && updateUserPermission?.length == 0) && permission_list['update-end-user']){
				window.location.reload();
			}
			if(((deleteUserPermission == 'all' || deleteUserPermission?.length) && !permission_list['delete-end-user'])){
				window.location.reload();

			}else if((deleteUserPermission != 'all' && deleteUserPermission?.length == 0) && permission_list['delete-end-user']){
				window.location.reload();
			}
			setManufacturers(formatInvoices(users));
		} catch (err) {
			console.log(err);
			if(err?.response?.data?.statusCode == 403 && (viewUserPermission == 'all' || viewUserPermission?.length)){
				window.location.reload();
			}
		}
	}
	
	function formatInvoices(data){
		return data.map((item) => {
			return {
				miltiple_role: item.multiple_role_end_users.map((item)=>{
					return {id:item.id,name:item.roles_end_user?.display_name}
				}),
				original_info:{
					phone: item.phone,
					emp_no: item.emp_no,
					address: item.address,
					address_2: item.address_2,
					city: item.city,
					state: item.state,
					zip: item.zip,
					country: item.country,
					start_date: item.start_date,
					end_date: item.end_date,
				},
				user_start_date: Boolean(item.start_date) ? dateReformatTo_mmm_dd_yyyy(item.start_date) : "-",
				user_end_date: Boolean(item.end_date) ? dateReformatTo_mmm_dd_yyyy(item.end_date) : "-",
				...lodash.omit(item, [
					"entity_external_platform_id",
					"platform_customer_id",
					"dynamic_attributes",
					"created_at",
					"updated_at",
					"sales_lead_name",
					"is_set_password",
					"photoURL",
					"password_reset_id",
					"reset_token",
					"microsoft_id",
					"google_id",
					"portal_user",
					"company_id",
					"user_companies_id",
					"identifier",
					"avatar",
					"name"
				]),
				miltiple_role_name: (()=>{
					let role_name = ''
					item.multiple_role_end_users.forEach((data)=>{
						role_name += `,${data.roles_end_user?.display_name || ''}`
					})
					// item.multiple_role_end_users.forEach((item)=>{
					// 	role_name += item.roles_end_user?display_name
					// })
					role_name = role_name.slice(1)
					return role_name
					
				})(),
				role_name: item.multiple_role_end_users.roles_end_user ? item.multiple_role_end_users.roles_end_user?.display_name : 'N/A',
				department_name: item.department ? item.department.department_name : 'N/A',
				last_accessed: item.last_login ? formatDateTo_MM_DD_YYYY_HH_MM_SS(item.last_login) : 'Not accessed',
				source_ :item.source || 'No Source'
			}
		})
	}

	async function getDynamicAttributes(){
		try {
			const res = await axios.get("/dynamic-attributes/fetch-all");
			const { data } = res.data;
			const platformModuleItem = data.find(({ name }) => name == "invoice_header");
			setPlatformModule(platformModuleItem);
			try {
				const res_1 = await axios.get(`/dynamic-attributes/fetch/${platformModuleItem.id}`);
				const { data: data_1 } = res_1.data;
				setPlatformModuleAttributes(data_1);
				return data_1;
			} catch (err) {
				toast.error("Unable to fetch dynamic attributes");
				console.log(err);
			}
		} catch (err_1) {
			toast.error("Unable to fetch dynamic attributes");
			setPlatformModule({});
			console.log(err_1);
		}
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			return getUsers();
		})
	}


	

	useEffect(()=>{
		setLoading(true)
		getUsers().finally(() => {
			setLoading(false)
		});
	},[]);

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id && user.role[0] != 'Super-User'){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	if(loading){
		return(<FuseLoading />)
	}else{
	return (<>
			<CsvUpload 
				refresh={refreshList}
				// viewManufacturersPermission = { viewManufacturersPermission }
				// createManufacturersPermission = { createManufacturersPermission }
				// updateManufacturersPermission = { updateManufacturersPermission }
			/>

			{dialogState.open &&  <SaveReportDialog tableName={"user"}/>}


			<FusePageCarded
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				content={
					<>
						<div className={selectedTab !== 0 ? 'hidden' : ''}>
						{
							loading && <FuseLoading />
						}
						{
							!loading && <SalesOrderTable 
								endUsersAccounts={invoices}
								selectedView={selectedView}
								gotoDetailsPage={gotoDetailsPage}
								refresh={refreshList}
								viewUserPermission = {viewUserPermission}
								addUserPermission = {addUserPermission}
								updateUserPermission = {updateUserPermission}
								deleteUserPermission = {deleteUserPermission}
							/>
						}
						</div>
						{/* <AddUserDialog getUsers = {getUsers}/> */}
						{openAddUserForm && <QuickAddUser open={ openAddUserForm } close={handleAddUserFormClose}/>}
{/* 
						<div className={selectedTab !== 1 ? 'hidden' : 'p-10'}>
							{logsLoading ? <FuseLoading/> : <ActivityAgGridListing module='user' logs = {logs} /> }
						</div> */}
					</>
				}
				innerScroll
			/>
		
		</>)
	}
}

export default withReducer('usersApp', reducer)(CardedFullWidth2TabbedSample);
