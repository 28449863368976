import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import { openFormViewerDialog, setFromData, setUserInfo } from '../store/formViewDialogReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import UserDetailsDialog from './UserDetailsDialog';
import Tooltip from '@material-ui/core/Tooltip';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import { openReportViewerDialog, setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import DeleteConfirmModal from 'helper/DeleteConfirm';


const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
	searchbar: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		margin:'5px 5px 5px 0',
		height:'28px',
	},
	buttonSquare : {
		borderRadius:'5px'
	}
});

function ListView(props) {
	const [ gridApi, setGridApi ] = useState(null);
	const table_name = 'USER';
	const gridRef = useRef();
	const [ windowHeight, setWindowHeight ] = useState(window.innerHeight-330);
	const [ userData, setUserData] = useState(null);
	const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false)
	const [ departmentMenu, setDepartmentMenu ] = useState([])
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);

	const dispatch = useDispatch();
	const router = useRouter(); 
	const toast = useToast(dispatch);
	const [ displayButton, setDisplayButton ] = useState(false);
	const [filterApplied, setFilterApplied] = useState(false);
	const skipHeader = [
		"id", "user_companies_id", "roles_end_users_id", "created_at",
		"updated_at", "entity_external_platform_id", "company_id",
		"platform_customer_id", "portal_user", "password","google_id",
		"microsoft_id", "reset_token", "is_set_password","password_reset_id",
		"photoURL", "sales_lead_name", "login_enabled", "start_date", "end_date",
		"user_added", "first_login", "company_creator", "role_name", 
		"miltiple_role", "multiple_role_end_users", "department", "dept_id",
		"user_start_date", "user_end_date", "user_type", "original_info","last_login", 'source','otp_token',
		'tb_token', 'is_tb_acc_created', 'tb_user_id', 'tb_user_type', 'tb_tenant_id', 'agent_level', 'agent_type', 'agent_status'
	]
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const tab_value = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.tab_value
  );
	const quick_added_user = useSelector(
    (state) => state.usersApp.reportViewDialogReducer.quickAddedUser
  );


	const handleClickOpen = (data) => {
		router.push(`/user/${data.id}`)
	};

	function handelEdit(data){
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}

	const handleOpenDeleteDialog = (data) => {
		setOpenDeleteDialog(true)
		setUserData(data)
	}

	const handleClose = () => {
		setOpenDeleteDialog(false)
		setUserData(null)
	}

	function handleDelete(){
		axios.delete(`/user/delete/${userData.id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message)
				handleClose()
			}
			else {
				handleClose()
				toast.success(res.data.message)
				props.refresh()
			}
		}).catch((err)=>{
			toast.error(err.response.data.message)
		})
	}

	function formatString(str){
        if(!str) return ''
        if(str == 'miltiple_role_name') return 'Role Name'
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

	const onGridReady = (params) => {
		setGridApi(params);
		props.endUsersAccounts.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(value === '' || value == null){
					if(fieldName === 'last_accessed'){
						list[fieldName] = 'Not accessed'
					}else{
						list[fieldName] = `No ${formatString(fieldName)}`
					}
				}
			}
		})
		if(router.query.report_name == undefined) {			
			loadInitialState(params);
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}else{
		  setRowGroupPanelShow('always')
			getReport(params);
		}
	}

	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		saveView("N/A", rows, gridRef.current.filterModelArr)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function onSortChanged(params){
		if (router.query.report_name == undefined) {
		  const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
		  saveView(rows);
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "user";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState(params) {
		axios
		.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then((results) => {
		const { data } = results.data;
		if (data !== null) {
			const order = JSON.parse(data.order);
			const filter = JSON.parse(data.filter);
			gridRef.current = {...gridRef.current,filterModelArr:filter}
			params?.columnApi.applyColumnState({ state: order });
			params?.api.setFilterModel(filter);
			if(Object.keys(filter)?.length){
				setFilterApplied(true)
			}
		}
		})
		.catch((err) => {
		console.log(err);
		});
	}

	useEffect(()=>{
    if(filterApplied){
      toast.success("Filters have been applied")
    }
  },[filterApplied])

	function saveView(rows, filter, filterModelArr=null) {
		let payload = {}
		if(rows == 'N/A'){
		payload = {
			filter:  JSON.stringify(filterModelArr ?? filter),
			table_name : table_name
		}
		}else{
		payload = {
			order:  JSON.stringify(rows),
			table_name : table_name
		}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
		console.log('view updated')
		})
		.catch(err=>{
		console.log(err);
		})
	}

	const onColumnMoved = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}
	
	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
			if(router.query.report_name == undefined) {
				saveView(rows)
			}
		}
	}

	function onColumnRowGroupChanged(event) {
		if (router.query.report_name == undefined) {
		  const rows = event.columnApi.getColumnState();
		  const first_row = rows[0];
		  if (first_row.colId !== "ag-Grid-AutoColumn") {
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		  } else {
			dispatch(setrowGroupModel(rows));
		  }
		}
		// auto size group columns
		// onRowGroupOpenedAutoSize();
	  }

	function onRowGroupOpenedAutoSize(){	
		const allColumnIds = [];
		gridApi?.columnApi.getColumnState().forEach((column) => {
			
			if(column.colId === 'ag-Grid-AutoColumn') {
				allColumnIds.push(column.colId);
			}
		});
		gridApi?.columnApi.autoSizeColumns(allColumnIds, false);
	}

	function onCellValueChanged(e){
		let first_name = e.data.first_name
		let last_name = e.data.last_name
		let title = e.data.title
		let phone = e.data.original_info.phone
		let address = e.data.original_info.address 
		let address_2 = e.data.original_info.address_2 
		let city = e.data.original_info.city 
		let state = e.data.original_info.state
		let zipcode = e.data.original_info.zipcode
		let country = e.data.original_info.country
		let emp_no = e.data.original_info.emp_no
		let start_date = e.data.original_info.start_date
		let end_date = e.data.original_info.end_date
		let department = null
		
		if(e.colDef.field == "first_name"){
			first_name = e.newValue
		}
		if(e.colDef.field == "last_name"){
			last_name = e.newValue
		}
		if(e.colDef.field == "title"){
			title = e.newValue
		}
		if(e.colDef.field == "phone"){
			phone = e.newValue
		}
		if(e.colDef.field == "address"){
			address = e.newValue
		}
		if(e.colDef.field == "address_2"){
			address_2 = e.newValue
		}
		if(e.colDef.field == "city"){
			city = e.newValue
		}
		if(e.colDef.field == "state"){
			state = e.newValue
		}
		if(e.colDef.field == "zip"){
			zipcode = e.newValue
		}
		if(e.colDef.field == "country"){
			country = e.newValue
		}
		if(e.colDef.field == "department_name"){
			department = departmentMenu.find(item=>item.label == e.newValue)
		}
		if(e.colDef.field == "emp_no"){
			emp_no = e.newValue
		}
		if(e.colDef.field == "start_date"){
			start_date = e.newValue
		}
		if(e.colDef.field == "end_date"){
			end_date = e.newValue
		}
		const payload = {
			user_id: Number(e.data.id),
			first_name: e.data.first_name,
			last_name: e.data.last_name,
			title: e.data.title,
			email: e.data.email,
			login_enabled: e.data.login_enabled ?? false,
			role_id: e.data.multiple_role_end_users.map((role) => {
				return role.roles_end_user_id
			}),
			emp_no: emp_no || '',
			phone: phone || '',
			address: address || '',
			address_2: address_2 || '',
			city: city || '',
			state: state ||'',
			zipcode: zipcode || '',
			country: country || '',
			department_id: department ? department.id : (e.data.department?.id ?? null) ,
			start_date: start_date ? start_date.split('T')[0] : '',
			end_date: end_date ? end_date.split('T')[0] : '',
		}
		if(!payload.email){
			toast.error('Email is required')
			props.refresh()
			return
		} else if(!payload.first_name){
			toast.error('First Name is required')
			props.refresh()
			return
		} else if(!payload.last_name){
			toast.error('Last Name is required')
			props.refresh()
			return
		} else if(!payload.title){
			toast.error('Title is required')
			props.refresh()
			return
		}
		if(phone && !phone.match(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/)){
			toast.error('Phone number should be in 000-000-0000 format')
			props.refresh()
			return
		}
		axios.post('/user/update', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
				toast.success('User updated successfully.')
				props.refresh()
			}		  
		}).catch(err => {
		  console.log('212=>',err);
		  toast.error('Something went wrong!')
		})
	}

	useEffect(()=>{
		onGridReady()
		return () => {
			dispatch(setQuickFilter(null))
		}
	},[props.endUsersAccounts.length])
	
	const onRangeSelectionChanged = (params) => {

		let columnCount = 0 ;
		let startRowIndex = 0 ;
		let endRowIndex =  0 ;
		
		const rows = params.api.getCellRanges();

		if(rows.length != 0){
			columnCount = params.api.getCellRanges()[0].columns.length ;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex ;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex ;			
		}

		if(columnCount > 1 || startRowIndex != endRowIndex  ){
			setDisplayButton(true);
		}else{
			setDisplayButton(false);
		}
	}

	const generateHeaderLabel = (header) => {
		if(header == 'emp_no'){
			return 'Employee Number'
		}else if( header === "state" ) {
			return 'State/Province';
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	function valueFormatter(params){
		// if(params.colDef.field === 'last_accessed'){
		// 	if(params.value && params.value !== 'Not accessed'){
		// 		const date = new Date(params.value)
		// 		return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
		// 	}else{
		// 		return params.value
		// 	}
		// }
		// else return params.value
		return params.value
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])

	useEffect(() => {
		const onBtStopEditing = () => {
		gridRef?.current?.api?.stopEditing();
	}
		window.addEventListener('click',onBtStopEditing);
		return () => {
		window.removeEventListener('click', onBtStopEditing);
		};
	},[])

	useEffect( () => {
    props.refresh()
  }, [quick_added_user])

	const fetchDepartmentSuggestions = () => {
		axios.get("/department").then((response) => {
			let department = AlphabeticalSorting(response.data.data,'department_name')
			setDepartmentMenu(department.map((item)=>{
				return{
					id: item.id,
					label: item.department_name
				}
			}))
		}).catch(err => {
			console.log('164=>',err);
			toast.error('Something went wrong!')
		})
	}

	useEffect(()=>{
		fetchDepartmentSuggestions()
	},[])

	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	function formatLogs(data){
		return data.map((item)=>{
				return {
						id:item.id,
						action_id: `act${item.id}`,
						action_date_and_time: getLocalTimeFromUTC(item.created_at),
						user_name: item.user? `${item.user.first_name} ${item.user.last_name} (${item.user.email})`: item.end_user_name,
						name: item.user_name,
						action: item.action_name,
						description: item.comment,
						previous_value: (!item.previous_data || item.previous_data === '') ? 'N/A' : item.previous_data,
						current_value: (!item.current_data || item.current_data === '') ? 'N/A' : item.current_data,
						field_name: item.action_field_name,
						user_role: item.user_role_name
				}
		})
	}

	function getLogs(){
			axios.get('/user/all-activities')
			.then(({data})=>{
				setLogs(formatLogs(data.data.activities));
			})
			.catch(err=>{
				console.log('cannot fetch logs');
			})
	}

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	useEffect(()=>{
    setTabValue(tab_value)
  }, [tab_value])

	function handleAddUser(){
		dispatch(openFormViewerDialog());
	}

	const handleAddReport = () => {
		dispatch(openReportViewerDialog())
	}

	return (
		<>	

			{(tabValue == 0) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'user'} logs={logs} displayTableName = {'Users'}
				 onClick={handleChildClick}
				 onClickAddButton={handleAddUser}
				 onClickAddReportButton={handleAddReport}
				 addPermission = {props.addUserPermission.length == 0? false: true}

			/>}		
			{(tabValue == 1) && (<ActivityAgGridListing module={'user'}
			 logs={logs} displayTableName = {'Users'}
			 onClickAddButton={handleAddUser}
			 />)}
	
			{(tabValue == 0) && <div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'11px'
				}}
			>
				{/* {filterApplied && <p style={{margin: '4px'}}>Filters have been applied</p>} */}
				<AgGridReact
					ref={gridRef}
					suppressRowClickSelection={true}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData={props.endUsersAccounts}
					rowHeight = {30}
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }
					onSortChanged={onSortChanged}

					groupHeaderHeight ={1}
					frameworkComponents={frameworkComponents}
					floatingFiltersHeight = {40}

					// status bar enabled on range selection
					enableRangeSelection={ true }
					statusBar={{
						statusPanels: [
						{ 
							statusPanel: 'agAggregationComponent' },
						],
					}}

					// to check the the no. of rows selected during range selection
					onRangeSelectionChanged = { onRangeSelectionChanged }						

					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					cacheQuickFilter={true}

					pagination={true}
					defaultColDef={{
						enableRowGroup:true,
						resizable: true
					}}
				>
					<AgGridColumn
						lockPosition={true}
						rowSelection={'single'}
						width={120}
						minWidth={120}
						maxWidth={120}
						suppressSizeToFit={true}
						headerName="Actions"
						pinned= 'right' 
						className="action_field"
						lockPinned={true}
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>
										{<Tooltip id="view" title="View" placement="top">
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-eye" onClick={() => handleClickOpen(data)} />
										</Tooltip>}

										{(props.addUserPermission == 'all' || props.addUserPermission?.length > 0) && <Tooltip id="edit" title="Edit" placement="top">
											{/* <Icon 
												onClick={() => handelEdit(data)} 
												style={{
													color: '#1d2939',
													paddingBottom: '3px',
													paddingRight:'30px',
													fontSize:'15px',
													cursor:'pointer'
												}}
											>
												edit
											</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-pencil" onClick={()=>handelEdit(data)} />
										</Tooltip>}

										{(props.deleteUserPermission == 'all' || props.deleteUserPermission?.length > 0) && <Tooltip id="delete" title="Delete" placement="top">
											{/* <Icon 
												onClick={() => handleOpenDeleteDialog(data)} 
												style={{
													color: '#1d2939',
													paddingBottom: '3px',
													paddingRight:'30px',
													fontSize:'15px',
													cursor:'pointer'
												}}
											>
												delete
											</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-trash" onClick={()=>handleOpenDeleteDialog(data)} />
										</Tooltip>}
									</>
								)
							}else{
								return (<></>)
							}
						}}
					>
					</AgGridColumn>					
					{
						Object.keys(props.endUsersAccounts[0]).map((header)=>{
							if(!skipHeader.includes(header)){
								if(header == 'email'){
									return (
											<AgGridColumn
												field = {header}
												suppressSizeToFit = {true}
												headerName = {generateHeaderLabel(header)}  
												sortable = {true}
												filter = "text"
												flex={1}
												minWidth={200}
												headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
												
											>
											</AgGridColumn>
									)
								}
								if(header == 'miltiple_role_name'){
									return (
											<AgGridColumn 
												key = {header}
												field = {header}
												cellEditorPopup={true}
												suppressSizeToFit = {true}
												editable = {false}
												headerName = "Role Name"  
												sortable = {true}
												filter = "text"
												flex={1}
												minWidth={200}
												headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
												
											>
											</AgGridColumn>
									)
								}
								if(header == 'department_name'){
									return (
											<AgGridColumn 
												key = {header}
												field = {header}
												cellEditorPopup={true}
												suppressSizeToFit = {true}
												editable = {true}										
												onCellValueChanged = {onCellValueChanged}
												headerName = {generateHeaderLabel(header)}  
												sortable = {true}
												filter = "text"
												flex={1}
												minWidth={200}
												headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
												
												cellEditorParams= {{values:departmentMenu.map(department => department.label)}}
												cellEditor= 'agRichSelectCellEditor'
											>
											</AgGridColumn>
									)
								}
								if(header == 'country'){
									return (
											<AgGridColumn 
												key = {header}
												field = {header}
												suppressSizeToFit = {true}
												suppressRowClickSelection = {true}
												editable = {true}										
												onCellValueChanged = {onCellValueChanged}
												headerName = {generateHeaderLabel(header)}  
												sortable = {true}
												filter = "text"
												flex={1}
												minWidth={200}
												headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
												
												cellEditorParams= {{values:countries.map(item=>`${item.name}`)}}
												cellRenderer= 'GenderRenderer'
												cellEditor= 'agRichSelectCellEditor'
											>
											</AgGridColumn>
									)
								}
								if(header == 'source_'){
									return (
											<AgGridColumn 
												key = {header}
												field = {header}
												suppressSizeToFit = {true}
												suppressRowClickSelection = {true}
												// editable = {true}										
												// onCellValueChanged = {onCellValueChanged}
												headerName = {generateHeaderLabel(header)}  
												sortable = {true}
												filter = "text"
												headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
												
												cellEditorParams= {{values:countries.map(item=>`${item.name}`)}}
												cellRenderer= 'GenderRenderer'
												cellEditor= 'agRichSelectCellEditor'
											>
											</AgGridColumn>
									)
								}
								if(header == 'source_'){
									return (
											<AgGridColumn 
												key = {header}
												field = {header}
												suppressSizeToFit = {true}
												suppressRowClickSelection = {true}
												// editable = {true}										
												// onCellValueChanged = {onCellValueChanged}
												headerName = {generateHeaderLabel(header)}  
												sortable = {true}
												filter = "text"
												headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
												enableRowGroup = {true}
												cellEditorParams= {{values:countries.map(item=>`${item.name}`)}}
												cellRenderer= 'GenderRenderer'
												cellEditor= 'agRichSelectCellEditor'
											>
											</AgGridColumn>
									)
								}
								return(
									<AgGridColumn 
										key = {header} 
										field = {header}
										suppressSizeToFit = {true}
										editable = {true}										
										onCellValueChanged = {onCellValueChanged}
										headerName = {generateHeaderLabel(header)}
										valueFormatter={valueFormatter}
										sortable = {true} 
										filter = "text"
										flex={1}
										minWidth={200}
										headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										
									></AgGridColumn>
								)
							}
						})
					}				
				</AgGridReact>
				{/* <GeneralModal
					open={userData}
					title={'Delete User'}
					text={<h4>Are you sure to delete this user named as <b>{`${userData?.first_name} ${userData?.last_name}`}</b>?</h4>}
					handleClose={handleClose}
					handleConfirm={handleDelete}
				/> */}
				{
					openDeleteDialog && 
					<DeleteConfirmModal 
						openAlerModal={openDeleteDialog}
						module={'User'}
						handleCloseAlertModal={handleClose} 
						handleAlertModal={handleDelete}
					/>
				}
			</div>}
		</>
	);
}

export default ListView
