import EndUsersAccountsTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import FuseLoading from '@fuse/core/FuseLoading';
import { useDispatch } from 'react-redux';
import CsvUpload from './CsvUpload';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import SaveReportDialog from './saveReportDialog';
import { formatString } from '@fuse/utils/stringOperations';
import { usePermission } from '@fuse/hooks';
import { getContractStatus } from 'helper/status';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
});

const views = ['List View','Grid View'];
const defaultFilters = {
	quote_indicator:'',
	revenue: '',
	quote_status: '',
	agent: ''
}

function CardedFullWidth2TabbedSample(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	const [viewMenuOpened, setViewMenuOpened] = useState(false);
	const [filters, setFilters] = useState(defaultFilters);
	const [anchorEl, setAnchorEl] = useState(null);
	const [endUsersAccounts, setEndUsersAccounts] = useState([]);
	const router = useRouter();
	const agents = getAgents(endUsersAccounts);
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [searchFilters, setSearchFilters] = useState([]);
	const [searchSuggestions, setSearchSuggestions] = useState([]);
	const [customFilters, setCustomFilters] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({});
	const [currentSearchParameters, setCurrentSearchParameters] = useState({});
	const [manualContract,setManualContract] = useState([])
	const { hasPermission } = usePermission()
	const viewContractPermission = hasPermission('view-contract',true)
	const createContractPermission = hasPermission('add-contract')
	const updateContractPermission = hasPermission('update-contract',true)
	const deleteContractPermission = hasPermission('delete-contract',true)

	function handleViewMenuOpen(event) {
		setAnchorEl(event.currentTarget);
		setViewMenuOpened(true);
	}

	function handleViewMenuClose() {
		setAnchorEl(null);
		setViewMenuOpened(false);
	}

	function getAgents(endUsersAccounts){
		const agentSet = new Set();
		for(let i = 0; i < endUsersAccounts.length ; i++){
			agentSet.add(endUsersAccounts[i].Agent);
		}
		return Array.from(agentSet);
	}

	function applyFilters(filters){
		setFilters(filters);
		setEndUsersAccounts(endUsersAccounts.filter(acc=>{
			let flag = true;
			if(filters.agent != '' && filters.agent != acc.Agent){
				flag = false;
			}
			return flag;
		}))
	}

	function clearFilters() {
		const {query, field} = currentSearchParameters;
		handleSearch(query, field, {});
		setSelectedFilters({});
	}

	const handleTabChange = (event, value) => {
		setSelectedTab(value);
	};

	function toggleView(val){
		setSelectedView(val);
	}

	function gotoDetailsPage(id){
		router.push(`contracts/details/${id}`)
	}

	const [platformModule, setPlatformModule] = useState({});
	const [platformModuleAttributes, setPlatformModuleAttributes] = useState([]);

	async function getContracts(){
		try {
			const res = await axios.get(`/end-user-contracts/list-all?archived=true&queryfilter=${router.query.filter != undefined ? router.query.filter : '_'}`);
			const { data } = res.data;
			setEndUsersAccounts(formatContracts(data));
		} catch (err) {
			console.log(err);
		}
	}
	
	function handleSearchReset(){
		getContracts()
	}

	function getSearchFilters(){
		setSearchFilters([
			{field: "entity_external_platform_id", label: "Contract ID"},
			{field: "contract_status", label: "Contract Status"},
			{field: "location", label: "Contact Location"},
		])
	}

	function debounce(func, timeout = 300){
		let timer;
		return (...args) => {
		  clearTimeout(timer);
		  timer = setTimeout(() => { func.apply(this, args); }, timeout);
		};
	}

	function getCustomFilters(){
		axios.get('/contracts/get-filters')
		.then(res => {
			const { data } = res.data;
			setCustomFilters(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	function getSearchSuggetions(query, field){
		axios.get('/end-user-contracts/search-suggestions',{
			params: {
				query,
				field
			}
		})
		.then(res => {
			const { data } = res.data;
			setSearchSuggestions(data)
		})
		.catch(err => {
			console.log(err);
		})
	}

	const handelSearchSuggestions = debounce((query, field) => {
		getSearchSuggetions(query, field)
	}, 500)

	function handleSearch(query, field, filters) {
		axios.get('/end-user-contracts/search',{
			params: {
				query,
				field,
				filters
			}
		})
		.then(res => {
			const { data } = res.data;
			// setEndUsersAccounts(data)
			
			setEndUsersAccounts(formatContracts(data))
		})
		.catch(err => {
			console.log(err);
		})
	}

	function formatContracts(data){
		return data.map((contract) => {
			const dynamicAttributes = Object.entries(contract.dynamic_attributes).reduce((acc, [key, item]) => {
				acc[key] = item.attribute_value

				return acc;
			},{});
			return {
				id: contract.id,
				contract_number: contract.entity_external_platform_id || '-',
				contract_name: contract.contract_name,
				company_name: contract.user_company?.company_name || '-',
				contract_description: contract?.contract_description || '-',
				start_date: contract.contract_start_date !== null ? dateReformatTo_mmm_dd_yyyy(contract.contract_start_date) : "-",
                end_date: contract.contract_end_date !== null ? dateReformatTo_mmm_dd_yyyy(contract.contract_end_date) : "-",
				source: formatString(contract?.source) || "-",
				type: contract?.contract_headers_type?.display_name || '-',
				status: contract?.contract_header_status?.contract_status_name || '-',
				contract_tag: contract?.contract_header_status?.contract_status_name || '',
				contract_status: getContractStatus(contract?.contract_end_date)?.status || '',
				service_level_agreement_object: contract?.service_level_agreement,
				// service_level_agreement: contract?.service_level_agreement?.sla_name || '-',
				SLA: contract?.service_level_agreement?.sla_name || '-',
				service_provider: contract?.contract_service_provider?.service_provider_name || '-',
				service_provider_object: contract?.contract_service_provider,
				master: contract?.master === true ? "Yes" : "No",
				parent_contract: contract?.parent_contract?.contract_name || '-',
				['new/renew']: contract?.renewal || '-',
				payment_amount: contract?.contract_payment?.payment_amount || '-',
				currency: contract?.contract_payment?.country || '-',
				payment_term: contract?.contract_payment?.payment_term?.display_name || '-',
				payment_order_number: contract?.contract_payment?.po_number || '-',
				payment_order_date: contract?.contract_payment?.po_date ? dateReformatTo_mmm_dd_yyyy(contract?.contract_payment?.po_date) : null || '-',
				reminder: formatString(contract?.reminder) || '-',
				text_contact_number: contract?.support_contact_number || '-',
				contract_status_object: contract?.contract_header_status,
				parent_contract_object: contract?.parent_contract,
				contract_type_object: contract?.contract_headers_type,
				location_object: contract?.shipping,
				contract_payment_object: contract?.contract_payment,
				location: contract.shipping?`${contract.shipping.location_name}-${contract.shipping.address1}, ${contract.shipping.city}, ${contract.shipping.state}, ${contract.shipping.country}`: "",
				location_id: contract.shipping?.entity_external_platform_id || '-',
				master_object: { value: contract?.master, label: contract?.master ? 'Yes' : 'No' },
				...dynamicAttributes,
			}
		})
	}

	async function getDynamicAttributes(){
		try {
			const res = await axios.get("/dynamic-attributes/fetch-all");
			const { data } = res.data;
			const platformModuleItem = data.find(({ name }) => name == "contract_header");
			setPlatformModule(platformModuleItem);
			try {
				const res_1 = await axios.get(`/dynamic-attributes/fetch/${platformModuleItem.id}`);
				const { data: data_1 } = res_1.data;
				setPlatformModuleAttributes(data_1);
				return data_1;
			} catch (err) {
				toast.error("Unable to fetch dynamic attributes");
				console.log(err);
			}
		} catch (err_1) {
			toast.error("Unable to fetch dynamic attributes");
			setPlatformModule({});
			console.log(err_1);
		}
	}

	function refreshList(){
		getDynamicAttributes().then(() => {
			return getContracts();
		})
	}

	function gotoDetailsPageMaluallyAddedContract(){
		return
	}
	
	useEffect(()=>{
		setLoading(true)
		getSearchFilters();
		getDynamicAttributes().then(() => {
			return getContracts();
		}).finally(() => {
			setLoading(false)
		});
	},[]);

	if(loading){
		return(<FuseLoading />)
	}else{
	return (<>
			<CsvUpload refresh={refreshList}/>
			{/* <MapViewer/> */}
			<SaveReportDialog />
			<FusePageCarded
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0',
				topBg:classes.topBg,
				contentWrapper:classes.contentWrapper
			}}
			// header={
			// 	<DynamicFieldsHeader 
			// 		handleSearch={handleSearch} 
			// 		searchSuggestions={searchSuggestions} 
			// 		clearSearch={clearFilters}
			// 		searchFilters={searchFilters}
			// 		handleSearchSuggestions={handelSearchSuggestions}
			// 		handleSearchReset={handleSearchReset}
			// 		onSearchChange={(searchValue, fieldValue) => {
			// 			setCurrentSearchParameters({query: searchValue, field: fieldValue})
			// 		}}
			// 	/>
			// }
		// 	contentToolbar={
		// 		<>
		// 			<Tabs
		// 				value={selectedTab}
		// 				onChange={handleTabChange}
		// 				indicatorColor="primary"
		// 				textColor="primary"
		// 				variant="scrollable"
		// 				scrollButtons="off"
		// 				className="w-full h-64"
		// 			>
		// 				<Tab key="end_user_tab_1" className="h-64" label="All Archived Contracts" />
		// 				{/* <Tab key="end_user_tab_2" className="h-64" disabled={!!router.query.report_name} label="Manually Added Contracts" /> */}
						
		// 			</Tabs>
						
		// </>
		// 	}
			content={
				<>
					<div className={selectedTab !== 0 ? 'hidden' : 'p-10'}>
						<EndUsersAccountsTable 
							endUsersAccounts={endUsersAccounts}
							selectedView={selectedView}
							gotoDetailsPage={gotoDetailsPage}
							refresh={refreshList}
							viewContractPermission = {viewContractPermission}
							createContractPermission = {createContractPermission}
							updateContractPermission = {updateContractPermission}
							deleteContractPermission = {deleteContractPermission}
						/>
					</div>
				</>
			}
			innerScroll
		/>		
	</>)
	}
}

export default withReducer('contractsArchiveApp', reducer)(CardedFullWidth2TabbedSample);

