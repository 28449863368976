import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { withRouter } from 'react-router-dom';
import axios from "@fuse/utils/axios";
import { setrowGroupModel } from 'app/main/service-request/store/filterParamsReducer';
// import { setSerialNumberForServiceRequest } from 'app/main/service-request/store/filterParamsReducer';
import { setServiceTicketsCount } from 'app/main/assets/store/formViewDialogReducer'
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '@fuse/hooks';
import useToast from '@fuse/hooks/useToast'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { closeFormViewerDialog, openFormViewerDialog } from 'app/main/service-request/store/formViewDialogReducer';
import { getLocalTimeFromUTC } from '@fuse/utils/dateOperations';

const useStyles = makeStyles(theme => ({
	loader: {
		display: 'flex',
		marginTop: '27px',
		marginLeft: '10px',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	drawerWidth: {
		width: 600
	},
	uploadImageButton: {
		padding: '10px 36px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer',
		marginTop: "25px"
	},
	subSection: {
		marginLeft: '20px !important',
		marginRight: '20px !important',
		marginBottom: '20px !important',
		padding: '10px',
		backgroundColor: '#f4f4f4',
		borderRadius: '4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
}))


function ServiceRequestsTab(props) {
	const table_name = "SERVICE_REQUEST";

	const [windowHeight, setWindowHeight] = useState(window.innerHeight - 430);

	const gridRef = useRef()

	const onGridReady = params => {
		// Following line to make the currently visible columns fit the screen  
		params.api.sizeColumnsToFit();

		// Following line dymanic set height to row on content
		params.api.resetRowHeights();
	};

	const tableHeaders = [
		"ticket_number", "serial_number", "title", "priority", "status", "agent_name", "created_date", "updated_date"
	];

	const classes = useStyles();
	const router = useRouter()
	const [serviceRequests, setServiceRequests] = useState([])
	const [loading, setLoading] = useState(false)
	const { assetDetails } = props

	const dispatch = useDispatch()
	const toast = useToast(dispatch);

	function formatServiceRequests(serviceRequests) {
		return serviceRequests.map((item) => {
			return {
				id: item.id,
				ticket_number: item.ticket_id,
				serial_number: item.asset.serial_number,
				title: item.title,
				priority: item.service_request_priority ? item.service_request_priority?.name : 'N/A',
				status: item.service_request_status ? item.service_request_status?.status_name : 'N/A',
				status_details: {
					id: item?.service_request_status?.id,
					status_name: item?.service_request_status?.status_name,
					status_color: item?.service_request_status?.status_color
				},
				agent_name: item.agent ? `${item.agent.first_name} ${item.agent.last_name}` : 'N/A',
				service_request_status: item.service_request_status ?? 'N/A',
				deleted_at: item.deleted_at,
				created_date: item.created_at ? getLocalTimeFromUTC(item.created_at) : '-',
				updated_date: item.updated_at ? getLocalTimeFromUTC(item.updated_at) : '-',
			}
		})
	}
	function getServiceRequests() {
		if (!assetDetails) return
		// setLoading(true)
		axios.get(`/assets/fetch-asset-service-request/${assetDetails.id}?archived=${true}`, {
		}).then((res) => {
			const { response } = res.data.data;
			setServiceRequests(formatServiceRequests(response))
			dispatch(setServiceTicketsCount(response.length))
		}).catch((err) => {
			console.log(err)
		}).finally(() => {
			// setLoading(false)
		})
	}

	useEffect(() => {
		getServiceRequests()
	}, [])

	if (loading) {
		return (
			<FuseLoading />
		)
	}

	const generateHeaderLabel = (header) => {
		if (header == 'accessory_category_id') {
			return 'Accessory Category Name'
		} else if (header == 'manufacturer_id') {
			return 'Manufacturer Name'
		} else if (header === 'detailed_description_of_the_issue') {
			return 'Issue Description'
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	function handleHideColumnDropDownDesign() {
		return { border: '1px solid #000000', borderRadius: '16px', padding: '4px 8px', backgroundColor: 'transparent', lineHeight: '24px', position: 'relative' }
	}

	function onSortChanged(params) {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function saveView(rows, filter) {
		let payload = {}
		if (rows == 'N/A') {
			payload = {
				filter: JSON.stringify(filter),
				table_name: table_name
			}
		} else {
			payload = {
				order: JSON.stringify(rows),
				table_name: table_name
			}
		}
		axios.post('/user-view/save-view-order', payload)
			.then(() => {
				console.log('view updated')
			})
			.catch(err => {
				console.log(err);
			})
	}

	function redirectToServiceTicketProfile(event) {
		if (event?.data?.deleted_at) {
			router.push(`/archive-service-tickets/${event?.data.id}`)
		} else {
			router.push(`/service-tickets/${event?.data.id}`)
		}
	}


	return (
		<div className={classes.root}>
			<div
				className="ag-theme-alpine"
				style={{
					width: "100%",
					height: windowHeight + 105,
					fontSize: '12px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					rowData={serviceRequests}
					animateRows
					rowHeight={28}
					onGridReady={onGridReady}
					paginationPageSize={10}
					pagination
					onSortChanged={onSortChanged}
					defaultColDef={{
						resizable: true
					}}
				>
					{tableHeaders.map(header => {
						if (header == 'ticket_number') {
							return (
								<AgGridColumn
									field={header}
									key={header}
									suppressSizeToFit={true}
									headerName={generateHeaderLabel(header)}
									sortable={true}
									// floatingFilter = { true } 
									headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
									filter="text"
									flex={1}
									minWidth={200}

									cellRendererFramework={(event) => {
										return (<a
											style={{
												height: '10px',
												width: '10px',
												color: 'black',
												cursor: 'pointer',
												marginTop: '2px',
												borderRadius: '50%',
												display: 'inline-block',
											}}
											onClick={() => redirectToServiceTicketProfile(event)}
											variant="subtitle1"
										>
											{<><span>{event.value}</span></>}
											{<><span> {event?.data?.deleted_at ? '(Archived)' : ''}</span></>}
										</a>)
									}}
								>
								</AgGridColumn>
							)
						}
						if (header == 'status') {
							return (
								<AgGridColumn
									field={header}
									key={header}
									minWidth={230}
									cellEditorPopup
									suppressSizeToFit
									// cellEditor="agRichSelectCellEditor"
									cellEditor='customDropdownEditor'
									headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
									sortable

									// floatingFilter
									headerName={generateHeaderLabel(header)}
									filter="text"
									editable={false}
									cellRendererFramework={(event) => {
										if (event.colDef.field === "status") {
											return (
												<span
													style={handleHideColumnDropDownDesign()}>
													<span
														style={{
															height: "10px",
															width: "10px",
															marginTop: "2px",
															borderRadius: "50%",
															display: "inline-block",
															// backgroundColor: event.data?.asset_status_color?.code
															backgroundColor:
																event.value?.split(",")[1] ??
																event.data?.status_details?.status_color?.code,
														}}
													/>

													<span style={{ marginLeft: "5px", marginRight: '14px', fontSize: '12px', fontWeight: '500', color: 'black', fontFamily: '"Inter", sans-serif', }}>
														{event.value?.split(",")[0]}
													</span>
												</span>
											);
										} if (
											event.colDef.headerName == "Group" &&
											event.node.field == "status"
										) {
											return (
												<>
													<span>{event.value}</span>
												</>
											);
										}
										return (
											<>
												<span>{event.value}</span>
											</>
										);

									}}
								/>
							);
						}
						return (
							<AgGridColumn
								key={header}
								field={header}
								headerName={generateHeaderLabel(header)}
								// width={500}
								minWidth={(header === 'created_date' || header === 'updated_date') ? 300 : 170}
								sortable={true}
								flex={1}
								filter="text"
								wrapText
								headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
							/>
						)

					})}
				</AgGridReact>
			</div>
		</div>
	)
}

export default withRouter(ServiceRequestsTab)
