
import React, { useEffect, useRef,useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core'
import { Icon, IconButton} from '@mui/material'
const  FilePreviewModal = ({ open, close, filePreviewLink, fileName }) => {

  const useStyles = makeStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  });

	const classes = useStyles()
	const fileExtension = fileName.split('.').pop().toLocaleLowerCase()
	const imageFileExtension = ['png', 'jpeg', 'jpg']
  const [url,setURL] = useState('')
  const [isImageFile,setIsImageFile] = useState(false)

  
  useEffect(() => {
    if (imageFileExtension.includes(fileExtension)) {
      setIsImageFile(true)
    }else{
      const encodedURL = encodeURIComponent(filePreviewLink);
 
      setURL(`${!['pdf','csv','txt'].includes(fileExtension) ? `https://view.officeapps.live.com/op/embed.aspx?src=${encodedURL}` :  `https://docs.google.com/viewer?url=${encodedURL}&embedded=true`}`);
    }
 }, [filePreviewLink]);

//   async function fetchFile(url) {
//     setLoading(true);
    
//     try {
//         const response = await fetch(url);

//         if (!response.ok) throw new Error("Network response was not ok");

//         const blob = await response.blob();
//         const fileURL = URL.createObjectURL(blob);
//         setFileURL(fileURL);
//     } catch (error) {
//         console.error("Failed to fetch File:", error);
//     } finally {
//         setLoading(false);
//     }
// }

// useEffect(() => {
//     fetchFile(filePreviewLink);

//     return () => {
//         if (fileURL) {
//             URL.revokeObjectURL(fileURL);
//         }
//     }
// }, [filePreviewLink]);

	return (
		<Modal
			open={open}
			className={classes.modal}
		>
			<div style={{
				height: '60vh',
				width: '50vw',
				backgroundColor: 'white',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '13px',
				flexDirection: 'column',
				position: 'relative',
				padding: '30px'
			}}>
				<IconButton
					style={{ position: 'absolute', right: '0px', top: '0px' }}
					onClick={() => {
						close()
					}}>
					<Icon>close</Icon>
				</IconButton>
				{
					isImageFile
						?
						<img style={{ height: '100%', width: '100%' }} src={filePreviewLink || ''} alt='' />
						:
						<iframe style={{ height: '100%', width: '100%' }} src={url} />
				}
			</div>
		</Modal>
	)
}

export default  FilePreviewModal