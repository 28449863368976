exports.countries = [ 
    {"name": "United States", "code": "US", "currency": "USD" , "phone": "+1","locale": "en-US"},  
    {"name": "Canada", "code": "CA", "currency":"CAD", "phone": "+1","locale": "en-CA"}, 
    {"name": "Afghanistan", "code": "AF", "currency": "AFN" , "phone": "+93","locale": "fa-AF"},
    {"name": "Albania", "code": "AL", "currency":"ALL", "phone": "+355","locale": "sq-AL"}, 
    {"name": "Algeria", "code": "DZ", "currency":"DZD", "phone": "+213","locale": "ar-DZ"},
    {"name": "American Samoa", "code": "AS", "currency":"USD", "phone": "+1684","locale": "en-AS"},
    {"name": "AndorrA", "code": "AD", "currency":"EUR", "phone": "+376","locale": "ca-AD"}, 
    {"name": "Angola", "code": "AO", "currency":"AOA", "phone": "+244","locale": "ln-AO"}, 
    {"name": "Anguilla", "code": "AI", "currency":"XCD", "phone": "+1264","locale": "en-AI"},
    {"name": "Antigua and Barbuda", "code": "AG", "currency":"XCD", "phone": "+1268","locale": "en-AG"}, 
    {"name": "Argentina", "code": "AR", "currency":"ARS", "phone": "+54","locale": "es-AR"}, 
    {"name": "Armenia", "code": "AM", "currency":"AMD", "phone": "+374","locale": "hy-AM"},
    {"name": "Aruba", "code": "AW", "currency":"AWG", "phone": "+297","locale": "nl-AW"},
    {"name": "Australia", "code": "AU", "currency":"AUD", "phone": "+61","locale": "en-AU"},
    {"name": "Austria", "code": "AT", "currency":"EUR", "phone": "+43","locale": "de-AT)"}, 
    {"name": "Azerbaijan", "code": "AZ", "currency":"AZN", "phone": "+994","locale": "az-Cyrl-AZ"}, 
    {"name": "Bahamas", "code": "BS", "currency":"BSD", "phone": "+1242","locale": "en-BS"}, 
    {"name": "Bahrain", "code": "BH", "currency":"BHD", "phone": "+973","locale": "ar-BH"}, 
    {"name": "Bangladesh", "code": "BD", "currency":"BDT", "phone": "+880","locale": "bn-BD"}, 
    {"name": "Barbados", "code": "BB", "currency":"BBD", "phone": "+1246","locale": "en-BB"}, 
    {"name": "Belarus", "code": "BY","currency":"BYN", "phone": "+375","locale": "be-BY"}, 
    {"name": "Belgium", "code": "BE", "currency":"EUR", "phone": "+32","locale": "en-BE"},
    {"name": "Belize", "code": "BZ", "currency":"BZD", "phone": "+501","locale": "en-BZ"}, 
    {"name": "Benin", "code": "BJ", "currency":"XOF", "phone": "+229","locale": "fr-BJ"}, 
    {"name": "Bermuda", "code": "BM", "currency":"BMD", "phone": "+1441","locale": "en-BM"}, 
    {"name": "Bhutan", "code": "BT", "currency":"BTN", "phone": "+975","locale": "dz-BT"}, 
    {"name": "Bolivia", "code": "BO", "currency":"BOV", "phone": "+591","locale": "es-BO"}, 
    {"name": "Bosnia and Herzegovina", "code": "BA", "currency":"BAM", "phone": "+387","locale": "bs-Cyrl-BA"}, 
    {"name": "Botswana", "code": "BW", "currency":"BWP", "phone": "+267","locale": ""},
    {"name": "Bouvet Island", "code": "BV", "currency":"NOK", "phone": "","locale": ""}, 
    {"name": "Brazil", "code": "BR", "currency":"BRL", "phone": "+55","locale": "es-BR"},
    {"name": "British Indian Ocean Territory", "code": "IO", "currency":"USD", "phone": "","locale": "en-IO"}, 
    {"name": "Brunei Darussalam", "code": "BN", "currency":"BND", "phone": "+673","locale": "ms-BN"}, 
    {"name": "Bulgaria", "code": "BG", "currency":"BGN", "phone": "+359","locale": "bg-BG"}, 
    {"name": "Burkina Faso", "code": "BF", "currency":"XOF", "phone": "+226","locale": "fr-BF"}, 
    {"name": "Burundi", "code": "BI", "currency":"BIF", "phone": "+257","locale": "en-BI"}, 
    {"name": "Cambodia", "code": "KH", "currency":"KHR", "phone": "+855","locale": "km-KH"}, 
    {"name": "Cameroon", "code": "CM", "currency":"XAF", "phone": "+237","locale": "en-CM"}, 
    {"name": "Cape Verde", "code": "CV", "currency":"CVE", "phone": "+238","locale": "kea-CV"}, 
    {"name": "Cayman Islands", "code": "KY", "currency":"KYD", "phone": "+1345","locale": "ky-KG"}, 
    {"name": "Central African Republic", "code": "CF", "currency":"XAF", "phone": "+236","locale": "fr-CF"}, 
    {"name": "Chad", "code": "TD", "currency":"XAF", "phone": "+235","locale": "ar-TD"}, 
    {"name": "Chile", "code": "CL", "currency":"CLP", "phone": "+56","locale": "es-CL"}, 
    {"name": "China", "code": "CN","currency":"CNY", "phone": "+86","locale": "bo-CN"}, 
    {"name": "Christmas Island", "code": "CX", "currency":"AUD", "phone": "+61","locale": "en-CX"}, 
    {"name": "Cocos (Keeling) Islands", "code": "CC", "currency":"AUD", "phone": "+61","locale": "en-CC"}, 
    {"name": "Colombia", "code": "CO", "currency":"COP", "phone": "+57","locale": "es-CO"}, 
    {"name": "Comoros", "code": "KM", "currency":"KMF", "phone": "+269","locale": "ar-KM"},
    {"name": "Congo", "code": "CG", "currency":"XAF", "phone": "+242","locale": "fr-CG"}, 
    {"name": "Congo, The Democratic Republic of the", "code": "CD", "currency":"CDF", "phone": "+243","locale": "fr-CD"}, 
    {"name": "Cook Islands", "code": "CK", "currency":"NZD", "phone": "+682","locale": "en-CK"}, 
    {"name": "Costa Rica", "code": "CR", "currency":"CRC", "phone": "+506","locale": "es-CR"},
    {"name": "Croatia", "code": "HR", "currency":"HRK", "phone": "+385","locale": "hr-HR"},
    {"name": "Cyprus", "code": "CY", "currency":"EUR", "phone": "+357","locale": "en-CY"}, 
    {"name": "Czech Republic", "code": "CZ", "currency":"CZK", "phone": "+420","locale": "cs-CZ"}, 
    {"name": "Denmark", "code": "DK", "currency":"DKK", "phone": "+45","locale": "en-DK"}, 
    {"name": "Djibouti", "code": "DJ", "currency":"DJF", "phone": "+253","locale": "ar-DJ"}, 
    {"name": "Dominica", "code": "DM", "currency":"XCD", "phone": "+1767","locale": "en-DM"}, 
    {"name": "Dominican Republic", "code": "DO", "currency":"DOP", "phone": "+1829","locale": "es-DO"}, 
    {"name": "Ecuador", "code": "EC", "currency":"USD", "phone": "+593","locale": "es-EC"}, 
    {"name": "Egypt", "code": "EG", "currency":"EGP", "phone": "+20","locale": "ar-EG"}, 
    {"name": "El Salvador", "code": "SV", "currency":"SVC", "phone": "+503","locale": "es-SV"}, 
    {"name": "Equatorial Guinea", "code": "GQ", "currency":"XAF", "phone": "+240","locale": "es-GQ"}, 
    {"name": "Eritrea", "code": "ER", "currency":"ERN", "phone": "+291","locale": ""}, 
    {"name": "Estonia", "code": "EE", "currency":"EUR", "phone": "+372","locale": "et-EE"}, 
    {"name": "Ethiopia", "code": "ET", "currency":"ETB", "phone": "+251","locale": ""}, 
    {"name": "Falkland Islands (Malvinas)", "code": "FK", "currency":"FKP", "phone": "+500","locale": "en-FK"}, 
    {"name": "Faroe Islands", "code": "FO", "currency":"DKK", "phone": "+298","locale": "fo-FO"}, 
    {"name": "Fiji", "code": "FJ", "currency":"FJD", "phone": "+679","locale": "en-FJ"}, 
    {"name": "Finland", "code": "FI", "currency":"EUR", "phone": "+358","locale": "en-FI"}, 
    {"name": "France", "code": "FR", "currency":"EUR", "phone": "+33","locale": "fr-FR"}, 
    {"name": "French Guiana", "code": "GF", "currency":"EUR", "phone": "+594","locale": "fr-GF"}, 
    {"name": "French Polynesia", "code": "PF", "currency":"XPF", "phone": "+689","locale": "fr-PF"},
    {"name": "French Southern Territories", "code": "TF", "currency":"EUR","phone": "","locale": ""},
    {"name": "Gabon", "code": "GA", "currency":"XAF", "phone": "+241","locale": ""}, 
    {"name": "Gambia", "code": "GM", "currency":"GMD", "phone": "+220","locale": "en-GM"}, 
    {"name": "Georgia", "code": "GE", "currency":"GEL", "phone": "+995","locale": "ka-GE"}, 
    {"name": "Germany", "code": "DE", "currency":"EURO", "phone": "+49","locale": "de-DE"}, 
    {"name": "Ghana", "code": "GH", "currency":"GHS", "phone": "+233","locale": "en-GH"}, 
    {"name": "Gibraltar", "code": "GI", "currency":"GIP", "phone": "+350","locale": "en-GI"}, 
    {"name": "Greece", "code": "GR", "currency":"EURO", "phone": "+30","locale": "el-GR"}, 
    {"name": "Greenland", "code": "GL", "currency":"DKK", "phone": "+229","locale": ""}, 
    {"name": "Grenada", "code": "GD", "currency":"XCD", "phone": "+1473","locale": "en-GD"}, 
    {"name": "Guadeloupe", "code": "GP", "currency":"EUR", "phone": "+590","locale": "fr-GP"}, 
    {"name": "Guam", "code": "GU", "currency":"USD", "phone": "+1671","locale": "en-GU"}, 
    {"name": "Guatemala", "code": "GT", "currency":"GTQ", "phone": "+502","locale": "es-GT"}, 
    {"name": "Guernsey", "code": "GG", "currency":"GBP", "phone": "+44","locale": "en-GG"}, 
    {"name": "Guinea", "code": "GN", "currency":"GNF", "phone": "+224","locale": "ff-GN"}, 
    {"name": "Guinea-Bissau", "code": "GW", "currency":"XOF", "phone": "+245","locale": "pt-GW"}, 
    {"name": "Guyana", "code": "GY", "currency":"GYD", "phone": "+592","locale": "en-GY"}, 
    {"name": "Haiti", "code": "HT", "currency":"USD", "phone": "+509","locale": "fr-HT"}, 
    {"name": "Heard Island and Mcdonald Islands", "code": "HM", "currency":"AUD", "phone": "","locale": ""}, 
    {"name": "Holy See (Vatican City State)", "code": "VA", "currency":"EUR", "phone": "","locale": "it-VA"}, 
    {"name": "Honduras", "code": "HN", "currency":"HNL", "phone": "+504","locale": "es-HN"}, 
    {"name": "Hong Kong", "code": "HK", "currency":"HKD", "phone": "+852","locale": "en-HK"}, 
    {"name": "Hungary", "code": "HU", "currency":"HUF", "phone": "+36","locale": "hu-HU"}, 
    {"name": "Iceland", "code": "IS", "currency":"ISK", "phone": "+354","locale": "is-IS"}, 
    {"name": "India", "code": "IN", "currency":"INR", "phone": "+91","locale": "en-IN"}, 
    {"name": "Indonesia", "code": "ID", "currency":"IDR", "phone": "+62","locale": "id-ID"}, 
    // {"name": "Iran, Islamic Republic Of", "code": "IR", "phone": "+","locale": ""},
    {"name": "Iraq", "code": "IQ", "currency":"IQD", "phone": "+964","locale": "ar-IQ"}, 
    {"name": "Ireland", "code": "IE", "currency":"EUR", "phone": "+353","locale": "en-IE"}, 
    {"name": "Isle of Man", "code": "IM", "currency":"GBP", "phone": "+44","locale": "en-IM"}, 
    {"name": "Israel", "code": "IL", "currency":"ILS", "phone": "+972","locale": "en-IL"}, 
    {"name": "Italy", "code": "IT", "currency":"EUR", "phone": "+39","locale": "de-IT"}, 
    {"name": "Jamaica", "code": "JM", "currency":"JMD", "phone": "+1876","locale": "en-JM"}, 
    {"name": "Japan", "code": "JP", "currency":"JPY", "phone": "+81","locale": "ja-JP"}, 
    {"name": "Jersey", "code": "JE", "currency":"GBP", "phone": "+44","locale": "en-JE"}, 
    {"name": "Jordan", "code": "JO", "currency":"JOD", "phone": "+962","locale": "ar-JO"}, 
    {"name": "Kazakhstan", "code": "KZ", "currency":"KJT", "phone": "+7","locale": "kk-KZ)"}, 
    {"name": "Kenya", "code": "KE", "currency":"KES", "phone": "+254","locale": "en-KE"}, 
    {"name": "Kiribati", "code": "KI", "currency":"AUD", "phone": "+686","locale": ""}, 
    {"name": "South Korea", "code": "KP", "currency":"KPW", "phone": "","locale": "ko-KP"},
    // {"name": "Korea, Republic of", "code": "KR", "phone": "+","locale": ""}, 
    {"name": "Kuwait", "code": "KW", "currency":"KWD", "phone": "+965","locale": "ar-KW"}, 
    {"name": "Kyrgyzstan", "code": "KG", "currency":"KGS", "phone": "+996","locale": ""}, 
    {"name": "Lao People\'s' Democratic Republic", "code": "LA", "currency":"LAK", "phone": "","locale": "lo-LA"}, 
    {"name": "Latvia", "code": "LV", "currency":"EUR", "phone": "+371","locale": "lv-LV"}, 
    {"name": "Lebanon", "code": "LB", "currency":"LBP", "phone": "+961","locale": "ar-LB"}, 
    {"name": "Lesotho", "code": "LS", "currency":"ZAR", "phone": "+266","locale": "en-LS"}, 
    {"name": "Liberia", "code": "LR", "currency":"LRD", "phone": "+231","locale": "en-LR"}, 
    {"name": "Libyan Arab Jamahiriya", "code": "LY", "currency":"LYD", "phone": "","locale": "ar-LY"}, 
    {"name": "Liechtenstein", "code": "LI", "currency":"CHF", "phone": "+423","locale": ""}, 
    {"name": "Lithuania", "code": "LT", "currency":"EUR", "phone": "+370","locale": "lt-LT"}, 
    {"name": "Luxembourg", "code": "LU", "currency":"EUR", "phone": "+352","locale": "de-LU"}, 
    {"name": "Macao", "code": "MO", "currency":"MOP", "phone": "","locale": "en-MO"}, 
    {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK", "currency":"MKD", "phone": "+389","locale": "mk-MK"}, 
    {"name": "Madagascar", "code": "MG","currency":"MGA" , "phone": "+261","locale": "en-MG"}, 
    {"name": "Malawi", "code": "MW", "currency":"MWK", "phone": "+265","locale": "en-MW"}, 
    {"name": "Malaysia", "code": "MY", "currency":"MYR", "phone": "+60","locale": "en-MY"}, 
    {"name": "Maldives", "code": "MV", "currency":"MVR", "phone": "+960","locale": ""}, 
    {"name": "Mali", "code": "ML", "currency":"XOF", "phone": "+223","locale": "bm-ML"}, 
    {"name": "Malta", "code": "MT", "currency":"EUR", "phone": "+356","locale": "en-MT"}, 
    {"name": "Marshall Islands", "code": "MH", "currency":"USD", "phone": "+692","locale": "en-MH"}, 
    {"name": "Martinique", "code": "MQ", "currency":"EUR", "phone": "+596","locale": "fr-MQ"}, 
    {"name": "Mauritania", "code": "MR", "currency":"MRU", "phone": "+222","locale": "ar-MR"}, 
    {"name": "Mauritius", "code": "MU", "currency":"MUR", "phone": "+230","locale": "en-MU"}, 
    {"name": "Mayotte", "code": "YT", "currency":"EUR", "phone": "+262","locale": "fr-YT"}, 
    {"name": "Mexico", "code": "MX", "currency":"MXN", "phone": "+52","locale": "es-MX"}, 
    {"name": "Micronesia, Federated States of", "code": "FM", "currency":"USD", "phone": "","locale": "en-FM"}, 
    {"name": "Moldova, Republic of", "code": "MD", "currency":"MDL", "phone": "","locale": "ro-MD"}, 
    {"name": "Monaco", "code": "MC", "currency":"EUR", "phone": "+377","locale": "fr-MC"}, 
    {"name": "Mongolia", "code": "MN", "currency":"MNT", "phone": "+976","locale": "mn-MN"}, 
    {"name": "Montenegro", "code": "ME", "currency":"EUR", "phone": "+382","locale": "sr-Cyrl-ME"},
    {"name": "Montserrat", "code": "MS", "currency":"XCD", "phone": "+1664","locale": "en-MS"},
    {"name": "Morocco", "code": "MA", "currency":"MAD", "phone": "+212","locale": "ar-MA"}, 
    {"name": "Mozambique", "code": "MZ", "currency":"MZN", "phone": "+258","locale": "mgh-MZ"}, 
    {"name": "Myanmar", "code": "MM", "currency":"MMK", "phone": "+95","locale": "my-MM"}, 
    {"name": "Namibia", "code": "NA", "currency":"ZAR", "phone": "+264","locale": "af-NA"}, 
    {"name": "Nauru", "code": "NR", "currency":"AUD", "phone": "","locale": "en-NR"}, 
    {"name": "Nepal", "code": "NP", "currency":"NPR", "phone": "+977","locale": "ne-NP"}, 
    {"name": "Netherlands", "code": "NL", "currency":"EUR", "phone": "+599","locale": "en-NL"},
    {"name": "New Caledonia", "code": "NC", "currency":"XPF", "phone": "+687","locale": "fr-NC"}, 
    {"name": "New Zealand", "code": "NZ", "currency":"NZD", "phone": "+64","locale": "en-NZ"}, 
    {"name": "Nicaragua", "code": "NI", "currency":"NIO", "phone": "+505","locale": ""}, 
    {"name": "Niger", "code": "NE", "currency":"XOF", "phone": "+227","locale": "dje-NE"}, 
    {"name": "Nigeria", "code": "NG", "currency":"NGN", "phone": "+234","locale": "en-NG"}, 
    {"name": "Niue", "code": "NU", "currency":"NZD", "phone": "+683","locale": "en-NU"}, 
    {"name": "Norfolk Island", "code": "NF", "currency":"AUD", "phone": "+672","locale": "en-NF"}, 
    {"name": "Northern Mariana Islands", "code": "MP", "currency":"USD", "phone": "+1670","locale": "en-MP"}, 
    {"name": "Norway", "code": "NO", "currency":"NOK", "phone": "+47","locale": "nb-NO"}, 
    {"name": "Oman", "code": "OM", "currency":"OMR", "phone": "+968","locale": "ar-OM"}, 
    {"name": "Pakistan", "code": "PK", "currency":"PKR", "phone": "+92","locale": "en-PK"}, 
    {"name": "Palau", "code": "PW", "currency":"USD", "phone": "+680","locale": "en-PW"}, 
    {"name": "Palestinian Territory, Occupied", "code": "PS", "currency":"EUR", "phone": "+970","locale": "ar-PS"}, 
    {"name": "Panama", "code": "PA", "currency":"USD", "phone": "+507","locale": "es-PA"}, 
    {"name": "Papua New Guinea", "code": "PG", "currency":"PGK", "phone": "+675","locale": "en-PG"}, 
    {"name": "Paraguay", "code": "PY", "currency":"PYG", "phone": "+595","locale": "es-PY"}, 
    {"name": "Peru", "code": "PE", "currency":"PEN", "phone": "+51","locale": "es-PE"}, 
    {"name": "Philippines", "code": "PH", "currency":"PHP", "phone": "+63","locale": "en-PH"}, 
    {"name": "Pitcairn", "code": "PN", "currency":"NZD", "phone": "","locale": "en-PN"}, 
    {"name": "Poland", "code": "PL", "currency":"PLN", "phone": "+48","locale": "pl-PL"}, 
    {"name": "Portugal", "code": "PT", "currency":"EUR", "phone": "+351","locale": "pt-PT"}, 
    {"name": "Puerto Rico", "code": "PR", "currency":"USD", "phone": "+1787","locale": "en-PR"}, 
    {"name": "Qatar", "code": "QA", "currency":"QAR", "phone": "+974","locale": "ar-QA"}, 
    {"name": "Reunion", "code": "RE", "currency":"EUR", "phone": "+262","locale": "fr-RE"},
    {"name": "Romania", "code": "RO", "currency":"RON", "phone": "+40","locale": "ro-RO"}, 
    {"name": "Russian Federation", "code": "RU", "currency":"RUB", "phone": "","locale": "ce-RU"}, 
    {"name": "RWANDA", "code": "RW", "currency":"RWF", "phone": "+250","locale": "en-RW"}, 
    {"name": "Saint Helena", "code": "SH", "currency":"SHP", "phone": "","locale": "en-SH"}, 
    {"name": "Saint Kitts and Nevis", "code": "KN", "currency":"XCD", "phone": "","locale": "en-KN"}, 
    {"name": "Saint Lucia", "code": "LC", "currency":"XCD", "phone": "","locale": "en-LC"}, 
    {"name": "Saint Pierre and Miquelon", "code": "PM", "currency":"EUR", "phone": "","locale": "fr-PM"}, 
    {"name": "Saint Vincent and the Grenadines", "code": "VC", "currency":"XCD", "phone": "","locale": "en-VC"}, 
    {"name": "Samoa", "code": "WS", "currency":"WST", "phone": "+685","locale": "en-WS"}, 
    {"name": "San Marino", "code": "SM", "currency":"EUR", "phone": "+378","locale": "it-SM"}, 
    {"name": "Sao Tome and Principe", "code": "ST", "currency":"STN", "phone": "+239","locale": "pt-ST"}, 
    {"name": "Saudi Arabia", "code": "SA", "currency":"SAR", "phone": "+966","locale": ""}, 
    {"name": "Senegal", "code": "SN", "currency":"XOF", "phone": "+221","locale": "dyo-SN"}, 
    {"name": "Serbia", "code": "RS", "currency":"RSD", "phone": "+381","locale": "sr-Cyrl-RS"}, 
    {"name": "Seychelles", "code": "SC", "currency":"SCR", "phone": "+248","locale": "en-SC"}, 
    {"name": "Sierra Leone", "code": "SL", "currency":"SLE", "phone": "+232","locale": "en-SL"}, 
    {"name": "Singapore", "code": "SG", "currency":"SGD", "phone": "+65","locale": "en-SG"}, 
    {"name": "Slovakia", "code": "SK", "currency":"EUR", "phone": "+421","locale": "sk-SK"}, 
    {"name": "Slovenia", "code": "SI", "currency":"EUR", "phone": "+386","locale": "en-SI"}, 
    {"name": "Solomon Islands", "code": "SB", "currency":"SBD", "phone": "+677","locale": "en-SB"}, 
    {"name": "Somalia", "code": "SO", "currency":"SOS", "phone": "+252","locale": "ar-SO"}, 
    {"name": "South Africa", "code": "ZA", "currency":"ZAR", "phone": "+27","locale": "af-ZA"}, 
    {"name": "South Georgia and the South Sandwich Islands", "code": "GS", "currency":"USD", "phone": "","locale": ""}, 
    {"name": "Spain", "code": "ES", "currency":"EUR", "phone": "+34","locale": ""}, 
    {"name": "Sri Lanka", "code": "LK", "currency":"LKR", "phone": "+94","locale": "si-LK"}, 
    {"name": "Sudan", "code": "SD", "currency":"SDG", "phone": "+249","locale": "ar-SD"}, 
    {"name": "Suriname", "code": "SR", "currency":"SRD", "phone": "+597","locale": "nl-SR"}, 
    {"name": "Svalbard and Jan Mayen", "code": "SJ", "currency":"NOK", "phone": "","locale": ""}, 
    {"name": "Swaziland", "code": "SZ", "currency":"SZL", "phone": "+268","locale": "en-SZ"}, 
    {"name": "Sweden", "code": "SE", "currency":"SEK", "phone": "+46","locale": "en-SE"}, 
    {"name": "Switzerland", "code": "CH", "currency":"CHE", "phone": "+41","locale": "de-CH"},
    {"name": "Taiwan, Province of China", "code": "TW", "currency":"TWD", "phone": "","locale": "zh-Hant-TW"}, 
    {"name": "Tajikistan", "code": "TJ", "currency":"TJS", "phone": "+992","locale": "tg-TJ"}, 
    {"name": "Tanzania, United Republic of", "code": "TZ", "currency":"TZS", "phone": "","locale": "asa-TZ"}, 
    {"name": "Thailand", "code": "TH", "currency":"THB", "phone": "+66","locale": "th-TH"}, 
    {"name": "Timor-Leste", "code": "TL", "currency":"USD", "phone": "","locale": "pt-TL"}, 
    {"name": "Togo", "code": "TG", "currency":"XOF", "phone": "+228","locale": "ee-TG"}, 
    {"name": "Tokelau", "code": "TK", "currency":"NZD", "phone": "","locale": "en-TK"}, 
    {"name": "Tonga", "code": "TO", "currency":"TOP", "phone": "+676","locale": "en-TO"}, 
    {"name": "Trinidad and Tobago", "code": "TT", "currency":"TTD", "phone": "+1868","locale": "en-TT"}, 
    {"name": "Tunisia", "code": "TN", "currency":"TND", "phone": "+216","locale": "ar-TN"}, 
    {"name": "Turkey", "code": "TR", "currency":"TRY", "phone": "","locale": "tr-TR"}, 
    {"name": "Turkmenistan", "code": "TM", "currency":"TMT", "phone": "+993","locale": ""}, 
    {"name": "Turks and Caicos Islands", "code": "TC", "currency":"USD", "phone": "+1649","locale": "en-TC"}, 
    {"name": "Tuvalu", "code": "TV", "currency":"AUD", "phone": "+688","locale": "en-TV"}, 
    {"name": "Uganda", "code": "UG", "currency":"UGX", "phone": "+256","locale": "cgg-UG"}, 
    {"name": "Ukraine", "code": "UA", "currency":"UAH", "phone": "+380","locale": "ru-UA"}, 
    {"name": "United Arab Emirates", "code": "AE", "currency":"AED", "phone": "+971","locale": "ar-AE"}, 
    {"name": "United Kingdom", "code": "GB", "currency":"GBP", "phone": "+44","locale": "cy-GB"},
    {"name": "United States Minor Outlying Islands", "code": "UM", "currency":"USD", "phone": "","locale": "en-UM"}, 
    {"name": "Uruguay", "code": "UY", "currency":"UYI", "phone": "+598","locale": "es-UY"},
    {"name": "Uzbekistan", "code": "UZ", "currency":"UZS", "phone": "+998","locale": "uz-Cyrl-UZ"}, 
    {"name": "Vanuatu", "code": "VU", "currency":"VUV", "phone": "+678","locale": "en-VU"}, 
    {"name": "Venezuela", "code": "VE", "currency":"VED", "phone": "+58","locale": "es-VE"}, 
    {"name": "Viet Nam", "code": "VN", "currency":"VND", "phone": "","locale": "vi-VN"}, 
    {"name": "Virgin Islands, British", "code": "VG", "currency":"USD", "phone": "+1284","locale": "en-VG"}, 
    {"name": "Virgin Islands, U.S.", "code": "VI", "currency":"USD", "phone": "+1340","locale": "en-VI"}, 
    {"name": "Wallis and Futuna", "code": "WF", "currency":"XPF", "phone": "+681","locale": "fr-WF"}, 
    {"name": "Western Sahara", "code": "EH", "currency":"MAD", "phone": "+212","locale": "ar-EH"}, 
    {"name": "Yemen", "code": "YE", "currency":"YER", "phone": "+967","locale": "ar-YE"}, 
    {"name": "Zambia", "code": "ZM", "currency":"ZMW", "phone": "+260","locale": "bem-ZM"}, 
    {"name": "Zimbabwe", "code": "ZW", "currency":"ZWL", "phone": "+263","locale": "en-ZW"} 
]