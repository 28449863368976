import FusePageCardedOne from '@fuse/core/FusePageCardedOne';
import { React, useEffect, useState } from "react";
import { Button, Box, Tabs, Tab, Tooltip } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from '@fuse/hooks';
import Conversation from "./Tabs/Conversation";
import Contract from "./Tabs/Contract";
import Activities from "./Tabs/Activities";
import axios from '@fuse/utils/axios';
import Files from 'app/main/service-request/components/Tabs/Files';

const useStyles = makeStyles({
	layoutRoot: {
		background: '#fcfcfc'
	},
	pullRight: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare: {
		borderRadius: '5px'
	},
	topBg: {
		background: '#fcfcfc'
	},
	contentCard: {
		marginBottom: "15px",
		borderRadius: "5px"
	}
});


function Details(props) {
	const classes = useStyles();
	const router = useRouter()
	const requestId = router.params.id;
	const [serviceTabValue, setServiceTabValue] = useState(0);
	const [ serviceRequestDetails, setServiceRequestDetails ] = useState(null);
	const [ files, setFiles ] = useState([])

	const handleChangeServiceTabValue = (event, newValue) => {
		setServiceTabValue(newValue);
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	function redirectToAssetProfile(){
		if(!serviceRequestDetails) return
		router.push(`/assets-list/${serviceRequestDetails.asset_id}`)
	}

	function flattenServiceRequest( data ){
		// const tempElement = document.createElement('div');
		// tempElement.innerHTML = data.description;
		// data.description = tempElement.textContent
		return{
			ticket_status_name: data.service_request_status.status_name,
			ticket_status_color: data.service_request_status.status_color.code,
			ticket_id: data.ticket_id,
			ticket_title: data.title,
			ticket_description: data.description,
			asset_name: data.asset?.asset_name ?? 'N/A',
			serial_number: data.asset?.serial_number ?? 'N/A',
			asset_id: data.asset?.id ?? null,
			ticket_category: data.service_request_categories?.category_name ?? 'N/A',
			agent: data.agent ? `${data.agent.first_name} ${data.agent.last_name}`: 'N/A',
			ticket_source: data.service_request_source.source_name,
			ticket_prioriy:  data?.service_request_priority ? data?.service_request_priority?.name : 'N/A',
			ticket_type: `${data.ticket_type.charAt(0).toUpperCase()}${data.ticket_type.slice(1)}`,
			contract: data.contract,
			contract_number: data?.contract?.entity_external_platform_id,
      contract_id: data?.contract?.id,
			requestor_company_name: data.user_company.company_name
		}
	}
	function fetchServiceRequestDetails(){
		axios.get(`/service/request/get-details?request_id=${requestId}&archived=true`).then((res)=>{
			setServiceRequestDetails(flattenServiceRequest(res.data.data.serviceTicketDetails))
		}).catch((err)=>console.log(err))
	}

	function redirectToContractProfile(){
		if(!serviceRequestDetails) return
		router.push(`/contract-details/${serviceRequestDetails?.contract_id}`)
	}

	useEffect(()=>{
		fetchServiceRequestDetails()
	},[]);

	function fetchFiles(){
        if(!requestId) return
        axios.get(`/service/request/get-files/${requestId}`).then((res)=>{
            const { files } = res.data.data;
            setFiles(files)
        }).catch((err)=>{   
            console.log(err);
        })
    }
    useEffect(()=>{
        fetchFiles()
    },[requestId])

	return (
		<>
			<FusePageCardedOne
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg: classes.topBg,
					contentCard: classes.contentCard
				}}
				content={
					<>
						<div className='py-32'>
							<Button className='transparent_icon_btn back_btn' onClick={router.back}>
								<i className='ti ti-arrow-left' />
							</Button>
							<div className='my-10'>
								<div>
									<span className="cstm_black_border_badge_holder open">
										<span className="dot_holder" style={{background: `${serviceRequestDetails?.ticket_status_color}`}}>&nbsp;</span>
										{serviceRequestDetails?.ticket_status_name}
									</span>
								</div>
								{/* <FormControl>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										className={`cstm_badge_select_holder ${status}`}
										value={status}
										label=""
									>
										<MenuItem value='open'>
											
										</MenuItem>
									</Select>
								</FormControl> */}
							</div>
							<div className='mt-10 mb-20'>
								<h2 className='main_page_heading mb-10'>{serviceRequestDetails?.ticket_title}
									<span className='extra_sub_heading'>(Ticket #: {serviceRequestDetails?.ticket_id})</span></h2>
									<div
										className='sub_content_holder w-full'
										dangerouslySetInnerHTML={{__html: serviceRequestDetails?.ticket_description}}
									/>
							</div>
							<div className='flexbox_holder justify-start'>
								<div className='each_grey_border_holder'>
									<div className='light_heading'>Asset Name</div>
									<div className='bottom_holder'>
										<span className='deep_heading'>{serviceRequestDetails?.asset_name}</span>
										<Tooltip
											title="View Details"
											placement="top"
											componentsProps={{
												tooltip: {
													sx: {
														fontFamily: '"Inter", sans-serif',
														fontWeight: '400',
														fontSize: '12px',
														bgcolor: '#0C111D',
														color: '#fff'
													},
												},
											}}
										>
											{serviceRequestDetails?.asset_name !== 'N/A' && <i className='ti ti-eye' style={{ cursor: 'pointer'}} onClick={redirectToAssetProfile}/>}
										</Tooltip>
									</div>
								</div>
								<div className='each_grey_border_holder'>
										<div className='light_heading'>Contract Number</div>
										<div className='bottom_holder'>
												<span className='deep_heading'>{serviceRequestDetails?.contract_number || 'N/A'}</span>
												<Tooltip 
														title="View Details" 
														placement="top"
														componentsProps={{
																tooltip: {
																sx: {
																		fontFamily: '"Inter", sans-serif',
																		fontWeight: '400',
																		fontSize: '12px',
																		bgcolor: '#0C111D',
																		color: '#fff'
																},
																},
														}}
												>
														{serviceRequestDetails?.contract_number && <i onClick={redirectToContractProfile} style={{cursor: 'pointer'}} className='ti ti-eye' />}
												</Tooltip>
										</div>
								</div>
								<div className='each_grey_border_holder'>
									<div className='light_heading'>Company Name</div>
									<div className='bottom_holder'>
										<span className='deep_heading'>{serviceRequestDetails?.requestor_company_name}</span>
									</div>
								</div>
								<div className='each_grey_border_holder'>
									<div className='light_heading'>Requestor</div>
									<div className='bottom_holder'>
										<span className='deep_heading'>You</span>
									</div>
								</div>
								<div className='each_grey_border_holder'>
									<div className='light_heading'>Category</div>
									<div className='bottom_holder'>
										<span className='deep_heading'>{serviceRequestDetails?.ticket_category}</span>
									</div>
								</div>
								<div className='each_grey_border_holder'>
									<div className='light_heading'>Agent Name</div>
									<div className='bottom_holder'>
										<span className='deep_heading'>{serviceRequestDetails?.agent}</span>
									</div>
								</div>
								<div className='each_grey_border_holder'>
									<div className='light_heading'>Source</div>
									<div className='bottom_holder'>
										<span className='deep_heading'>{serviceRequestDetails?.ticket_source}</span>
									</div>
								</div>
								<div className='each_grey_border_holder'>
									<div className='light_heading'>Type</div>
									<div className='bottom_holder'>
										<span className='deep_heading'>{serviceRequestDetails?.ticket_type}</span>
									</div>
								</div>
								<div className='each_grey_border_holder'>
									<div className='light_heading'>Priority</div>
									<div className='bottom_holder'>
										<span className='deep_heading'>{serviceRequestDetails?.ticket_prioriy}</span>
									</div>
								</div>
							</div>

							<div className='flex flex-wrap justify-between items-center mt-20 mb-40'>
								<Box className='cstm_tabs_holder mb-0'>
									<Tabs value={serviceTabValue} onChange={handleChangeServiceTabValue} aria-label="basic tabs example" className='tabs_main_holder'>
										<Tab className='each_tab_holder' label="Conversation" {...a11yProps(0)} />
										{/* <Tab className='each_tab_holder' label="Contract " {...a11yProps(1)} /> */}
										<Tab className='each_tab_holder' label={`Files (${files.length})`} {...a11yProps()} />

										<Tab className='each_tab_holder' label="Activities" {...a11yProps(2)} />
									</Tabs>
								</Box>
							</div>

							{serviceTabValue === 0 &&
								<Conversation/>
							}

							{/* {serviceTabValue === 1 &&
								serviceRequestDetails && <Contract contractInfo={serviceRequestDetails?.contract}/>
							} */}
							{(serviceTabValue === 1 ) &&
                                <Files serviceRequestId={requestId} />
                            }
							{serviceTabValue === 2 &&
								<Activities/>
							}
						</div>
					</>
				}
				innerScroll
			/>
		</>
	);
}

export default Details