import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations';
import DeleteConfirmModal from 'helper/DeleteConfirm';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';


const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
  const classes = useStyles();
	const table_name = "DEPARTMENT";
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const gridRef = useRef();
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [editData, setEditData ]  = useState({});
	const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false)
	const [editButtonClick, setEditButtonClick ]  = useState(false);
	const [editDone, setEditDone ]  = useState(false);
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);
	const [departmentData, setDepartmentData] = useState(null)
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const [loadingActivityLog,setLoadingActivityLog] = useState(false);
	const [filterApplied, setFilterApplied] = useState(false);
	const tab_value = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.tab_value
  );

	const tableHeaders = [
		"department"
	];



	const onGridReady = (params) =>{
		if(router.query.report_name == undefined) {
			// dispatch(setfilterlist({}));
			loadInitialState(params);
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}else{
			setRowGroupPanelShow('always')
			getReport(params);
		}
	}

	function onSortChanged(params){
		if (router.query.report_name == undefined) {
		  const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
		  saveView(rows);
		}
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		saveView("N/A", rows, gridRef.current.filterModelArr)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "department";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState(params) {
		axios
		  .get(`/user-view/show-view-order?table_name=${table_name}`)
		  .then((results) => {
			const { data } = results.data;
			if (data !== null) {
				const order = JSON.parse(data.order);
				const filter = JSON.parse(data.filter);
				gridRef.current = {...gridRef.current,filterModelArr:filter}
				params?.columnApi.applyColumnState({ state: order });
				params?.api.setFilterModel(filter);
				if(Object.keys(filter)?.length){
				  setFilterApplied(true)
				}
			  }
		  })
		  .catch((err) => {
			console.log(err);
		  });
	}

	function saveView(rows, filter, filterModelArr=null) {
		let payload = {}
		if(rows == 'N/A'){
		payload = {
			filter:  JSON.stringify(filterModelArr ?? filter),
			table_name : table_name
		}
		}else{
		payload = {
			order:  JSON.stringify(rows),
			table_name : table_name
		}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
		console.log('view updated')
		})
		.catch(err=>{
		console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		const report_name = router.query.report_name;
		if(report_name == undefined){
			const rows = event.columnApi.getColumnState();

			saveView(rows);
			dispatch(setrowGroupModel(rows));
			// const first_row = rows[0];
	
			// if(first_row.colId !== 'ag-Grid-AutoColumn'){
			// 	saveView(rows);
			// 	dispatch(setrowGroupModel([]));
			// }else{
			// 	dispatch(setrowGroupModel(rows));
			// }
		}
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
		// auto-size columns on row-grouping		
		// onRowGroupOpenedAutoSize();	
	}

	const handleConfirm = (data) => {
		setOpenDeleteDialog(true)
		setDepartmentData(data)
	}

	const handleClose = () => {
		setOpenDeleteDialog(false)
		setDepartmentData(null)
	}

	function handelDelete(){
		axios.delete(`/department/delete/${departmentData?.id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message, 5000);
			}
			else{
				handleClose()
				toast.success(res.data.message)
				props.refresh()
			}	
		}).catch((err)=>{
			console.log(err)
			toast.error(err.data.message);
		})
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onCellValueChanged(e){
		const payload = {
			department_name:e.data.department,
			edit_id:e.data.id,
		}
		if(!payload.department_name){
			toast.error('Department Name is required')
			props.refresh()
			return
		}
		axios.post('/department/edit', payload).then(response => {
			if(response.status == 201){
				toast.success(response.data.message)
				props.refresh()
			} else {
			//   onSuccess()
			  toast.success('Department updated Successfully.')
			  props.refresh()
			//   props.fetchAllHardwareAssets()
			}
			
		  
		}).catch(err => {
		  console.log('212=>',err);
		  toast.error('Something went wrong!')
		})

	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])
	
	useEffect(()=>{
		// onGridReady()
		return () => {
			dispatch(setQuickFilter(null))
		}
	},[props.endUsersAccounts])


	function handelEdit(data){
		dispatch(setFromData({id:data.id,
			department_name:data.department_name,
		}))
		dispatch(openFormViewerDialog())		
	}

	// function editCloseForm(data){
	// 	if(data){
	// 		setEditDone(true)
	// 		setEditButtonClick(false)
	// 	}
	// }
	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	function formatLogs(data){
		let length = data.length
		return data.map((item)=>{
			const deptObject = {
				id:item.id,
				action_id: `act${length}`,
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				department_name : item?.department?.department_name || item.department_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
			}
			length--
			return deptObject
		})
	}

	function getLogs(){
		setLoadingActivityLog(true)
		axios.get('/department/activities')
		.then(({data})=>{
			setLoadingActivityLog(false)
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	useEffect(()=>{
    setTabValue(tab_value)
  }, [tab_value]);

	function handleOpenForm(){
		dispatch(openFormViewerDialog())
	}

	return (
		<>	
				{(tabValue == 0) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'department'} logs={logs} displayTableName = {'Departments'}
				 onClick={handleChildClick}
				 onClickAddButton={handleOpenForm}
				 addPermission = {props.addDepartmentPermission}
			/>}		
			{(tabValue == 1) && (<ActivityAgGridListing module={'department'} logs={logs} displayTableName = {'Departments'}
				addPermission = {props.addDepartmentPermission}
				loadingActivityLog={loadingActivityLog}
				/>)}
			{(tabValue == 0) && <div className="ag-theme-alpine" style={{width: "100%",height:windowHeight+105,fontSize:'11px'}}>
				<AgGridReact
					ref={gridRef}
					rowData={props.endUsersAccounts}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowHeight = {30}
					// onGridSizeChanged = { onGridSizeChanged }
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}
					
					// get filter model
					onFilterChanged = { onFilterChanged }		
					onColumnMoved = { onColumnMoved }	
					onColumnVisible = { onColumnVisible }
					onColumnPinned = { onColumnPinned }
					onSortChanged={onSortChanged}

					//row grouping
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					groupHeaderHeight ={1}
					floatingFiltersHeight = {40}
					frameworkComponents={frameworkComponents}

					pagination={true}
					defaultColDef={{
						// flex: 1,
						// minWidth: 180,
						// sortable: true,
						enableRowGroup:true,
						resizable: true
					}}
				>
					<AgGridColumn 
						lockPosition={true}
						rowSelection={'single'}
						width={100}
						minWidth={100}
						maxWidth={100}
						suppressSizeToFit={true}
						headerName="Actions"
						pinned= 'right' 
						className="action_field"
						lockPinned={true}
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>
										{props.updateDepartmentPermission && <Tooltip id="edit" title="Edit" placement="top">
											{/* <Icon onClick={() => handelEdit(data) }
												style={{
													color: '#1d2939',
													paddingBottom: '5px',
													paddingRight:'30px',
													fontSize:'15px',
													cursor:'pointer'
												}}
											>
												edit
											</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-pencil" onClick={() => handelEdit(data)} />
										</Tooltip>}
										{props.deleteDepartmentPermission && <Tooltip id="delete" title="Delete" placement="top" >
											{/* <Icon
												onClick={() => handleConfirm(data) }
												style={{
													color: '#1d2939',
													paddingBottom: '3px',
													paddingRight:'30px',
													fontSize:'15px',
													cursor:'pointer'
												}}
											>
												delete
											</Icon> */}
											<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-trash" onClick={() => handleConfirm(data)} />
										</Tooltip>}
									</>
								)
							}else{
								return (<></>)
							}
						}}
					>
					</AgGridColumn>
					<AgGridColumn 
						field="department" 
						headerName= "Department" 
						editable={true}
						onCellValueChanged = {onCellValueChanged}
						sortable={ true } filter="text"
						headerComponentFramework={(props)=>(CustomFilter(props, 'department', gridRef))} 
						flex = {1}
						minWidth={200}
					>
					</AgGridColumn>					
				</AgGridReact>
				{/* <GeneralModal
					open={departmentData}
					title={'Delete Department'}
					text={<h4>Are you sure to delete this Department named as <b>{departmentData?.department_name}</b>?</h4>}
					handleClose={handleClose}
					handleConfirm={handelDelete}
				/> */}
				{
					openDeleteDialog && 
					<DeleteConfirmModal 
						openAlerModal={openDeleteDialog}
						module={'Department'}
						handleCloseAlertModal={handleClose} 
						handleAlertModal={handelDelete}
					/>
				}
			</div>}
		</>
	);
}

export default ListView
