import { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import useRouter from '@fuse/hooks/useRouter';
import axios from "@fuse/utils/axios";
import { useDispatch,useSelector } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { Icon, IconButton, SvgIcon } from '@material-ui/core';
import { ReactComponent as CheckCircle } from '../icons/check-circle.svg'
import { ReactComponent as View } from '../icons/view.svg'
import { ReactComponent as HalfP } from '../icons/half-p.svg'
import { usePermission } from '@fuse/hooks';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FuseLoading from '@fuse/core/FuseLoading';
// import { DialogTitle,DialogContent,DialogContentText,DialogActions,Button, Dialog } from '@material-ui/core';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { openFormViewerDialog,addLocation,editFormViewerDialog } from '../store/formViewDialogReducer';


const Accordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, 0.5)',
		borderRadius: '5px',
		// backgroundColor: 'rgba(0, 0, 0, 0.100)',
		color: '#1E2125',
		boxShadow: 'none',
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			marginBottom: '10px',
			borderBottom: '1px solid rgba(0,0,0, 0.5)'
		},
		marginBottom: '10px',
	},
  	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .03)',
		borderRadius: 0,
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
		minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
		margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		// backgroundColor: theme.palette.background.paper,
	},
	rolesAccordionList: {
		marginTop: '20px'
	},
	rolesAccordion: {
		marginTop: '10px',
		fontSize: '20px'
	},
	heading: {
		fontSize: theme.typography.pxToRem(20),
		fontWeight: theme.typography.fontWeightRegular,
		minWidth: '150px',
		fontWeight: 500
	},
	heading2: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
		marginLeft: '60px',
		borderRight: '1px solid #999999',
		paddingRight: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		fontSize: '18px'
	},
	permissionIcon: {
		marginLeft: '10px',
		fontSize: '25px',
	},
	// summary: {
	// 	pointerEvents: 'none',
	// },
	icon: {
		pointerEvents: 'auto',
	},
}));

function Main() {
	const classes = useStyles();
	const router = useRouter();
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const user = useCurrentUser();
	const [loading, setLoading] = useState(false);
	const [preDefinedRoles, setPreDefinedRoles] = useState([]);
	const [customRoles, setCustomRoles] = useState([])
	const [allLocation, setAllLocation] = useState([])
	const [preDefinedRolesExpand, setPreDefinedRolesExpand] = useState({
		index: null,
		expand: false
	})
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [roleDetails, setRoleDetails] = useState({})
	const [userPresent, setUserPresent] = useState(false)
	const [editButtonPress, setEditButtonPress] = useState(false)
	const openState = useSelector((state) => state.rolesApp.formViewDialog)
  const { hasPermission } = usePermission()
	const viewRolePermission = hasPermission('view-role')
	const createRolePermission = hasPermission('add-role')
	const updateRolePermission = hasPermission('update-role')
	const deleteRolePermission = hasPermission('delete-role')

	const togglePredefinedRolesExpand = (index) =>{
		setPreDefinedRolesExpand(prev => ({
		index: preDefinedRoles.find((role) => role.id === index).id,
		expand: !prev
		}))
	}

	async function getLocation(){
			// if(!viewPermission){
			// 	return;
			// }
		try {
			try {
				const res = await axios.get("/shipping/fetch-all");
				const { data } = res.data;
				// console.log("l_datra",data)
				let location = [];
				for (let i = 0; i < data.length; i++) {
					location.push({
						id: data[i].id,
						name: `${data[i]?.location_name}-${data[i]?.address1}, ${data[i]?.city}, ${data[i]?.state}, ${data[i]?.country}`
					});
				}
				// setAllLocation(location)
				dispatch(addLocation(location));
			} catch (err) {
				console.log(err);
			}
		} finally { }
	}

	const handleClickOpen = () => {
		setOpenDeleteDialog(true);
	};  

	const fetchRoles = () => {
		setLoading(true)
		axios.get('/roles').then(response => {
			const data = response.data.data
			let preDefinedRoles = data.preDefinedRoles
			preDefinedRoles.forEach(role => {
				if(role.description){
					role.description = role.description.replace(/, and Subscriptions/g, '').replace(/, \n\t\t\t\t\tand Subscriptions/g, '');	
				}
			});

			setPreDefinedRoles(preDefinedRoles)
			setCustomRoles(data.customRoles)
			setLoading(false)
		}).catch(err => {
			console.log(err)
			toast.error('Something went wrong!')
		})
	}

	function getLocationName(location){
		let final_addr = ''
		let location_ = JSON.parse(location || '[]')
		for(let i=0;i<location_.length;i++){
			final_addr += String(location_[i].name)+', '
		}
		return final_addr.slice(0,final_addr.length-2)
	}

	function handelEdit(id){
		console.log("57",editButtonPress)
		setEditButtonPress(true)
		axios.get(`/user-role/${id}`).then(response => {
			const data = response.data.data
			dispatch(openFormViewerDialog())
			dispatch(editFormViewerDialog(data))
			// getLocation()
		}).catch(err => {
			console.log(err)
			console.log("56",editButtonPress)
			toast.error('Something went wrong!')
		})
	}

	const handleDeleteDialogOpen = (data) => {
		setEditButtonPress(true)
		axios.get(`/user-role/info/${data.id}`).then(response => {
			const data_ = response.data.data
			setUserPresent(data_.present)
			// setEditButtonPress(false)
		}).catch(err => {
			console.log(err)
			// setEditButtonPress(false)
			toast.error('Something went wrong!')
		})
		handleClickOpen()
		setOpenDeleteDialog(data)
		setRoleDetails(data)
		// setEditButtonPress(false)
	}

	const handleClose = () => {
		setOpenDeleteDialog(false);
		fetchRoles()
		setEditButtonPress(false)
	};

	function handelDelete(id){
		axios.delete(`/user-role/delete/${id}`).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				handleClose()
				setEditButtonPress(false)
			}else{
				toast.error('Roles deleted successfully')
				fetchRoles()
				handleClose()
				setEditButtonPress(false)
				// dispatch(refreshTableAfterDelete())
			}
			const data = response.data.data
			// dispatch(openFormViewerDialog())
			// dispatch(editFormViewerDialog(data))
		}).catch(err => {
			console.log(err)
			toast.error('Something went wrong!')
		})
	}

	useEffect(() => {
		fetchRoles()
	}, [])

	useEffect(() => {
		if(!openState.open){
			fetchRoles()
			console.log("55",editButtonPress)
			setEditButtonPress(false)
		}
  }, [openState])

	if (!viewRolePermission) {
		return (
			<div>
				<Card>
					<CardContent>You don't have permission to view any Roles at this time. Please contact your Systems Administrator for access.</CardContent>
				</Card>
			</div>
		)
	}

	// if(loading){
	// 	return <div className={classes.root}>Loading...</div>
	// }
	if(loading){
		return(<FuseLoading />)
	}

	return (
		<div className={classes.root}>
			<div style={{ margin: '20px 30px' }}>
				{/* <p style={{ fontSize: '20px', fontWeight: 600 }}>Predefined Roles</p> */}
				<div className='flex flex-wrap justify-between items-center mb-10'>
          <Typography
            className='allAssets_text mb-0'
          ><span>Predefined Roles</span>
          </Typography>
        </div>
				<div className={classes?.rolesAccordionList}>
					{preDefinedRoles.map((role, index) => (
						<Accordion>
							<AccordionSummary
								className={classes.summary}
								expandIcon={<ExpandMoreIcon  className={classes.icon} />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								// IconButtonProps={{
								//   onClick: () => togglePredefinedRolesExpand(role.id)
								// }}
							>
								<div className='flex w-full'>
									<Typography className={classes.heading} style={{ width: '15%' }}>{role.display_name}</Typography>
									<div className='flex' style={{ width: '70%' }}>
										<Typography className={classes.heading2}>
											Assets										
											{role.display_name == 'Super-User' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle/></SvgIcon>}
											{role.display_name == 'Support' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle/></SvgIcon>}
											{role.display_name == 'View-Only' && <SvgIcon className={classes.permissionIcon}><View /></SvgIcon>}
											{role.display_name == 'Admin' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle/></SvgIcon>}
										</Typography>
										<Typography className={classes.heading2}>
											Contracts
											{role.display_name == 'Super-User' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle/></SvgIcon>}
											{role.display_name == 'Support' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle/></SvgIcon>}
											{role.display_name == 'View-Only' && <SvgIcon className={classes.permissionIcon}><View /></SvgIcon>}
											{role.display_name == 'Admin' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle/></SvgIcon>}
										</Typography>
										{/* <Typography className={classes.heading2}>
											Subscription
											{role.display_name == 'Super-User' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle/></SvgIcon>}
											{role.display_name == 'Support' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle/></SvgIcon>}
											{role.display_name == 'View-Only' && <SvgIcon className={classes.permissionIcon}><View /></SvgIcon>}
											{role.display_name == 'Admin' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle/></SvgIcon>}
										</Typography> */}
									</div>
								</div>
								<Typography></Typography>
							</AccordionSummary>
							<AccordionDetails>
								<div className='flex flex-col w-full p-10' style={{ backgroundColor: 'rgba(0,0,0,0.1)', gap: 10 }}>
									<Typography className='flex items-center h3'>
										<Icon style={{ opacity: '0.7' }}>location_on_outlined</Icon>
										<>All Locations</>
									</Typography>
									<Typography className='ml-8 h3'>
                    {role.display_name == 'Super-User' && role.description == null  ?
                      `The Super User role provides full access to all platform features, data,
                      and account settings across all locations.`
                      : role.display_name == 'Super-User' && role.description !== null? role.description:''}
                    {role.display_name == 'Support' && role.description == null ?
                      `The Support Role is exclusively utilized by the Dalos Support team when authorized access
                      to your instance is grantedin response to a ticket. This role shares the same permissions as
                      the 'Super User'. When the Support Role is active, all other users switch to view-only mode,
                      disabling their ability to add or edit during the support session.`
                      : role.display_name == 'Support' && role.description !== null?role.description:''}
                    {role.display_name == 'View-Only' && role.description == null ?
                      `The View Only role allows users to browse all data related to Assets, Contracts. The role does not grant access to System or Account settings.`
                      : role.display_name == 'View-Only' && role.description !== null?role.description:''}
                    {role.display_name == 'Admin' && role.description == null ?
                      ` The Admin role has the ability to add, update, and delete all data related to Assets, Contracts. This role does not grant access to System or Account settings. It's designed
                      for users who manage key data but don't require complete system-wide control.`
                      : role.display_name == 'Admin'  && role.description !== null?role.description:''}
                  </Typography>
								</div>
							</AccordionDetails>
						</Accordion>
					))}
				</div>
			</div>

			<div style={{ margin: '30px 30px' }}>
				{/* <p style={{ fontSize: '20px', fontWeight: 600 }}>Custom Roles</p> */}
				<div className='flex flex-wrap justify-between items-center mb-10'>
          <Typography
            className='allAssets_text mb-0'
          ><span>Custom Roles</span>
          </Typography>
        </div>
				<div className={classes.rolesAccordionList}>				
					{customRoles.length !== 0 ? customRoles.map(role => (
						<Accordion>
							<AccordionSummary
								className={classes.summary}
								expandIcon={<ExpandMoreIcon className={classes.icon} />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<div className='flex items-center w-full' >
									<Typography className={classes.heading} style={{ width: '15%',paddingLeft:"15px"}}>{role.display_name}</Typography>
									<div className='flex' style={{ width: '70%' }}>
										<Typography className={classes.heading2}>
											Assets
											{role.asset_permission == 'all' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle  /></SvgIcon>}
											{role.asset_permission == 'view' && <SvgIcon className={classes.permissionIcon}><View /></SvgIcon>}
											{role.asset_permission == 'custom' && <SvgIcon className={classes.permissionIcon} style={{ color: 'blue' }}><HalfP /></SvgIcon>}
										</Typography>
										<Typography className={classes.heading2}>
											Contracts
											{role.contract_permission == 'all' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle  /></SvgIcon>}
											{role.contract_permission == 'view' && <SvgIcon className={classes.permissionIcon}><View /></SvgIcon>}
											{role.contract_permission == 'custom' && <SvgIcon className={classes.permissionIcon} style={{ color: 'blue' }}><HalfP /></SvgIcon>}
										</Typography>
										{/* <Typography className={classes.heading2}>
											Subscription
											{role.sub_permission == 'all' && <SvgIcon className={classes.permissionIcon} style={{ color: 'green' }}><CheckCircle  /></SvgIcon>}
											{role.sub_permission == 'view' && <SvgIcon className={classes.permissionIcon}><View /></SvgIcon>}
											{role.sub_permission == 'custom' && <SvgIcon className={classes.permissionIcon} style={{ color: 'blue' }}><HalfP /></SvgIcon>}
										</Typography> */}
									</div>
									{/* dispatch(openFormViewerDialog() */}
									<div className='flex justify-end' style={{ width: '15%' }}>
										{updateRolePermission && <IconButton onClick={() => handelEdit(role.id)}>
											<Icon className={classes.icon}>edit_outlined</Icon>
										</IconButton>}
										{deleteRolePermission && <IconButton>
											<Icon onClick={() => handleDeleteDialogOpen(role)} className={classes.icon}>delete_outlined</Icon>
										</IconButton>}
									</div>
								</div>						
							</AccordionSummary>
							{!editButtonPress && <AccordionDetails>
								<div className='flex flex-col w-full p-10' style={{ backgroundColor: 'rgba(0,0,0,0.1)', gap: 10 }}>
									<Typography className='flex items-center h3'>
										<Icon style={{ opacity: '0.7' }}>location_on_outlined</Icon>
										<>Locations</>
									</Typography>
									<Typography style={{marginLeft:'10px'}}>({getLocationName(role.action_location)})</Typography>
									<Typography className='ml-8 h3'>
										Description: {role.description}
									</Typography>
								</div>
							</AccordionDetails>}
						</Accordion>
					))
					:
						// <Typography>No Custom Roles to show!</Typography>
						//delete this code. This is for dummy design
						<Accordion>
							<AccordionSummary
								className={classes.summary}						
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<div className='flex items-center w-full' >
									<Typography className={classes.heading} style={{ width: '15%' }}>
										{'No records found'}
									</Typography>
								</div>						
							</AccordionSummary>
						</Accordion>
					}
				</div>
			</div>

			<DeleteModelDialog
				userPresent = {userPresent}
				openDeleteDialog={openDeleteDialog}
				setRoleDetails={setRoleDetails}
				handelDelete={handelDelete}
				handleClose={handleClose}
			/>

		</div>
	);
}

export default Main

function DeleteModelDialog(props) {
	return (
		<div>
			{/* <Dialog
				open={props.openDeleteDialog}
				onClose={props.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Delete Roles"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.userPresent && <h4>Deleting this role will remove it from all associated users. Continue?</h4>}
						{!props.userPresent && <h4>Are you sure to delete this Role named as <b>{props.openDeleteDialog.display_name}</b>?</h4>}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleClose}>Cancel</Button>
					<Button onClick={() => props.handelDelete(props.openDeleteDialog.id)} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog> */}
			<Dialog
				className="cstm_dialog_modal small text-center"
				open={props.openDeleteDialog}
				// onClose={props.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
				<div className='heading_holder flex justify-end items-center w-full'>
					<Button className='transparent_icon_btn' onClick={props.handleClose}>
					<i className="icon ti ti-x" />
					</Button>
				</div>
				</DialogTitle>
				<DialogContent>
				<div className="warning_content_holder">
					<div className="exclamation_icon_holder red">
					<i className="ti ti-exclamation-mark" />
					</div>
					<h2 className="heading">Are you sure to delete the role?</h2>
					<p className="sub_content">You will loss all the process if you proceed</p>
				</div>
				{/* <DialogContentText id="alert-dialog-description">
					{props.text}
				</DialogContentText> */}
				</DialogContent>
				<DialogActions className="action_footer_holder">
				<Button onClick={props.handleClose} className="secondary_btn" >No, go back!</Button>
				<Button onClick={() => props.handelDelete(props.openDeleteDialog.id)} className="primary_btn">
					Yes, Proceed!
				</Button>
				</DialogActions>
      		</Dialog>
		</div>
	)
}
