import EndUsersAccountsTable from './tables';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import DynamicFieldsHeader from './header';
import axiosgeneral from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import { usePermission } from '@fuse/hooks';
import { usePusher } from '@fuse/hooks';
import useCurrentUser from '@fuse/hooks/useCurrentUser';
import { getContractStatus } from 'helper/status';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	}
});

const views = ['List View','Grid View'];

function CardedFullWidth2TabbedSample(props) {
	const filters = ['Select filter','Serial Number','Contract Number','Company Name','Location Name'];
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedView, setSelectedView] = useState(0);
	let [endUsersAccountsMod,setEndUsersAccounts] = useState([]);
	let [totalRecords,setTotalRecords] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const router = useRouter();
	const [currentFilter, setCurrentFilter] = useState(0);	
	const [currentSearch, setCurrentSearch] = useState('_');
	const allowedCols = ['serial_number','make','model','short_description','dynamic_attributes']
	const { hasPermission } = usePermission()
	const viewAssetPermission = hasPermission('view-assets',true)
	const createAssetPermission = hasPermission('add-assets',true)
	const updateAssetPermission = hasPermission('update-assets',true)
	const deleteAssetPermission = hasPermission('delete-assets',true)
	const {broadcastChannel} = usePusher()
	const new_incident_event = "permission_change"
	const user = useCurrentUser();

	function fetchAssets(rowsPerPage='all', page=0, filter='_', search='_'){
		setLoading(true);
		let report_view = false;
		if(router.query.report_name){
			report_view = true;
		}
		axiosgeneral.get(`/assets/archived-assets?page=${page}&limit=${rowsPerPage}&filter=${filter}&search=${search == '' ? '_' : search}&queryFilter=${router.query.queryFilter != undefined ? router.query.queryFilter : '_'}&report_view=${report_view}&archived=${true}`)
		.then(res => {
			setAllStates(res.data.data);
			setRowsPerPage(res.data.data.length)
		})
		.catch(err => {
			return err;
		})
		.finally(()=>setLoading(false));
	}

	function setAllStates(data){
		setEndUsersAccounts(data.assets);
		setTotalRecords(data.number_of_records);
	}

	useEffect(()=>{
		if(broadcastChannel/* && invoices.length*/){
			broadcastChannel.bind(new_incident_event, data => {
				if(data.user_companies_id == user.data.user_companies_id){
					location.reload();
				}
			})
			return () => broadcastChannel.unbind(new_incident_event)
		}
	},[broadcastChannel])

	function formatString(str){
        if(!str) return '-'
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

	function flattenEndUserAccounts(){
		return endUsersAccountsMod.map(acc => {
			return ({
				id: acc.id,
				source: formatString(acc?.source) || '-',
				portal: formatString(acc.portal) || '-',
				serial_no: acc?.entity_external_platform_id || '-',
				asset_type: formatString(acc?.asset_type) || '-',
				name:acc?.asset_name || '-',
				asset_tag: acc?.asset_tag || '-',
				asset_name: formatString(acc?.asset_name) || '-',
				purchase_price: acc?.purchase_cost || '-',
				purchase_date: acc.purchase_date ? dateReformatTo_mmm_dd_yyyy(acc.purchase_date) : '-',
				install_date: acc.install_date ? dateReformatTo_mmm_dd_yyyy(new Date(acc.install_date).toISOString()) : '-',
				order_number: acc?.order_number || '-',
				user_name: (acc?.assigned_user?.first_name && acc?.assigned_user?.last_name) ? `${acc?.assigned_user?.first_name} ${acc?.assigned_user?.last_name}(${acc?.assigned_user?.email})` : '-',
				assigned_user_details: acc?.assigned_user ?? null,
				status: formatString(acc?.asset_status?.status_name) || '-',
				status_value: {
					id: acc?.asset_status?.id,
					status_name: acc?.asset_status?.status_name,
					status_color: acc?.asset_status?.status_color
				},
				image_name: acc.image_name ?? '',
				shipping_location_details: {
					id: acc.shipping?.id,
					entity_external_platform_id: acc.shipping?.entity_external_platform_id,
					location_name: acc.shipping?.location_name,
					address1: acc.shipping?.address1 || '',
					city: acc.shipping?.city || '',
					state: acc.shipping?.state || '',
					country: acc.shipping?.country || '',
				},			
				contract_number: acc?.contract_header?.contract_number || '-',
				contract_tag: acc?.contract_header?.contract_header_status?.contract_status_name || 'No Contract Tag',
				contract_tag_color: acc?.contract_header?.contract_header_status?.status_color || null,
				contract_status: acc?.contract_header?.contract_end_date ? getContractStatus(acc?.contract_header?.contract_end_date)?.status : 'N/A',
				contract_status_value: {
					id: acc?.contract_header?.contract_header_status?.id,
					status_name: acc?.contract_header?.contract_header_status?.contract_status_name,
					status_color: acc?.contract_header?.contract_header_status?.status_color
				},
				model_name: acc?.asset_model?.model_name || '-',
				model_number: acc?.asset_model?.model_no || '-',
				category : acc?.asset_model?.category?.category_name || '-',
				manufacturer : acc?.asset_model?.manufacturer?.manufacturer_name || '-',
				location: acc.shipping ? `${acc.shipping.location_name}`: '-',
				address:  acc.shipping?.address,
				notes: acc?.notes || '-',
				created_date: dateReformatTo_mmm_dd_yyyy(acc?.created_at),
        updated_date: dateReformatTo_mmm_dd_yyyy(acc?.updated_at),
        source: dateReformatTo_mmm_dd_yyyy(acc?.source),
				network_status: acc?.connection_status,
				// model_name: acc?.model_name, 
				sku: acc?.hardware_sku || '-',
				make: acc?.make || '-',
				model_id: acc.model_id || null,
				service_id: acc?.default_price_catalogue?.service?.service_sku || '-',
				city: acc?.shipping?.city || '-',
				eol: acc.asset_model?.eol ? dateReformatTo_mmm_dd_yyyy(acc.asset_model?.eol) : '-',
				eosl: acc.asset_model?.eosl ? dateReformatTo_mmm_dd_yyyy(acc.asset_model?.eosl) : '-',
				warranty_end_date: acc.warranty_end_date ? dateReformatTo_mmm_dd_yyyy(acc.warranty_end_date) : '-',
				sub_category : acc?.sub_category || '-',
				location_obj:acc.shipping ? acc.shipping : '-',
				contract_header: acc.contract_header ? {
					entity_external_platform_id: acc.contract_header.entity_external_platform_id,
					id: acc.contract_header.id
				} : null,
				original_info:{
					serial_number: acc.serial_number,
					asset_name: acc.asset_name,
					asset_tag: acc.asset_tag,
					notes: acc.notes,
					order_number: acc.order_number,
					purchase_cost: acc.purchase_cost,
					purchase_date: acc.purchase_date ? ((new Date(acc.purchase_date)).toISOString()).split('T')[0] : null,
					install_date: acc.install_date ? ((new Date(acc.install_date)).toISOString()).split('T')[0] : null,
					warranty_end_date: acc.warranty_end_date ? ((new Date(acc.warranty_end_date)).toISOString()).split('T')[0] : null,
				},
				... (acc.dynamic_attributes ? {... Object.entries(acc.dynamic_attributes).reduce((final, el) => ({... final, [el[0]] : el[1]?.attribute_value || ""}), {})} : {}),
				mac_id:acc?.mac_id || 'N/A',
		})})
	}

	useEffect(async ()=>{
		fetchAssets()
	},[]);

	return (
		<FusePageCarded
			classes={{
				root: classes.layoutRoot,
				toolbar: 'p-0',
				topBg: classes.topBg,
				contentWrapper : classes.contentWrapper
			}}
			// header={
			// 	<DynamicFieldsHeader 
			// 	/>
			// }
			content={
				<>
				<div className={selectedTab !== 0 ? 'hidden' : ''}>
					<EndUsersAccountsTable 
						endUsersAccounts={flattenEndUserAccounts()}
						selectedView={selectedView}
						fetchAssets={fetchAssets}
						totalRecords={totalRecords}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						loading={loading}
						filters={filters}
						currentFilter={currentFilter}
						currentSearch={currentSearch}
						tableName='asset'
						viewAssetPermission = { viewAssetPermission }
						createAssetPermission = { createAssetPermission }
						updateAssetPermission = { updateAssetPermission }
						deleteAssetPermission = { deleteAssetPermission }
					/>
				</div>
				</>
			}
			innerScroll
		/>
	)
}

export default withReducer('archivedAssetsApp', reducer)(CardedFullWidth2TabbedSample);
