import useToast from '@fuse/hooks/useToast'
import {
     makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import axios from '@fuse/utils/axios'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import { Modal } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Editor from '@fuse/components/Editor';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import { generateProperLocationString } from '@fuse/utils/general'
import { useDebounce, usePermission, useRouter } from '@fuse/hooks'
import { formatPriceFieldOnBlur } from '@fuse/utils/currencyFormat'
import AddStatusFromQuickAction from './modal/AddStatusFromQuickAction'
import AddLocationFromQuickAction from './modal/AddLocationFromQuickAction'
import AddModelFromQuickAction from './modal/AddModelFromQuickAction'
import AddUserFromQuickAction from './modal/AddUserFromQuickAction'
import AddContractFromQuickAction from './modal/AddContractFromQuickAction'
import { useDispatch, useSelector } from 'react-redux';
import { quickAddedAsset } from '../../main/assets/store/reportViewDialogReducer'
import { countries } from '@fuse/utils/countryName'
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => (
    {
        uploadImageButton: {
            padding: '6px 16px',
            fontSize: '1.3rem',
            minWidth: '64px',
            boxSizing: 'border-box',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            textTransform: 'none',
            backgroundColor: '#6B2C57',
            color: '#ffffff',
            cursor: 'pointer'
        },
        loader: {
            display: 'flex',
            '& > span': {
                margin: '10px auto'
            },
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        subSection: {
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#F9FAFB',
            borderRadius: '4px'
        },
        endButtons: {
            borderRadius: '4px'
        },
        drawerWidth: {
            width: 520,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            overflowX: 'hidden',
            overflowY: 'hidden'
        },
    }
))
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}







function QuickAddAsset(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { hasPermission } = usePermission()
    const handleCloseForm = () => {
        props.close('asset')
    }
    const toast = useToast(dispatch)
    const [age, setAge] = useState('');
    const handleChangeExport = (event) => {
        setAge(event.target.value);
    };
    const [assetNote, setAssetNote] = useState('')
    const [assetNoteText, setAssetNoteText] = useState('')
    const [currentUser, setCurrentUser] = useState(null)
    const [locationMenu, setLocationMenu] = useState([])
	const [usersMenu, setUsersMenu] = useState([])
	const [contractsMenu, setContractsMenu] = useState([])
	const [modelMenu, setModelMenu] = useState([])
    const [ statusMenu,setStatusMenu ] = useState([])
    const [ currentUserError, setCurrentUserError] = useState(false)
    const addAssetStatusPermission = hasPermission('add-asset_status');
    const addModelPermission = hasPermission('add-model');
    const addLocationPermission = hasPermission('add-location');
    const addUserPermission = hasPermission('add-end-user');
    const addContractPermission = hasPermission('add-contract');
    const [ dynamicFieldArray, setDynamicFieldArray ] = useState([
        {
            'id': Date.now(),
            'serial_number': '',
            'has_serial_number_error': false,
            'serial_number_error': '',
            'asset_name': '',
            'has_asset_name_error': false,
            'asset_name_error': '',
            'asset_tag': '',
            'has_asset_tag_error': false,
            'asset_tag_error': ''
        }
    ]);
    const [ loading, setLoading ] = useState(false)
    const [ openAddStatusModal, setOpenAddStatusModal ] = useState(false);
    const [ openAddLocationModal, setOpenAddLocationModal ] = useState(false)
    const [ openAddModelModal, setOpenAddModelModal ] = useState(false)
    const [ openAddUserModal, setOpenAddUserModal ] = useState(false)
    const [ openAddContractModal, setOpenAddContractModel ] = useState(false)
    const [ initialAssetDetails, setInitialAssetDetails] = useState()
    // let [allSpan, setAllSpan] = useState([
	// 	{
	// 		id: Date.now(),
	// 		serial_number: '',
	// 		asset_tag: '',
	// 		asset_name: ''
	// 	},
	// ])
    const [purchaseDate,setPurchaseDate] = useState({
		date: null,
		error: false
	})
	const [installDate,setInstallDate] = useState({
		date: null,
		error: false
	})
	const [warrantyEndDate,setWarrantyEndDate] = useState({
		date: null,
		error: false
	})



    function onEditorContentChange(content) {
        setAssetNote(content)
    }

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const [deployedDate,setDeplaoyedDate] = useState({
		date: null,
		error: false
	})
	const [recoveryDate,setRecoveryDate] = useState({
		date: null,
		error: false
	})
    const [contractSearch, setContractSearch] = useState(null)
    const [optionalFields, setOptionalFields] = useState({
		contractId: null,
		orderNumber: null,
		macAddress: null,
        entity_external_platform_id: null,  
        // country: { "name": "United States", "code": "US", "currency": "USD" },

		// purchaseDate: null,
		// installDate: null,
		// warrantyEndDate: null,
	})
    const [ imageName, setImageName ] = useState('')
    const [ imageLocation, setImageLocation ] = useState('')
	const [ previewImageLink,setPreviewImageLink ] = useState('')
    const [ openImagePreview,setOpenImagePreview ] = useState(false)
    const [openAlerModal, setOpenAlertModal] = useState(false);
    const [symbol, setSymbol] = useState();

    // const tabName = (name, tabValue) => {
    //     return (
    //     <div style={{display:'flex', alignItems:"center"}}>
    //         <span style={{ padding: '10px'}} className={tabValue === value ? 'required_tabs': ''}>{name}</span>
    //          <span style={{ color: '#98A2B3', marginLeft: '7px' , display:tabValue === 2 ? 'none':''}}>
    //          <Icon style={{ fontSize: '1.7rem' }}>
    //          arrow_forward_ios
    //          </Icon>
    //          </span>
    //      </div>
    //     )
    //      }


    const steps = ['Asset Details', 'User Details', 'Contract Setup'];

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [captureFieldAddedEvent, setCaptureFieldAddedEvent] = useState('dddddd')
    const { data: editFromState } = useSelector((state) => state.assetsApp.formViewDialog);
    const [assetSpecs, setAssetSpecs] = useState([]);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    function handleSpecsValueChange(index,value,propName){
		assetSpecs[index][propName] = value
	}

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        // handleNext();
    };


    const manageCurrentUser = (value) => {
		if(!value){
			//unassign a user
			setCurrentUser(null)
			return
		}
		
		let [ name, email ] = value.split('(')

		let currentUser = usersMenu.filter(user => (user.email === email.slice(0,-1)))
        setCurrentUser(currentUser[0])
	}

    const manageCurrentContract = (value) => {
		// if(!value) return ''
		if(!value){
			//unassign a user
			setContractSearch(null)
			setOptionalFields(state => {return ({...state, contractId: null})})
			return
		} 
		const currentContract = contractsMenu.filter(contract => contract.entity_external_platform_id === value)
		setContractSearch(currentContract[0])
		setOptionalFields(state => {return ({...state,entity_external_platform_id:currentContract[0].entity_external_platform_id, contractId:currentContract[0].id})})
	}


    const fetchUserSuggestionsOnInputChange = useDebounce(value => {
		if(!value.length){
			setUsersMenu([])
			return
		}
		fetchUserSuggestions(value)
	}, 500);


    const fetchUserSuggestions = (search='') => {
		axios.get(`/assets/fetch-users-suggestion?search=${search}`).then(response => {
			let users = AlphabeticalSorting(response.data.data.users,'first_name')
			setUsersMenu(users.map((item)=> {
                return {
                    ...item,
                    phone: item.phone ?? ''
                }
            }))
		}).catch(err => {
			console.log('164=>',err);
			// toast.error('Something went wrong!')
		})
	}

    // const handleReset = () => {
    //     setActiveStep(0);
    //     setCompleted({});
    // };


    const fetchLocationSuggestions = (value = '') => {
		setLoading(true )
		axios.get(`assets/fetch-location-suggestion?value=${value}`).then(response => {
			let locations = AlphabeticalSorting(response.data.data.locations,'location_name')
			setLoading(false)
			setLocationMenu(locations)
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

    const fetchContractSuggestions = () => {
		axios.get('/assets/fetch-contract-suggestion').then(response => {
			let contracts = AlphabeticalSorting(response.data.data.contracts,'entity_external_platform_id')
			setContractsMenu(contracts)
		}).catch(err => {
			console.log('174=>',err);
			// toast.error('Something went wrong!')
		})
	}

    const fetchModelSuggestions = (value) => {
		axios.get('/assets/fetch-model-suggestion').then(response => {
			let models = AlphabeticalSorting(response.data.data,'model_no')
			setModelMenu(models)
		}).catch((err) => {
			console.log('260=>',err);
			// toast.error('Something went wrong!')
		})
	}

    function fetchAssetSpecs(model_id=null){
		if(!model_id) return
		let api_url = `/assets/fetch-asset-specs?model_id=${model_id}`
		if(editFromState?.id) api_url =  `/assets/fetch-asset-specs?model_id=${model_id}&asset_id=${editFromState?.id}`

		axios.get(api_url).then(res => {
			setAssetSpecs(res.data.data.response)
		}).catch((err) => {
			console.log('err',err);
			toast.error(err.response.data.message)
		})
	}

    const newStatusAddedEvent = (createdData) => {
        setOpenAddStatusModal(!openAddStatusModal)
        setValue("status", {
            id: createdData.id,
            label: createdData.status_name
        })
        clearErrors("status")
        fetchStatus()
    }

    const newModelAddedEvent = (createdData) => {
        setOpenAddModelModal(!openAddModelModal)
        setValue("model", {
            id: createdData.id,
            model_name: createdData.model_name,
            model_no: createdData.model_no,
        })
        clearErrors("model")
        fetchModelSuggestions()
    }


    const newLocationAddedEvent = (createdData) => {
        setOpenAddLocationModal(!openAddLocationModal)
        setValue('location',{
            entity_external_platform_id: createdData.entity_external_platform_id,
            id: createdData.id,
            location_name: createdData.location_name,
            address1: createdData.address1 || '',
            address2: createdData.address2 || '',
            city: createdData.city || '',
            state: createdData.state || '',
            country: createdData.country || '',
        })
        clearErrors("location")
        fetchLocationSuggestions()
    }


    const newUserAddedEvent = (createdData) => {
        setOpenAddUserModal(!openAddUserModal)
        setValue('userId', {
            id: createdData.id,
            first_name: createdData.first_name,
            last_name: createdData.last_name,
            phone: createdData.phone,
            email: createdData.email,
        })
        setCurrentUser({
            id: createdData.id,
            first_name: createdData.first_name,
            last_name: createdData.last_name,
            phone: createdData.phone,
            email: createdData.email,
        })
        fetchUserSuggestions()
    }


    const newContractAddedEvent = (createdData) => {
        setOpenAddContractModel(!openAddContractModal)
        // const currentContract = contractsMenu.filter(contract => contract.entity_external_platform_id === value)
		setContractSearch({
            id: createdData.id,
            entity_external_platform_id: createdData.entity_external_platform_id
        })
		setOptionalFields(state => {return ({...state, contractId:createdData.id})})
        fetchContractSuggestions()
    }

    const fetchStatus = () => {
		// setLoading(true)
		axios.get(`asset-status?type=hardware`).then(response => {
			let status = AlphabeticalSorting(response.data.data,'status_name')
			// setLoading(false)
			setStatusMenu(status.map((item)=>{
				return{
					id: item.id,
					label: item.status_name
				}
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

    // const handleSpanInput = (index, value, propName) => {
	// 	setAllSpan((spans) => {
	// 	spans[index][propName] = value
	// 	return [...spans]
	// 	})
	// }


    useEffect(() => {
		fetchLocationSuggestions()
		// fetchUserSuggestions()
		fetchContractSuggestions()
		fetchModelSuggestions()
		fetchStatus()
	}, [])



    const handleOpenCloseAddStatus = () => {
        setOpenAddStatusModal(!openAddStatusModal)
    }
    const handleOpenCloseAddModel = () => {
        setOpenAddModelModal(!openAddModelModal)
    }
    const handleOpenCloseAddLocation = () => {
        setOpenAddLocationModal(!openAddLocationModal)
    }
    const handleOpenCloseAddContract = () => {
        setOpenAddContractModel(!openAddContractModal)
    }
    const handleOpenCloseAddUser = () => {
        setOpenAddUserModal(!openAddUserModal);
    }


    const defaultValues = {
        status: null,
        model: null,
        location: null,
        userId: null,
        country: null,
        purchaseCost: '',
    };

    const validationSchema = [

        yup.object({
            // allSpan: yup.array().of(yup.object().shape({
            //     serial_number: yup.string().required('Serial Number is required!'),
            //     // asset_name: yup.string().required('Asset Name is required!'),
            // })),
            status: yup.object().shape({
                id: yup.string().required('Status is required.'),
                label: yup.string().required('Status is required.')
            }).required('Status is required!'),
            model: yup.object().shape({
                id: yup.string().required('Model is required!'),
                // entity_external_id: yup.string().required('Model is required!'),
                model_name: yup.string().required('Model is required!'),
                model_no: yup.string().required('Model is required!'),
            }).required('Model is required!'),
            location: yup.object().shape({
                id: yup.number().required('Location is required.'),
                entity_external_platform_id: yup.string().required('Status is required.'),
                location_name: yup.string().required('Location is required.'),
                address1: yup.string().required('Location is required.'),
                // address2: yup.string().required('Location is required.'),
                city: yup.string().required('Location is required.'),
                country: yup.string().required('Location is required.'),
                state: yup.string().required('Location is required.'),
                // zip: yup.string().required('Location is required.'),
            }).required('Location is required.'),
            purchaseCost: yup.string().trim().notRequired(),
            country: yup.object().nullable().when("purchaseCost", {
                is: (val) => !!val,
                then: yup.object().shape({
                    name: yup.string().required('Currency is required.'),
                    code: yup.string().required('Currency is required.'),
                    currency: yup.string().required('Currency is required.')
                }).required('Currency is required')
            })
            

        }),

        yup.object({
            userId: yup.object().shape({
                id: yup.string().notRequired(),
                first_name: yup.string().notRequired(),
                last_name: yup.string().notRequired(),
                phone: yup.string().notRequired(),
                email: yup.string().notRequired(),
            }).nullable()
        }),

        yup.object({
            // contractNumber: yup.nullable().string()
        })

    ];

    const currentValidationSchema = validationSchema[activeStep];


    const methods  = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(currentValidationSchema),
        mode: "onChange"
    });

    const { control, formState, handleSubmit, reset, setValue, clearErrors, getValues, trigger, register } = methods

    const { errors } = formState


    // const { fields, append, remove } = useFieldArray({
    //     name: 'allSpan',
    //     control,
    // });

    const addNewSpan = () => {
        let newDynamicField = {
            'id': Date.now(),
            'serial_number': '',
            'has_serial_number_error': false,
            'serial_number_error': '',
            'asset_name': '',
            'has_asset_name_error': false,
            'asset_name_error': '',
            'asset_tag': '',
            'has_asset_tag_error': false,
            'asset_tag_error': ''
        }
        setDynamicFieldArray([...dynamicFieldArray, newDynamicField])
	}

    const handleNext = async () => {
        await trigger();
        let validationObj = {}
		let validateAssetTag = {}
        switch(activeStep) {
            // Required Details
            case 0:
                let hasAssetNameError = false;
                let hasSerialNumberError = false;
                let hasAssetTagError = false;
                const dynamicFieldArrayCopy = dynamicFieldArray;
                if(Object.keys(errors).length !== 0) {
                    for(let i = 0 ; i < dynamicFieldArrayCopy.length; i++) {
                        if(dynamicFieldArrayCopy[i].serial_number === '' && dynamicFieldArrayCopy[i].serial_number.trim() === '') {
                            hasAssetNameError = true
                            dynamicFieldArrayCopy[i].has_serial_number_error = true
                            dynamicFieldArrayCopy[i].serial_number_error = 'Serial Number is required!'
                        } else {
                            dynamicFieldArrayCopy[i].has_serial_number_error = false
                            dynamicFieldArrayCopy[i].serial_number_error = ''
                        }
                        if(dynamicFieldArrayCopy[i].asset_name === '' && dynamicFieldArrayCopy[i].asset_name.trim() === '') {
                            hasSerialNumberError = true
                            dynamicFieldArrayCopy[i].has_asset_name_error = true
                            dynamicFieldArrayCopy[i].asset_name_error = 'Asset Name is required!'
                        } else if(dynamicFieldArrayCopy[i].asset_name.length > 40){
                            hasSerialNumberError = true
                            dynamicFieldArrayCopy[i].has_asset_name_error = true
                            dynamicFieldArrayCopy[i].asset_name_error = 'Asset Name should be under 40 characters!'
                        }else {
                            dynamicFieldArrayCopy[i].has_asset_name_error = false
                            dynamicFieldArrayCopy[i].asset_name_error = ''
                        }
                        if(dynamicFieldArray[i].asset_tag !== '') {
                            if(dynamicFieldArray[i].asset_tag.trim() === '') {
                                hasAssetTagError = true
                                dynamicFieldArrayCopy[i].has_asset_tag_error = true
                                dynamicFieldArrayCopy[i].asset_tag_error = 'Please enter the valid character!'
                            } else {
                                dynamicFieldArrayCopy[i].has_asset_tag_error = false
                                dynamicFieldArrayCopy[i].asset_tag_error = ''
                            }
                        } else {
                            dynamicFieldArrayCopy[i].has_asset_tag_error = false
                            dynamicFieldArrayCopy[i].asset_tag_error = ''
                        }
                    }
                    setDynamicFieldArray([...dynamicFieldArrayCopy])
                    return;
                } else {
                    for(let i = 0 ; i < dynamicFieldArrayCopy.length; i++) {
                        if(dynamicFieldArrayCopy[i].serial_number === '' && dynamicFieldArrayCopy[i].serial_number.trim() === '') {
                            hasAssetNameError = true
                            dynamicFieldArrayCopy[i].has_serial_number_error = true
                            dynamicFieldArrayCopy[i].serial_number_error = 'Serial Number is required!'
                        } else {
                            dynamicFieldArrayCopy[i].has_serial_number_error = false
                            dynamicFieldArrayCopy[i].serial_number_error = ''
                        }
                        if(dynamicFieldArray[i].asset_name === '' && dynamicFieldArray[i].asset_name.trim() === '') {
                            hasSerialNumberError = true
                            dynamicFieldArrayCopy[i].has_asset_name_error = true
                            dynamicFieldArrayCopy[i].asset_name_error = 'Asset Name is required!'
                        }else if(dynamicFieldArray[i].asset_name.length > 40){
                            hasSerialNumberError = true
                            dynamicFieldArrayCopy[i].has_asset_name_error = true
                            dynamicFieldArrayCopy[i].asset_name_error = 'Asset Name should be under 40 characters!'
                        }else {
                            dynamicFieldArrayCopy[i].has_asset_name_error = false
                            dynamicFieldArrayCopy[i].asset_name_error = ''
                        }
                        if(dynamicFieldArray[i].asset_tag !== '') {
                            if(dynamicFieldArray[i].asset_tag.trim() === '') {
                                hasAssetTagError = true
                                dynamicFieldArrayCopy[i].has_asset_tag_error = true
                                dynamicFieldArrayCopy[i].asset_tag_error = 'Please enter the valid character!'
                                
                            } else {
                                dynamicFieldArrayCopy[i].has_asset_tag_error = false
                                dynamicFieldArrayCopy[i].asset_tag_error = ''
                            }
                            
                        } else {
                            dynamicFieldArrayCopy[i].has_asset_tag_error = false
                            dynamicFieldArrayCopy[i].asset_tag_error = ''
                        }
                    }
                    setDynamicFieldArray([...dynamicFieldArrayCopy])
                }
                
                
                if(dynamicFieldArray.length > 0) {
                    if(hasAssetNameError || hasAssetTagError || hasSerialNumberError) {
                        return;
                    }
                    for(let dynamicField of dynamicFieldArray) {
                        let temp_serial_no = dynamicField.serial_number.toLocaleLowerCase()
                        if(!validationObj[temp_serial_no]){
                            validationObj[temp_serial_no] = true
                        }else{
                            toast.error(`Serial number ${dynamicField.serial_number} has a duplicate value`)
                            return
                        }

                        let tamp_asset_tag = dynamicField.asset_tag.toLocaleLowerCase()
                        if(dynamicField.asset_tag !== '' && !validateAssetTag[tamp_asset_tag]){
                            validateAssetTag[tamp_asset_tag] = true
                        }else if(validateAssetTag[tamp_asset_tag]){
                            toast.error(`Asset tag ${dynamicField.asset_tag} has a duplicate value`)
                            return
                        }
                    }
                    let payload = {
                        editId: Boolean(editFromState) ? editFromState.id.toString() : null,
                        allSpan: dynamicFieldArray
                    }
                    axios.post('/assets/checking-serial-number', payload).then(response => {
                        if(response.status == 201){
                            toast.error(response.data.message)
                        } else {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                    }).catch(err => {
                        console.log('212=>',err);
                        toast.error(err?.response?.data?.message || "Something went wrong!")
                    })
                    break;
                } else {
                    return;
                }

            case 1:
                // await trigger();
                // console.log(errors)
                if(Object.keys(errors).length !== 0) {
                    return;
                }
                // if(deployedDate.date === null) {
                //     toast.error('Deploy date is required.')
                //     return;
                // }
                // if(recoveryDate.date === null) {
                //     toast.error('Recovery date is required.')
                //     return;
                // }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            default:
                break; 
        }


        
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    
    const onSubmit = (data) => {
        // console.log(JSON.stringify(data));
        // console.log(currentUser)

        let payload = {
			allSpan: dynamicFieldArray,
			status_id: Number(data.status.id),
			model_id: data.model.id,
			shipping_location: data.location,
			notes: assetNoteText,
			note_style: assetNote,
			users_id: currentUser?.id ?? null,
			deployed_date: deployedDate.date,
			recovery_date: recoveryDate.date,
			image_location: imageLocation,
			contract_headers_id: Boolean(optionalFields.contractId) ? optionalFields.contractId : null,
			purchase_cost: Boolean(data.purchaseCost) ? data.purchaseCost.replaceAll(',','') : null,
			purchase_date: purchaseDate.date ?? null,
			install_date: installDate.date ?? null,
			warranty_end_date: warrantyEndDate.date ?? null,
			eol_date: null,
			eosl_date: null,
			order_number: Boolean(optionalFields.orderNumber) ? optionalFields.orderNumber : null,
			mac_address: Boolean(optionalFields.macAddress) ? optionalFields.macAddress : null,
			image_name: imageName,
			country_code: data.country ? data.country.code : null
		}

        if(Boolean(editFromState)) payload.id = editFromState.id
		if(assetSpecs.length){
			payload.asset_spec = assetSpecs.map((item)=>{
				return{
					attribute_id: item.attribute.id,
					attribute_value: item.attribute_value
				}
			})
		} 

        let api_url = `/assets/hardware-asset-request`
        if(editFromState && !editFromState?.cloneItem) api_url = `/assets/update-hardware-asset-request`
        axios.post(api_url, payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
			} else {
                reset(defaultValues);
                (editFromState && !editFromState?.cloneItem)
                    ? toast.success('Asset Updated Successfully.')
                    : toast.success('Asset Added Successfully.')
                dispatch(quickAddedAsset())
                props.close('asset')
            }
		}).catch(err => {
			console.log('212=>',err);
			toast.error(err?.response?.data?.message || "Something went wrong!")
		})
    }



    const onChangeImageUpload = (event) => {
		setLoading(true)
		const selectedFile = event.target.files[0]
		if(!selectedFile) return
		if (selectedFile.size > 2000000) {
			toast.error("File size cannot exceed 2Mb!");
			setLoading(false)
			return;
		} else {
			let formData = new FormData()
			formData.append("file", selectedFile)
			axios.post('/assets/hardware-asset-image-upload', formData ).then(response => {
				if(response.status === 201) {
					toast.error(response.data.message)
				}
				else{
					setImageName(selectedFile.name)
					setImageLocation(response.data.data.imageLocation)
					setPreviewImageLink(response.data.data.imageLink)
				}
			}).catch(err => {
				toast.error('Something went wrong!')
			}).finally(() => {
				setLoading(false)
			})
		}
	}


    const handleDynamicFieldOnChangeEvent = (index, value, propName) => {
		setDynamicFieldArray((fieldArray) => {
		    fieldArray[index][propName] = value
		    return [...fieldArray]
		})
	}


    const serialNumberFieldOnChangeEvent = (value, index) => {
        if(value.trim() === "") {
            setDynamicFieldArray((fieldArray) => {
                fieldArray[index]['serial_number'] = value
                fieldArray[index].has_serial_number_error = true
                fieldArray[index].serial_number_error = 'Serial Number is Required.'
                return [...fieldArray]
            })
        } else {
            setDynamicFieldArray((fieldArray) => {
                fieldArray[index]['serial_number'] = value
                fieldArray[index].has_serial_number_error = false
                fieldArray[index].serial_number_error = ''
                return [...fieldArray]
            })
        }
    }


    const assetNameFieldOnChangeEvent = (value, index) => {
        if(value.trim() === "") {
            setDynamicFieldArray((fieldArray) => {
                fieldArray[index]['asset_name'] = value
                fieldArray[index].has_asset_name_error = true
                fieldArray[index].asset_name_error = 'Asset Name is Required.'
                return [...fieldArray]
            })
        }else if(value.length > 40){
            setDynamicFieldArray((fieldArray) => {
                fieldArray[index]['asset_name'] = value
                fieldArray[index].has_asset_name_error = true
                fieldArray[index].asset_name_error = 'Asset Name should be under 40 characters!'
                return [...fieldArray]
            })
        }else {
            setDynamicFieldArray((fieldArray) => {
                fieldArray[index]['asset_name'] = value
                fieldArray[index].has_asset_name_error = false
                fieldArray[index].asset_name_error = ''
                return [...fieldArray]
            })
        }
    }


    const assetTagFieldOnChangeEvent = (value, index) => {
        if(value !== '') {
            if(value.trim() === "") {
                setDynamicFieldArray((fieldArray) => {
                    fieldArray[index]['asset_tag'] = value
                    fieldArray[index].has_asset_tag_error = true
                    fieldArray[index].asset_tag_error = 'Please enter the proper character'
                    return [...fieldArray]
                })
                return;
            }
        }
        setDynamicFieldArray((fieldArray) => {
            fieldArray[index]['asset_tag'] = value
            fieldArray[index].has_asset_tag_error = false
            fieldArray[index].asset_tag_error = ''
            return [...fieldArray]
        })
    }


    const removeDynamicFieldFromArray = (index) => {
		setDynamicFieldArray(dynamicFieldArray.filter(fieldData => dynamicFieldArray[index] !== fieldData )  )
	}

	useEffect(()=>{
		if(!editFromState) return
		setDynamicFieldArray([{
			id: Date.now(),
			serial_number: editFromState.serial_no,
            'has_serial_number_error': false,
            'serial_number_error': '',
			asset_name: editFromState.name,
            'has_asset_name_error': false,
            'asset_name_error': '',
			asset_tag: editFromState?.cloneItem ? '' : (editFromState.asset_tag ?? ''),
            'has_asset_tag_error': false,
            'asset_tag_error': ''
		}])

        //status
        setValue('status',{
            id: editFromState.status_value.id,
            label: editFromState.status_value.status_name
        })

        //location
		setValue('location',editFromState.shipping_location_details)

        //model
        setValue('model', editFromState.asset_model)
        fetchAssetSpecs(editFromState.asset_model.id)
        //notes
		if(editFromState.notes != 'No Notes'){
			setAssetNote((editFromState.note_style && editFromState.note_style !==  '')  ? editFromState.note_style : `<p>${editFromState.notes}</p>`)
		}

        setAssetNoteText(editFromState.notes == 'No Notes'?'':editFromState.notes)

        setImageName(editFromState.image_name ?? '')
		setPreviewImageLink(editFromState?.preview_image_link ?? '')
        setImageLocation((editFromState?.preview_image_link ?? '').split("AWSAccessKeyId")[0].slice(0,-1))

        if(editFromState.assigned_user_details){
			fetchUserSuggestions(editFromState.assigned_user_details.email)	
		}

        setDeplaoyedDate((prev)=>{
			return{
				...prev,
				date: editFromState.deployed_date
			}
		})
		setRecoveryDate((prev)=>{
			return{
				...prev,
				date: editFromState.recovery_date
			}
		})

        setValue('purchaseCost', formatPriceFieldOnBlur(editFromState.purchase_cost) ?? '')
        const getCountryDetails = (countries.filter(country => country.code === editFromState.country_code))[0]
        setValue('country', getCountryDetails)
        if(getCountryDetails){
            setSymbol(Intl.NumberFormat(getCountryDetails?.locale !== ''  ? getCountryDetails?.locale : 'en-US', {
                style: 'currency',
                currency: getCountryDetails?.currency
              })
              .formatToParts(0)
              .filter(part => part.type === 'currency')
              .map(part => part.value)
              .join(''))
            }else{
                setSymbol()
        }
        setOptionalFields(state => {return ({
			...state, 
			orderNumber: editFromState.order_number,
			macAddress: editFromState.mac_address,
			// country: getCountryDetails,
            // purchaseCost: formatPriceFieldOnBlur(editFromState.purchase_cost)
		})})

        setPurchaseDate((prev) => {
			return {
				...prev,
				date: editFromState.purchase_date
			}
		})

		setInstallDate((prev) => {
			return {
				...prev,
				date: editFromState.install_date
			}
		})

		setWarrantyEndDate((prev) => {
			return {
				...prev,
				date: editFromState.warranty_end_date
			}
		})
        // setInitialAssetDetails({
		// 	serial_number: editFromState.serial_no,
		// 	asset_name: editFromState.name,
		// 	asset_tag: editFromState?.cloneItem ? '' : (editFromState.asset_tag ?? ''),
        //     status: editFromState.status_value?.status_name,
        //     location:editFromState.shipping_location_details?.location_name,
        //     model: editFromState?.asset_model?.model_name,
        //     notes: editFromState?.notes,
        //     image: editFromState?.image_name,
        //     userEmail: editFromState?.assigned_user_details?.email,
        //     deployedDate: editFromState?.deployed_date,
        //     recoveryDate: editFromState?.recovery_date,
        //     orderNumber: editFromState?.order_number,
        //     purchaseCost: editFromState?.purchase_cost,
        //     purchaseDate: editFromState?.purchase_date,
        //     installDate: editFromState?.install_date,
        //     warrantyEndDate: editFromState?.warranty_end_date,
        //     contractNumber: editFromState?.contract_header?.entity_external_platform_id,
        // })

	},[editFromState])

	useEffect(()=>{
		if(editFromState){
			manageCurrentUser(editFromState.assigned_user_details 
				? `${editFromState.assigned_user_details.first_name} ${editFromState.assigned_user_details.last_name} (${editFromState.assigned_user_details.email})`
				: null
			)
		}
	},[usersMenu])

    useEffect(()=>{
		if(editFromState && contractsMenu.length){
			manageCurrentContract(editFromState.contract_header?.entity_external_platform_id ?? '')
		}
	},[contractsMenu])

    const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        // const values = getValues()
        // if(!editFromState){

        //     if(dynamicFieldArray[0].asset_name !== ''|| dynamicFieldArray[0].asset_tag !== '' || 
        //         dynamicFieldArray[0].serial_number !== '' || values.status !== null || values.model !== null || values.location   !== null || assetNote || imageName !== '')
        //         {
        //             setOpenAlertModal(!openAlerModal)
        //         }else{
        //             handleCloseForm()
        //         }

        // }else if(!editFromState?.cloneItem){
        //     const purchaseCost = optionalFields?.purchaseCost

        //     if(dynamicFieldArray[0].asset_name !== initialAssetDetails.asset_name || dynamicFieldArray[0].asset_tag !== initialAssetDetails.asset_tag || 
        //         dynamicFieldArray[0].serial_number !== initialAssetDetails.serial_number || values.status?.label !== initialAssetDetails.status || values.model?.model_name !== initialAssetDetails.model || values.location?.location_name   !== initialAssetDetails.location || assetNote !== initialAssetDetails.notes || 
        //         imageName !== initialAssetDetails.image || currentUser?.email !== initialAssetDetails.userEmail || deployedDate.date !== initialAssetDetails.deployedDate || recoveryDate.date !== initialAssetDetails.recoveryDate || optionalFields?.orderNumber !== initialAssetDetails.orderNumber
        //         || purchaseCost !== initialAssetDetails.purchaseCost || purchaseDate.date !== initialAssetDetails.purchaseDate || installDate.date !== initialAssetDetails.installDate ||  warrantyEndDate.date !== initialAssetDetails.warrantyEndDate || optionalFields?.entity_external_platform_id  !== initialAssetDetails.contractNumber){
        //             setOpenAlertModal(!openAlerModal)
        //     }else{
        //         handleCloseForm()
        //     }
        // }else{
        //     handleCloseForm()
        // }
        setOpenAlertModal(!openAlerModal)
        
    }
    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        handleCloseForm()
    }

    return (
        <div>
            <Drawer anchor='right' open={props.open} classes={{ paper: classes.drawerWidth }} className='cstm_drawer_holder'>
                <div className='heading_holder'>
                    <h4 className='heading'>{editFromState ? (editFromState?.cloneItem ? 'Clone ' : 'Update ') : 'Add '} Asset</h4>
                    <IconButton onClick={handleAlertModal} style={{padding: '0px'}}>
                        <Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
                    </IconButton>
                </div>

                <div className='progressbar_holder'>
                    <div style={{width: activeStep === 0 ? '33.33%' : activeStep === 1 ? '66.67%' : activeStep === 2 ? '100%' : '0%'}} className='progress'>
                        &nbsp;
                    </div>
                </div>

                <div className='cstm_wizard_holder'>
                    <Box sx={{ width: '100%' }} className='box_holder'>
                        <Stepper activeStep={activeStep} className='stepper_holder'>
                            {steps.map((label, index) => (
                            <Step className='each_step_holder' key={label} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                                </StepButton>
                            </Step>
                            ))}
                        </Stepper>
                        <div className='main_content_holder'>
                            {/* {allStepsCompleted() ? (
                            <>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </>
                            ) : ( */}
                            <>
                                <h4 className='main_heading'>
                                    {activeStep === 0 && <span>Asset Details</span>}
                                    {activeStep === 1 && <span>User Details</span>}
                                    {activeStep === 2 && <span>Contract Setup</span>}
                                </h4>
                                <div className='content_holder'>
                                    {activeStep === 0 && 
                                        <div className='box_holder'>
                                            { 
                                                dynamicFieldArray.map((fieldData, index) => {
                                                    return (
                                                        <div>
                                                            <Grid className='grid_container' container spacing={2} key={fieldData.id}>
                                                                <Grid item xs={6}>
                                                                
                                                                    <Typography className='status_text'>
                                                                        Serial Number
                                                                        <span className='required_span'>*</span>
                                                                    </Typography>
                                                                    <Controller
                                                                        
                                                                        name="assetNameOfDynamicField" 
                                                                        control={control}
                                                                        render={( { field: { onChange, ...field } } ) => { 
                                                                            return (
                                                                                <TextField 
                                                                                    {...field}
                                                                                    style={{ background: 'white' }}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    value={fieldData.serial_number}
                                                                                    onChange={(event) => serialNumberFieldOnChangeEvent(event.target.value, index)}
                                                                                    error={fieldData.has_serial_number_error}
                                                                                    // onChange={(event) => handleDynamicFieldOnChangeEvent(index, event.target.value, "serial_number")}
                                                                                    helperText={fieldData.serial_number_error}
                                                                                    FormHelperTextProps={{
                                                                                        style: { 
                                                                                            margin : 0, 
                                                                                            backgroundColor: '#f4f4f4',
                                                                                            width: '100%',
                                                                                            paddingTop: '2px',
                                                                                            fontSize: '10px'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }}
                                                                    />
                                                                </Grid>                                     
                                                                <Grid item xs={6}>
                                                                    <Box>
                                                                        <Typography className='status_text'>
                                                                            Asset Name
                                                                            <span className='required_span'>*</span>
                                                                        </Typography>
                                                                        <Controller
                                                                            name='assetNameOfDynamicField' 
                                                                            control={control}
                                                                            render={( { field: { onChange, ...field } } ) => { 
                                                                                return (
                                                                                    <TextField 
                                                                                        {...field}
                                                                                        style={{background: 'white'}}
                                                                                        value={fieldData.asset_name}
                                                                                        error={fieldData.has_asset_name_error}
                                                                                        // onChange={(event) => handleDynamicFieldOnChangeEvent(index, event.target.value, "asset_name")}
                                                                                        helperText={fieldData.asset_name_error}
                                                                                        onChange={(event) => assetNameFieldOnChangeEvent(event.target.value, index)}
                                                                                        variant='outlined' 
                                                                                        fullWidth
                                                                                        required
                                                                                        // error={!!errors.allSpan?.[index]?.['asset_name']}
                                                                                        // helperText={errors?.allSpan?.[index]?.['asset_name']?.message}
                                                                                        FormHelperTextProps={{
                                                                                            style: { 
                                                                                                margin : 0, 
                                                                                                backgroundColor: '#f4f4f4',
                                                                                                width: '100%',
                                                                                                paddingTop: '2px',
                                                                                                fontSize: '10px'
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography className='status_text'>Asset Tag</Typography>
                                                                    <Controller
                                                                        name='assetNameOfDynamicField' 
                                                                        control={control}
                                                                        render={( { field: { onChange, ...field } } ) => (
                                                                            <TextField 
                                                                                {...field}
                                                                                style={{background: 'white'}}
                                                                                value={fieldData.asset_tag}
                                                                                error={fieldData.has_asset_tag_error}
                                                                                // onChange={(event) => handleDynamicFieldOnChangeEvent(index, event.target.value, "asset_tag")}
                                                                                onChange={(event) => assetTagFieldOnChangeEvent(event.target.value, index)}
                                                                                helperText={fieldData.asset_tag_error}
                                                                                FormHelperTextProps={{
                                                                                    style: { 
                                                                                        margin : 0, 
                                                                                        backgroundColor: '#f4f4f4',
                                                                                        width: '100%',
                                                                                        paddingTop: '2px',
                                                                                        fontSize: '10px'
                                                                                    }
                                                                                }}
                                                                                variant='outlined'
                                                                                // label='Asset Tag' 
                                                                                fullWidth
                                                                            />
                                                                        ) }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                                {!editFromState && <div className='action_holder flex justify-end items-center'>
                                                                    {dynamicFieldArray.length - 1 === index && <div className='add_another_holder inline-block mb-3'>
                                                                        <Button className='link_type_btn' onClick={() => addNewSpan()}>
                                                                            <Icon>add</Icon>
                                                                            <span>Add Another</span>
                                                                        </Button>
                                                                    </div>}
                                                                    {index !== 0 &&  <div className='remove_holder' onClick={() => removeDynamicFieldFromArray(index)}>
                                                                            <i className='ti ti-trash' />
                                                                        </div>
                                                                    }
                                                                </div>}                                                                
                                                        </div>                                                  
                                                    )                                                        
                                                })
                                            }                                                

                                            <Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>
                                                                Status
                                                                <span className='required_span'>*</span>
                                                            </Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                <Controller
                                                                    name="status"
                                                                    className="mb-16"
                                                                    control={control}
                                                                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                        <>
                                                                            <Autocomplete
                                                                                {...field} 
                                                                                style={{ width: '100%' }}
                                                                                className='main_autocomplete'
                                                                                options={statusMenu}
                                                                                // disablePortal
                                                                                onChange={(event, value) => field.onChange(value)}
                                                                                PaperComponent={({ children }) => (
                                                                                    <Paper className='autocomplete_holder'>{children}
                                                                                        {addAssetStatusPermission && <div className='p-16'>
                                                                                            <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddStatus()}>
                                                                                                <i className='ti ti-plus' />
                                                                                                <span>Add Status</span>
                                                                                            </Button>
                                                                                        </div>}
                                                                                    </Paper>
                                                                                )}
                                                                                getOptionLabel={option => option.label ?? ''}
                                                                                renderInput={(params) => {
                                                                                    return (
                                                                                        <div ref={params.InputProps.ref}>
                                                                                            <TextField
                                                                                                name="status"
                                                                                                style={{background: 'white'}}
                                                                                                {...params}
                                                                                                // label="Status"
                                                                                                required
                                                                                                defaultValue={defaultValues.status}
                                                                                                error={!!errors.status?.id}
                                                                                                helperText={errors?.status?.message && 'Status is required!'}
                                                                                                FormHelperTextProps={{
                                                                                                    style: { 
                                                                                                        margin : 0, 
                                                                                                        backgroundColor: '#f4f4f4',
                                                                                                        width: '100%',
                                                                                                        paddingTop: '2px',
                                                                                                        fontSize: '10px',
                                                                                                        color: '#FF0000'
                                                                                                    }
                                                                                                }}
                                                                                                variant="outlined"
                                                                                                fullWidth
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />                                                            
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>
                                                                Model
                                                                <span className='required_span'>*</span>
                                                            </Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                <Controller
                                                                    name="model"
                                                                    className="mb-16"
                                                                    control={control}
                                                                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                        <>
                                                                            <Autocomplete 
                                                                                {...field} 
                                                                                style={{ width: '100%' }}
                                                                                className='main_autocomplete'
                                                                                options={modelMenu}
                                                                                // disablePortal
                                                                                // value={modelSearch}
                                                                                defaultValue={defaultValues.model}
                                                                                onChange={(event, value) => {
                                                                                    field.onChange(value)
                                                                                    if(value) fetchAssetSpecs(value.id)
                                                                                }}
                                                                                PaperComponent={({ children }) => (
                                                                                    <Paper className='autocomplete_holder'>{children}
                                                                                        {addModelPermission && <div className='p-16'>
                                                                                            <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddModel()}>
                                                                                                <i className='ti ti-plus' />
                                                                                                <span>Add Model</span>
                                                                                            </Button>
                                                                                        </div>}
                                                                                    </Paper>
                                                                                )}
                                                                                getOptionLabel={option => `${option.model_no ?? ''}-${option.model_name}` }
                                                                                renderInput={(params) => {
                                                                                    return (
                                                                                        <div ref={params.InputProps.ref}>
                                                                                            <TextField
                                                                                                style={{background: 'white'}}
                                                                                                {...params}
                                                                                                // label="Model"
                                                                                                variant="outlined"
                                                                                                fullWidth
                                                                                                required
                                                                                                error={!!errors.model?.id}
                                                                                                helperText={errors?.model?.message && 'Model is required.'}
                                                                                                defaultValue={defaultValues.model}
                                                                                                FormHelperTextProps={{
                                                                                                    style: { 
                                                                                                        margin : 0, 
                                                                                                        backgroundColor: '#f4f4f4',
                                                                                                        width: '100%',
                                                                                                        paddingTop: '2px',
                                                                                                        fontSize: '10px',
                                                                                                        color: '#FF0000'
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }}
                                                                            />
                                                                            
                                                                            {/* <div style={{ width:'40%', padding: '10px', border: '1px solid #aaa', borderLeft:'0', borderRadius: '0 4px 4px 0'}}>
                                                                                <Button 
                                                                                variant="outlined" 
                                                                                color='primary' 
                                                                                size='small' 
                                                                                style={{  borderRadius: '4px' }}
                                                                                onClick={() => handleClickOpen()}
                                                                                >
                                                                                    New
                                                                                </Button>
                                                                            </div> */}
                                                                        </>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>
                                                                Location
                                                                <span className='required_span'>*</span>
                                                            </Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                                <Controller
                                                                    name="location"
                                                                    className="mb-16"
                                                                    control={control}
                                                                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                        <>
                                                                            
                                                                            <Autocomplete
                                                                                {...field}
                                                                                style={{ width: '100%' }}
                                                                                className='main_autocomplete'
                                                                                options={locationMenu}
                                                                                // disablePortal
                                                                                onChange={(event, value) => field.onChange(value)}
                                                                                PaperComponent={({ children }) => (
                                                                                    <Paper className='autocomplete_holder'>{children}
                                                                                        {addLocationPermission && <div className='p-16'>
                                                                                            <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddLocation()}>
                                                                                                <i className='ti ti-plus' />
                                                                                                <span>Add Location</span>
                                                                                            </Button>
                                                                                        </div>}
                                                                                    </Paper>
                                                                                )}
                                                                                getOptionLabel={
                                                                                    option => {
                                                                                        return generateProperLocationString({
                                                                                            location_name: option?.location_name ?? '',
                                                                                            address1: option?.address1 ?? '',
                                                                                            address2: option?.address2 ?? '',
                                                                                            city: option?.city ?? '',
                                                                                            state: option?.state ?? '',
                                                                                            country: option?.country ?? ''
                                                                                        })
                                                                                    } 
                                                                                }
                                                                                renderInput={(params) => {
                                                                                    return (
                                                                                    <div ref={params.InputProps.ref}>
                                                                                        <TextField
                                                                                            {...params}
                                                                                            style={{background: 'white'}}
                                                                                            // label="Location"
                                                                                            required
                                                                                            defaultValue={defaultValues.location}
                                                                                            error={!!errors?.location}
                                                                                            helperText={errors?.location?.message && "Location is required."}
                                                                                            FormHelperTextProps={{
                                                                                                style: { 
                                                                                                    margin : 0, 
                                                                                                    backgroundColor: '#f4f4f4',
                                                                                                    width: '100%',
                                                                                                    paddingTop: '2px',
                                                                                                    fontSize: '10px'
                                                                                                }
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                        />
                                                                                    </div>
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                        
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>
                                                                Currency
                                                               {getValues("purchaseCost") &&  <span className='required_span'>*</span>}
                                                            </Typography>
                                                            <Controller 
                                                            name='country'
                                                            control={control}
                                                            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                <>
                                                                <Autocomplete
                                                                    {...field}

                                                                    style={{ width: '100%' }}
                                                                    options={countries}
                                                                    value={getValues('country')}
                                                                    defaultValue={getValues('country')}
                                                                    getOptionLabel={option => option.currency ?? ''}
                                                                    onChange={(_, value) => {
                                                                        let params = value ? value : defaultValues.country
                                                                        field.onChange(params)

                                                                        if(getValues("country")){
                                                                        setSymbol(Intl.NumberFormat(getValues("country")?.locale !== ''  ? getValues("country")?.locale : 'en-US', {
                                                                            style: 'currency',
                                                                            currency: getValues("country")?.currency
                                                                          })
                                                                          .formatToParts(0)
                                                                          .filter(part => part.type === 'currency')
                                                                          .map(part => part.value)
                                                                          .join(''))
                                                                        }else{
                                                                            setSymbol()
                                                                        }
                                                                    }}
                                                                    renderOption={(option) => {
                                                                        return (
                                                                            <div className='flex items-center'>
                                                                                    <img
                                                                                        loading="lazy"
                                                                                        // width={"20px"}
                                                                                        className='mr-5 py-2'
                                                                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                                        alt=""
                                                                                    />
                                                                                
                                                                                <span className='pt-2'>{option.currency}</span>
                                                                            </div>
                                                                        )
                                                                    }}
                                                                    renderInput={params => {
                                                                        return (
                                                                            <div ref={params.InputProps.ref}>
                                                                                        <TextField
                                                                                            {...params}
                                                                                    variant='outlined'
                                                                                    fullWidth
                                                                                    // required
                                                                                    inputRef={ref}
                                                                                    defaultValue={defaultValues.country}
                                                                                    error={!!errors.country}
                                                                                    helperText={errors?.country?.message && "Currency is required."}
                                                                                    FormHelperTextProps={{
                                                                                        style: { marginLeft: 0 }
                                                                                    }}
                                                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                                                    InputProps={{
                                                                                        startAdornment:
                                                                                            <InputAdornment style={{ marginRight: '15px' }}>
                                                                                                {getValues('country') &&
                                                                                                    <img
                                                                                                        loading="lazy"
                                                                                                        width="20"
                                                                                                        src={`https://flagcdn.com/w20/${getValues('country')?.code.toLowerCase()}.png`}
                                                                                                        srcSet={`https://flagcdn.com/w40/${getValues('country')?.code.toLowerCase()}.png 2x`}
                                                                                                        alt=""
                                                                                                    />
                                                                                                }
                                                                                            </InputAdornment>
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }}
                                                                />
                                                                </>
                                                            )}
                                                        />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Purchase Price</Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                            <Controller
                                                                name='purchaseCost'
                                                                control={control}

                                                                render={( { field: { ref, ...field } } ) => { 
                                                                    return (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                variant='outlined' 
                                                                                inputRef={ref}
                                                                                fullWidth
                                                                                style={{background: 'white'}}
                                                                                error={!!errors.purchaseCost}
                                                                                helperText={errors?.purchaseCost?.message}
                                                                                FormHelperTextProps={{
                                                                                    style: {
                                                                                        backgroundColor: '#f4f4f4',
                                                                                        margin: '0px',
                                                                                        paddingTop: '2px'
                                                                                    }
                                                                                }}
                                                                                onBlur={()=>{
                                                                                    field.onChange(formatPriceFieldOnBlur(event.target.value));					
                                                                                }}
                                                                                onKeyPress={(event) => {
                                                                                    if (!/[0-9,.]+/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                onChange={event => {
                                                                                    field.onChange(event.target.value ?? '');
                                                                                }}
                                                                                InputProps={{
                                                                                    startAdornment: 
                                                                                        <InputAdornment>
                                                                                            <span style={{ marginRight: '8px', marginTop: '1px',fontSize: '13px' }}>{symbol}</span>
                                                                                        </InputAdornment>
                                                                                }}
                                                                            />
                                                                                                                                                
                                                                            </>                                                            
                                                                    )
                                                                }}
                                                            />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Purchase Date</Typography>                                                           
                                                            <CustomDatePickerV2
                                                                className="date_picker"
                                                                style={{ background: '#FCFCFD' }}
                                                                state={purchaseDate}
                                                                setState={setPurchaseDate}
                                                                required={true}
                                                                fromCustomFilter={false}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    
                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Install Date</Typography>
                                                            <CustomDatePickerV2
                                                                style={{ background: '#FCFCFD' }}
                                                                state={installDate}
                                                                setState={setInstallDate}
                                                                required={true}
                                                                fromCustomFilter={false}
                                                            />
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Warranty End Date</Typography>
                                                            <CustomDatePickerV2
                                                                style={{ background: '#FCFCFD' }}
                                                                state={warrantyEndDate}
                                                                setState={setWarrantyEndDate}
                                                                required={true}
                                                                fromCustomFilter={false}
                                                            />
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Order Number</Typography>
                                                            <TextField 
                                                                variant='outlined'
                                                                fullWidth
                                                                value={optionalFields.orderNumber}
                                                                onChange={event => setOptionalFields(prevState => { return { ...prevState, orderNumber: event.target.value } })}
                                                                style={{background: 'white'}}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Mac Address</Typography>
                                                            <TextField 
                                                                variant='outlined'
                                                                fullWidth
                                                                value={optionalFields.macAddress}
                                                                onChange={event => setOptionalFields(prevState => { return { ...prevState, macAddress: event.target.value } })}
                                                                style={{background: 'white'}}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    {/* Notes  */}
                                                    <Grid item xs={12}>
                                                        <div style={{ marginBottom: '10px' }}>
                                                            <Typography className='status_text'>Description</Typography>
                                                            <Editor
                                                                editorContent={assetNote}
                                                                onEditorContentChange={onEditorContentChange}
                                                                setAssetNoteText={setAssetNoteText} 
                                                                moduleType='asset'
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>

                                                {/* Image upload */}
                                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                                    <Typography className='status_text'>Upload Image</Typography>
                                                    <div className='upload_imgBox flex-wrap relative'>
                                                        
                                                        <label htmlFor="upload-photo" style={{ cursor: 'pointer', maxWidth: '200px' }}>
                                                            <span style={{ color: '#667085' }} className='icon_holder'>
                                                                <Icon>
                                                                    photo_camera
                                                                </Icon>
                                                                <span className='file_info_content'>Drag an image here or <span>upload a file</span> Supports only gif, png, jpg, jpeg Max size: 2Mb (rec. 700x430px)</span>
                                                            </span>
                                                        </label>
                                                        <input type="file" onChange = {(event) => onChangeImageUpload(event)} name="photo" id="upload-photo" />
                                                        <div style={{marginLeft: '10px'}} className='text-center w-full'>
                                                            { loading ? 
                                                                <>
                                                                    <div className={classes.loader}>
                                                                        <CircularProgress />
                                                                    </div>
                                                                </> : <Typography 
                                                                        onClick={()=>{
                                                                            if(imageName == '' || imageName == 'No Image Name') return;
                                                                            setOpenImagePreview(true)
                                                                        }}
                                                                        style={{color: '#000',fontSize: '14px',textDecoration:'none',cursor: 'pointer'}}
                                                                    >
                                                                        {imageName}
                                                                    </Typography>
                                                            }
                                                        </div>
                                                    </div>                                                    
                                                </div>

                                                { 
                                                    assetSpecs.map((assetSpec, index) => {
                                                        return ( 
                                                            <div style={{ display: 'flex', justifyContent:'center', width:'100%', border:'1px solid #ddd', borderRadius: '5px', padding: '10px', marginTop: '15px' ,marginBottom: '10px' }} key={index}>
                                                                <div style={{display:'flex', flexDirection: 'column', width:'100%'}}>
                                                                    <div style={{ display: 'flex', justifyContent:'center', marginBottom: '10px', width:'100%' }}>

                                                                        <div style={{ marginRight: '10px', width: '100%' }}>
                                                                            <TextField 
                                                                                label="Attribute"
                                                                                variant="outlined"
                                                                                style={{background: 'white'}}
                                                                                fullWidth 
                                                                                disabled
                                                                                value={assetSpec.attribute.attribute_name}
                                                                                onChange={() => {}}>
                                                                            </TextField>
                                                                            
                                                                        </div>

                                                                        <div style={{ width: '100%' }}>
                                                                            <TextField 
                                                                                label="Value"
                                                                                variant="outlined"
                                                                                style={{background: 'white'}}
                                                                                fullWidth
                                                                                defaultValue={assetSpec.attribute_value}
                                                                                onChange={(e) => handleSpecsValueChange(index,e.target.value,'attribute_value')}>
                                                                            </TextField>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                } 
                                            </Box>
                                        </div>
                                    }
                                    {activeStep === 1 && 
                                        <>
                                            <Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>User Name</Typography>
                                                            <Controller 
                                                                name='userId'
                                                                control={control}
                                                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                                                    <>
                                                                        <Autocomplete 
                                                                            style={{ width: '100%' }}
                                                                            className='main_autocomplete'
                                                                            options={usersMenu}
                                                                            // disablePortal
                                                                            value={currentUser}
                                                                            disableClearable={false}
                                                                            defaultValue={currentUser}
                                                                            onChange={(event, value)=> {
                                                                                field.onChange(value)
                                                                                manageCurrentUser(event.target.innerText)
                                                                            }}										
                                                                            PaperComponent={({ children }) => (
                                                                            	<Paper className='autocomplete_holder'>{children}
                                                                                    {addUserPermission && <div className='p-16'>
                                                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddUser()}>
                                                                                            <i className='ti ti-plus' />
                                                                                            <span>Add User</span>
                                                                                        </Button>
                                                                                    </div>}
                                                                                </Paper>
                                                                            )}
                                                                            getOptionLabel={option => `${option.first_name ?? ''} ${option.last_name ?? ''} (${option.email})` }
                                                                            renderInput={params => {
                                                                                return (
                                                                                    <div ref={params.InputProps.ref}>
                                                                                        <TextField
                                                                                            {...params}
                                                                                            style={{background: 'white'}}
                                                                                            // label="User Name"
                                                                                            placeholder='Start typing user name'
                                                                                            FormHelperTextProps={{
                                                                                                style: {marginLeft : 0}
                                                                                            }}
                                                                                            onChange={(event) => {
                                                                                                if(event && event.target) {
                                                                                                    fetchUserSuggestionsOnInputChange(event.target.value)													
                                                                                                }
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Phone Number</Typography>
                                                            <TextField
                                                                style={{ background: '#FCFCFD' }}
                                                                // {...field}
                                                                className='serial_numberField'
                                                                // value={span.serial_number}
                                                                // onChange={event => {
                                                                // 	handleSpanInput(index, event.target.value, "serial_number")
                                                                // }}
                                                                variant='outlined'
                                                                value={currentUser?.phone ?? ''}
                                                                // label='Serial Number'
                                                                // autoFocus
                                                                fullWidth
                                                                disabled
                                                                
                                                                // error={!!errors.allSpan?.[index]?.['serial_number']}
                                                                // helperText={errors?.allSpan?.[index]?.['serial_number']?.message}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        margin: 0,
                                                                        backgroundColor: '#FCFCFD',
                                                                        width: '100%',
                                                                        paddingTop: '2px',
                                                                        border: '1px solid #F2F4F7',
                                                                        fonstSize: '10px'
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Email Address</Typography>
                                                            <TextField
                                                                // {...field}
                                                                style={{ background: '#FCFCFD' }}
                                                                className='serial_numberField'
                                                                // value={span.serial_number}
                                                                // onChange={event => {
                                                                // 	handleSpanInput(index, event.target.value, "serial_number")
                                                                // }}
                                                                variant='outlined'
                                                                value={currentUser?.email ?? ''}
                                                                disabled
                                                                // label='Serial Number'
                                                                // autoFocus
                                                                fullWidth
                                                                // required
                                                                // error={!!errors.allSpan?.[index]?.['serial_number']}
                                                                // helperText={errors?.allSpan?.[index]?.['serial_number']?.message}
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        margin: 0,
                                                                        backgroundColor: '#FCFCFD',
                                                                        width: '100%',
                                                                        paddingTop: '2px',
                                                                        border: '1px solid #F2F4F7',
                                                                        fonstSize: '10px'
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Deployed Date</Typography>
                                                            {/* <CustomDatePicker
                                                        required={false}
                                                        state={recoveryDate}
                                                        setState={setRecoveryDate}
                                                        label='Recovery Date'
                                                    /> */}
                                                            <CustomDatePickerV2
                                                                className="date_picker"
                                                                style={{ background: '#FCFCFD' }}
                                                                state={deployedDate}
                                                                setState={setDeplaoyedDate}
                                                                required={true}
                                                                fromCustomFilter={false}
                                                            // label='Recovery Date'
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <div className='cstm-date' style={{ width: '100%' }}>
                                                            <Typography className='status_text'>Recovery Date</Typography>
                                                            {/* <CustomDatePicker
                                                        required={false}
                                                        state={recoveryDate}
                                                        setState={setRecoveryDate}
                                                        label='Recovery Date'
                                                    /> */}
                                                            <CustomDatePickerV2
                                                                style={{ background: '#FCFCFD' }}
                                                                state={recoveryDate}
                                                                setState={setRecoveryDate}
                                                                required={true}
                                                                fromCustomFilter={false}
                                                            // label='Recovery Date'
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                    {activeStep === 2 && 
                                        <>
                                            <Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Box>
                                                            <Typography className='status_text'>Contract Number</Typography>
                                                            <FormControl fullWidth className='assets_listSelect'>
                                                            <Autocomplete 
                                                                style={{ width: '100%' }}
                                                                className='main_autocomplete'
                                                                options={contractsMenu}
                                                                // disablePortal
                                                                value={contractSearch}
                                                                defaultValue={contractSearch}
                                                                onInputChange={event => {
                                                                    if(event) {
                                                                        manageCurrentContract(event.target.innerText)
                                                                    }
                                                                }}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper className='autocomplete_holder'>{children}
                                                                        {addContractPermission && <div className='p-16'>
                                                                            <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddContract()}>
                                                                                <i className='ti ti-plus' />
                                                                                <span>Add Contract</span>
                                                                            </Button>
                                                                        </div>}
                                                                    </Paper>
                                                                )}
                                                                getOptionLabel={option => option.entity_external_platform_id ?? ''}
                                                                renderInput={params => {
                                                                return (
                                                                    <div ref={params.InputProps.ref}>
                                                                        <TextField 
                                                                            {...params}
                                                                            // label='Contract Number'
                                                                            variant='outlined'
                                                                            fullWidth
                                                                            style={{background: 'white'}}
                                                                        />
                                                                    </div>
                                                                )
                                                                }}
                                                            />
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                </div>
                                <div className='btn_holder'>
                                    {activeStep !== 0 && 
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className='back_btn'
                                        >
                                            Back
                                        </Button>
                                    }

                                    {activeStep !== steps.length - 1 &&
                                        <Button onClick={handleNext} className='next_btn'>
                                            Next
                                        </Button>
                                    }
                                    {/* {activeStep !== steps.length &&
                                        (completed[activeStep] ? (
                                            <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                Step {activeStep + 1} already completed
                                            </Typography>
                                        ) : (
                                            <Button onClick={handleComplete}>
                                                {completedSteps() === totalSteps() - 1
                                                ? 'Proceed'
                                                : 'Complete Step'}
                                            </Button>
                                        ))
                                    } */}
                                    {activeStep === steps.length - 1 &&                                            
                                        <Button onClick={handleSubmit(onSubmit)} className='next_btn'>                                                
                                            Proceed
                                        </Button>                                            
                                    }
                                </div>
                            </>
                            {/* )} */}
                        </div>
                    </Box>

                </div>
            </Drawer>

            {openAlerModal &&
                <AlertModal  openAlerModal module="asset" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
            }

            {
                openAddStatusModal && 
                <AddStatusFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddStatus}
                    openAddStatusModalStateData={openAddStatusModal}
                    newDataAddedEvent={newStatusAddedEvent}
                />
            }   

            {
                openAddLocationModal && 
                <AddLocationFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddLocation}
                    openAddStatusModalStateData={openAddLocationModal}
                    newDataAddedEvent={newLocationAddedEvent}
                />
            }      

            {
                openAddModelModal && <AddModelFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddModel}
                    openAddStatusModalStateData={openAddModelModal}
                    newDataAddedEvent={newModelAddedEvent}
                />
            }             
            {
                openAddUserModal && <AddUserFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddUser}
                    openAddStatusModalStateData={openAddUserModal}
                    newDataAddedEvent={newUserAddedEvent}
                />
            }


            {
                openAddContractModal && <AddContractFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddContract}
                    openAddStatusModalStateData={openAddContractModal}
                    newDataAddedEvent={newContractAddedEvent}
                />
            }


            <Modal
				open={openImagePreview}
				className={classes.modal}
			>
				<div style={{
					height: 500,
					width: 500,
					backgroundColor: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems:'center',
					borderRadius: '13px',
					display: 'flex',
					flexDirection: 'column'
				}}>
					<IconButton 
						style={{left: '203px',bottom: '19px'}} 
						onClick={()=>{
							setOpenImagePreview(false)
						}}>
						<Icon>close</Icon>
					</IconButton>
					<img style={{height: '400px',width: '400px'}} src={previewImageLink} alt='' ></img>
				</div>
			</Modal>

        </div >
    )
}
export default QuickAddAsset