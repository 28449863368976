import useToast from '@fuse/hooks/useToast'
import { makeStyles } from '@material-ui/core'
import {
	Button, CircularProgress, Icon,
	IconButton, Paper, TextField, Tooltip, Typography,
	Drawer, Modal,Box, Grid
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeFormViewerDialog } from '../store/formViewDialogReducer'
import axios from '@fuse/utils/axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Autocomplete } from '@material-ui/lab'
import { setFromData } from '../store/formViewDialogReducer';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort'
import { usePermission } from '@fuse/hooks';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import Editor from '@fuse/components/Editor';
import AddManufacturerFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddManufacturerFromQuickAction'
import AddCategoryFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddCategoryFromQuickAction'
import AddAttributeFromQuickAction from '../../../fuse-layouts/shared-components/modal/AddAttributeFromQuickAction';
import AlertModal from 'helper/alertModal'

const useStyles = makeStyles(theme => ({
	uploadImageButton: {
		padding: '6px 16px',
		fontSize: '1.3rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		fontFamily: 'Inter, sans-serif',
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: '4px',
		textTransform: 'none',
		backgroundColor: '#6B2C57',
		color: '#ffffff',
		cursor: 'pointer'
	},
	loader: {
		display: 'flex',
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	subSection: {
		marginLeft: '20px !important',
		marginRight: '20px !important',
		marginBottom: '20px !important',
		padding: '10px',
		backgroundColor: '#f4f4f4',
		borderRadius: '4px'
	},
	endButtons: {
		borderRadius: '4px'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	drawerWidth: {
		width: 600
	},
	datePickerPopup: {
		'& .MuiPickersBasePicker-pickerView': {
			width: '200px', // Set the custom width here
		},
		customDatePicker: {
			// Set your desired width here, for example:
			width: '200px',
		},
	},
}))

function AddModelDialog(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const toast = useToast(dispatch)
	const openState = useSelector((state) => state.modelApp.formViewDialog)
	const { data: editFromState } = useSelector(({ modelApp }) => modelApp.formViewDialog);
	const [loading, setLoading] = useState(false)
	const [dynamicAttributesMenu, setDynamicAttributesMenu] = useState([])
	const spanTemp = {
		attribute: null,
		attribute_value: '',
	}
	const [allSpan, setAllSpan] = useState([])
	const [manufacturers, setManufacturers] = useState([])
	const [categoryMenu, setCategoryMenu] = useState([])
	const [imageName, setImageName] = useState('')
	const [imageLocation, setImageLocation] = useState('')
	const [previewImageLink, setPreviewImageLink] = useState('')
	const [openImagePreview, setOpenImagePreview] = useState(false)
	const { hasPermission } = usePermission()
	const createManufacturerPermission = hasPermission('add-manufacture')
	const createCategoryPermission = hasPermission('add-category')
	const createModelAttributePermission = hasPermission('add-model_attribute')
	const [eolDate, setEolDate] = useState({
		date: null,
		error: false
	})
	const [eoslDate, setEoslDate] = useState({
		date: null,
		error: false
	})
	const [description, setDescription] = useState('');
	const [openAlerModal, setOpenAlertModal] = useState(false);

	//form validation
	const schema = yup.object({
		model_name: yup.string().required('Model Name is required!').matches(/\S/, 'Model Name cannot be only spaces!'),
		model_no: yup.string().required('Model Number is required!').matches(/\S/, 'Model Number cannot be only spaces!'),
		manufacturer: yup.object().required('Manufacturer Name is required!'),
		category: yup.object().required('Manufacturer Name is required!')
	})

	const defaultValues = {
		model_name: '',
		model_no: '',
		manufacturer: null,
		category: null
	}

	const { control, formState, handleSubmit, reset, setValue, clearErrors } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const { errors } = formState;

	const addNewSpan = () => {
		let newSpan = {
			...spanTemp
		}
		setAllSpan((allSpan) => [...allSpan, newSpan])
	}

	const deleteSpan = (index) => {
		setAllSpan(allSpan.filter(span => allSpan[index] !== span))
	}

	const handleSpanInput = (index, value, propName) => {
		setAllSpan((spans) => {
			spans[index][propName] = value
			return [...spans]
		})
	}

	const onCancel = () => {
		reset(defaultValues)
		setEolDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setEoslDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		resetFormDetails()
		setAllSpan([{
			...spanTemp
		}])
		setImageName('')
		setImageLocation('')
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
		setDescription('')
	}

	/**--------------------Category---------------------------------------*/
	const [openCategoryDialog, setOpenCategoryDialog] = useState(false)

	const handleOpenCloseAddCategory = () => {
        setOpenCategoryDialog(!openCategoryDialog)
    }

	const newCategoryAddedEvent  = (createdData) => {
        setOpenCategoryDialog(!openCategoryDialog)
        setValue('category', {
            id: createdData.id,
            category_name: createdData.category_name
        })
        clearErrors('category')
        fetchCategorySuggestions()
    }
	/**-----------------Manufacture-------------------------------- */
	const [openManufactureDialog, setOpenManufactureDialog] = useState(false)

	const handleOpenCloseAddManufacturer = () => {
        setOpenManufactureDialog(!openManufactureDialog)
    }
	const newManufacturerAddedEvent = (createdData) => {
		handleOpenCloseAddManufacturer()
        setValue('manufacturer', {
			id: createdData.id, 
			entity_external_platform_id: createdData.entity_external_platform_id, 
			manufacturer_name: createdData.manufacturer_name
		})
        clearErrors('manufacturer')
        fetchManufacturerSuggestions()
    } 
	/**---------------Dynamic Attributes------------------------ */
	const [openDynamicAttributeDialog, setOpenDynamicAttributeDialog] = useState(false)
	const [indexOfQuickAddButtonClick, setIndexOfQuickAddButtonClick] = useState(-1)

	const handleOpenCloseAddAssetAttribute = (indexNumber) => {
        if(openDynamicAttributeDialog === false) {
            setIndexOfQuickAddButtonClick(indexNumber)
        } else {
            setIndexOfQuickAddButtonClick(-1)
        }
        setOpenDynamicAttributeDialog(!openDynamicAttributeDialog);
    }

	const newAttributeAddedEvent = (new_attribute) => {
        setOpenDynamicAttributeDialog(!openDynamicAttributeDialog)
		allSpan[indexOfQuickAddButtonClick] = {
			attribute: {
				attribute_name: new_attribute.attribute_name,
				id: new_attribute.id
			},
			attribute_value: ''
		}
        setIndexOfQuickAddButtonClick(-1)
    }
	/*---------------------------------------------------------- */
	const fetchManufacturerSuggestions = (value = '') => {
		setLoading(true)
		axios.get("/manufacturers").then(response => {
			setLoading(false)
			setManufacturers((AlphabeticalSorting(response.data.data, 'manufacturer_name')).filter((item) => { return Boolean(item.manufacturer_name) }))
		}).catch(err => {
			console.log('136=>', err);
			// toast.error('Something went wrong!')
		})
	}

	async function fetchAvailableDynamicField() {
		try {
			const res = await axios.get('/model-custom-attributes')
			const { data } = res.data
			setDynamicAttributesMenu(AlphabeticalSorting(data, 'attribute_name'))
		} catch (err) {
			console.log(err)
		}
	}

	const fetchCategorySuggestions = () => {
		axios.get('/category?category_type=hardware').then(response => {
			setCategoryMenu((AlphabeticalSorting(response.data.data, 'category_name')).filter((item) => { return Boolean(item.category_name) }))
		}).catch(err => {
			console.log('164=>', err);
			// toast.error('Something went wrong!')
		})
	}

	const onChangeImageUpload = (event) => {
		setLoading(true)
		const selectedFile = event.target.files[0]
		if (!selectedFile) return
		if (selectedFile.size > 2000000) {
			toast.error("File size cannot exceed 2Mb!");
			setLoading(false)
			return;
		} else {
			let formData = new FormData()
			formData.append("file", selectedFile)
			axios.post('/assets/hardware-asset-image-upload', formData).then(response => {
				if (response.status === 201) {
					toast.error(response.data.message)
				}
				else {
					setImageName(selectedFile.name)
					setImageLocation(response.data.data.imageLocation)
					setPreviewImageLink(response.data.data.imageLink)
				}
			}).catch(err => {
				console.log('136=>', err);
				toast.error('Something went wrong!')
			}).finally(() => {
				setLoading(false)
			})
		}
	}

	const onEditorContentChange = (content) => {
		setDescription(content)
	}
	const closeDialog = () => {
		setEolDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		setEoslDate((prev) => {
			return {
				...prev,
				date: null
			}
		})
		dispatch(closeFormViewerDialog())
		dispatch(setFromData(null))
		setImageName('')
		setImageLocation('')
		setAllSpan([{
			...spanTemp
		}])
	}

	const resetFormDetails = () => {
		// setOptionalFields({ 
		// 	contractId: null, orderNumber: null, purchaseCost: null, purchaseDate: null, 
		// 	installDate: null, warrantyEndDate: null, eolDate: null, eoslDate: null 
		// })
	}

	useEffect(() => {
		if (editFromState) {
			setValue('model_name', editFromState.model_name)
			setValue('model_no', editFromState.model_no)
			setValue('category', editFromState.category)
			setValue('manufacturer', editFromState.manufacturer)
			setEolDate((prev) => {
				return {
					...prev,
					date: editFromState.eol == "No Eol" ? null : editFromState.eol
				}
			})
			setEoslDate((prev) => {
				return {
					...prev,
					date: editFromState.eosl == "No Eosl" ? null : editFromState.eosl
				}
			})

			setDescription(editFromState.original_info.description)
			setImageName(editFromState.original_info.image_name)
			setPreviewImageLink(editFromState?.preview_image_link ?? '')
			setImageLocation(editFromState.image_link)

			let span_data = []
			for (let item of editFromState.dynamic_attribute_mapping) {
				span_data.push({
					attribute: item.model_custom_attribute,
					attribute_value: item.attribute_value,
					has_related_value_in_assets: item.model_custom_attribute.asset_spec
				})
			}

			setAllSpan([
				...span_data,
				{ ...spanTemp }
			])
		}
	}, [editFromState])

	const manageFormData = (formData) => {
		if (eolDate.error || eoslDate.error) return
		// if(imageName === '') {
		// 	toast.error('Can not proceed without uploading model image!')
		// 	return
		// }

		let dynamic_attributes = []
		for (let span of allSpan) {
			if (span.attribute) {
				// if(span.attribute_value === ''){
				// 	toast.error(`Can not proceed without value of ${span.attribute.attribute_name}`)
				// 	return 
				// }else{
				dynamic_attributes.push({
					attribute_id: span.attribute.id,
					attribute_value: String(span.attribute_value)
				})
				// }
			}
		}

		let payload
		let api_url

		if (!editFromState) {
			payload = {
				entity_external_platform_id: String(Math.trunc(Math.random() * 20000000)),
				model_name: formData.model_name,
				category_id: formData.category.id,
				manufacturer_id: formData.manufacturer.id,
				model_no: formData.model_no,
				description: description ?? '',
				eol_date: eolDate.date ?? '',
				eosl_date: eoslDate.date ?? '',
				image_link: imageLocation ?? '',
				image_name: imageName
			}
			if (dynamic_attributes.length) {
				payload.dynamic_attributes = dynamic_attributes
			}
			api_url = '/models/add'
		} else {
			payload = {
				id: editFromState.id,
				entity_external_platform_id: String(Math.trunc(Math.random() * 20000000)),
				model_name: formData.model_name,
				category_id: formData.category.id,
				manufacturer_id: formData.manufacturer.id,
				model_no: formData.model_no,
				description: description ?? '',
				eol_date: (eolDate.date == 'No Eol') ? '' : eolDate.date ? eolDate.date : '',
				eosl_date: (eoslDate.date == 'No Eosl') ? '' : eoslDate.date ? eoslDate.date : '',
				image_link: imageLocation ?? '',
				image_name: imageName ?? ''
			}
			if (dynamic_attributes.length) {
				payload.dynamic_attributes = dynamic_attributes
			}
			api_url = '/models/update'
		}

		console.log(payload)
		axios.post(api_url, payload).then(response => {
			if (response.status == 201) {
				toast.error(response.data.message)
			} else {
				reset(defaultValues)
				closeDialog()
				toast.success(response.data.message)
				dispatch(setQuickFilter(null))
				props.getModels()
			}
		}).catch(err => {
			console.log('212=>', err);
			// toast.error('Something went wrong!')
		})
	}


	useEffect(() => {
		fetchManufacturerSuggestions()
		fetchCategorySuggestions()
		fetchAvailableDynamicField()
		setAllSpan([{
			...spanTemp
		}])
	}, [])

	const handleAlertModal=(_, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        setOpenAlertModal(!openAlerModal)
    }

    const handleCloseAlertModal=() =>{
        setOpenAlertModal(false)
        onCancel()
    }
	return (
		<div>
			<Modal
				open={openImagePreview}
				className={classes.modal}
			>
				<div style={{
					height: 500,
					width: 500,
					backgroundColor: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '13px',
					display: 'flex',
					flexDirection: 'column'
				}}>
					<IconButton style={{ left: '203px', bottom: '19px' }} onClick={() => { setOpenImagePreview(false) }}>
						<Icon>close</Icon>
					</IconButton>
					<img style={{ height: '400px', width: '400px' }} src={previewImageLink}></img>
				</div>
			</Modal>

			{
                openCategoryDialog && <AddCategoryFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddCategory}
                    openAddStatusModalStateData={openCategoryDialog}
                    newDataAddedEvent={newCategoryAddedEvent}
                />
            }
			{
                openManufactureDialog && <AddManufacturerFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddManufacturer}
                    openAddStatusModalStateData={openManufactureDialog}
                    newDataAddedEvent={newManufacturerAddedEvent}
                />
            }
			{
                openDynamicAttributeDialog && <AddAttributeFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddAssetAttribute}
                    openAddStatusModalStateData={openDynamicAttributeDialog}
                    newDataAddedEvent={newAttributeAddedEvent}
                />
            }
			<>
			<Drawer anchor='right' open={openState.open} className='cstm_drawer_holder' classes={{ paper: classes.drawerWidth }}>
				<div className='heading_holder'>
					<h4 className='heading'>{Boolean(editFromState) ? 'Update Model' : 'Add Model'}</h4>
					<IconButton style={{padding: '0px'}} onClick={handleAlertModal}>
						<Icon style={{fontSize: '24px', color: '#1d2939'}}>close</Icon>
					</IconButton>
				</div>
				<div className='progressbar_holder'>
					<div className='progress'>
						&nbsp;
					</div>
				</div>
				<div style={{ paddingTop: '30px' }} className='cstm_wizard_holder'>
					<Box sx={{ width: '100%' }} className='box_holder'>
						<div className='main_content_holder'>
							<div className='content_holder'>
								<div className="text-left">
									<div className='mb-10'>
										<Typography className='status_text'>
											Model Number
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name='model_no'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													variant='outlined'
													autoFocus
													fullWidth
													required
													style={{ background: 'white' }}
													error={!!errors.model_no}
													helperText={errors?.model_no?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/>
									</div>

									<div className='mb-10'>
										<Typography className='status_text'>
											Model Name
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name='model_name'
											control={control}
											render={({ field }) => (
												<TextField
													{...field}
													style={{ background: 'white' }}
													variant='outlined'
													autoFocus
													fullWidth
													required
													error={!!errors.model_name}
													helperText={errors?.model_name?.message}
													FormHelperTextProps={{
														style: {
															margin: 0,
															width: '100%',
															paddingTop: '2px'
														}
													}}
												/>
											)}
										/>
									</div>

									<div className='autocomplete_holder mb-10 relative'>
										<Typography className='status_text'>
											Manufacturer
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name="manufacturer"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field }, fieldState: { error } }) => {
												return (
													<>
														<Autocomplete
															{...field}
															style={{ width: '100%' }}
															options={manufacturers}
															disablePortal
															onChange={(event, value) => field.onChange(value)}
															PaperComponent={({ children }) => (
																<Paper className='autocomplete_holder'>{children}
																	<div className='p-16'>
																		{createManufacturerPermission && <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddManufacturer()}>
																			<i className='ti ti-plus' />
																			<span>Add Manufacturer</span>
																		</Button>}
																	</div>
																</Paper>
															)}
															getOptionLabel={option => option.manufacturer_name ?? ''}
															renderInput={(params) => {
																return (
																	<div ref={params.InputProps.ref}>
																		<TextField
																			{...params}
																			style={{ background: 'white' }}
																			required
																			error={!!error}
																			helperText={error?.message && 'Manufacturer is required!'}
																			FormHelperTextProps={{
																				style: {
																					margin: 0,
																					width: '100%',
																					paddingTop: '2px',
																					fontSize: '10px'
																				}
																			}}
																			variant="outlined"
																			fullWidth
																		/>
																	</div>
																)
															}}
														/>
													</>
												)
											}}
										/>
									</div>

									<div className='autocomplete_holder mb-10 relative'>
										<Typography className='status_text'>
											Category
											<span className='required_span'>*</span>
										</Typography>
										<Controller
											name="category"
											className="mb-16"
											control={control}
											render={({ field: { ref, ...field }, fieldState: { error } }) => (
												<>
													<Autocomplete
														{...field}
														style={{ width: '100%' }}
														options={categoryMenu}
														disablePortal
														onChange={(event, value) => field.onChange(value)}
														PaperComponent={({ children }) => (
															<Paper>{children}
																<div className='p-16'>
																	{createCategoryPermission && <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddCategory()}>
																		<i className='ti ti-plus' />
																		<span>Add Category</span>
																	</Button>}
																</div>
															</Paper>
														)}
														getOptionLabel={option => option.category_name ?? ''}
														renderInput={(params) => {
															return (
																<div ref={params.InputProps.ref}>
																	<TextField
																		{...params}
																		style={{ background: 'white' }}
																		required
																		// defaultValue={defaultValues.category}
																		error={!!error}
																		helperText={error?.message && 'Category is required!'}
																		FormHelperTextProps={{
																			style: {
																				margin: 0,
																				width: '100%',
																				paddingTop: '2px',
																				fontSize: '10px'
																			}
																		}}
																		variant="outlined"
																		fullWidth
																	/>
																</div>
															)
														}}
													/>
												</>
											)}
										/>
									</div>

									<Typography className='status_text'>
										Date
									</Typography>
									<Grid container spacing={1}>
										<Grid item md={8} lg={6}>
											<div className='mb-10'>
												<CustomDatePickerV2
													state={eolDate}
													setState={setEolDate}
													required={false}
													label='EOL Date'
												/>
											</div>
										</Grid>
										<Grid item md={8} lg={6}>
											<div className='mb-10'>
												<CustomDatePickerV2
													state={eoslDate}
													setState={setEoslDate}
													required={false}
													label='EOSL Date'
												/>
											</div>
										</Grid>
									</Grid>

									<div className='mb-10'>
										<Typography className='status_text'>
											Description
										</Typography>
										<Editor
											editorContent={description}
											onEditorContentChange={onEditorContentChange}
											moduleType='models'
										/>
									</div>

									<div className='mb-10'>
										<Typography variant='subtitle1' className='status_text'>Upload Image</Typography>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<input
												accept="image/*"
												id="model_image_upload"
												type="file"
												onChange={(event) => onChangeImageUpload(event)}
												hidden
											/>
											<label htmlFor='model_image_upload' className='light_grey_btn'>
												Browse
											</label>
											<div style={{ marginLeft: '10px' }}>
												{loading ?
													<>
														<div className={classes.loader}>
															<CircularProgress />
														</div>
													</> : <Typography>{imageName}</Typography>
												}
											</div>
										</div>
										<p className='status_text mt-10'>{"Accepts jpg, jpeg, png, gif. 2Mb max (rec. 700x430px)"}</p>
									</div>

									<div>
										{
											allSpan.map((span, index) => {
												return (
													<div style={{ display: 'flex', justifyContent: 'center', width: '100%', border: '1px solid #ddd', borderRadius: '5px', padding: '10px', marginTop: '15px', marginBottom: '10px' }} key={index}>
														<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
															<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', width: '100%' }}>

																<div style={{ marginRight: '10px', width: '100%' }} className='autocomplete_holder'>
																	<Typography className='status_text'>
																		Attribute
																	</Typography>
																	<Controller
																		name="allSpan"
																		className="mb-16"
																		control={control}
																		render={({ field: { ref, ...field }, fieldState: { error } }) => (
																			<>
																				<Autocomplete
																					{...field}
																					style={{ width: '100%' }}
																					options={dynamicAttributesMenu}
																					// disablePortal
																					anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
																					getOptionLabel={option => option.attribute_name ?? ''}
																					// onChange={(event, value) => field.onChange(value)}
																					value={span.attribute}
																					disabled={Boolean(span.has_related_value_in_assets)}
																					onChange={(event, value) => {
																						// onChange(event)
																						handleSpanInput(index, value, "attribute")
																					}}
																					getOptionDisabled={option => Boolean(allSpan.find((i) => i.attribute?.id == option.id))}
																					PaperComponent={({ children }) => (
																						<Paper className='autocomplete_holder'>{children}
																							{createModelAttributePermission && <div className='p-16'>
																								<Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddAssetAttribute(index)}>
																									<i className='ti ti-plus' />
																									<span>Add Attribute</span>
																								</Button>
																							</div>}
																						</Paper>
																					)}
																					renderInput={params => {
																						return (
																							<div >
																								<TextField
																									{...params}
																									style={{ background: 'white' }}
																									label=''
																									variant='outlined'
																									// error={span.attributeError === '' ? false : true}
																									// helperText={span.attributeError}
																									fullWidth
																									FormHelperTextProps={{
																										style: { marginLeft: 0 }
																									}}
																								/>
																							</div>
																						)
																					}}
																				/>
																			</>
																		)}
																	/>
																</div>

																<div style={{ width: '100%' }}>
																	<Typography className='status_text'>
																		Value
																	</Typography>
																	<Controller
																		name='allSpan'
																		control={control}
																		render={({ field: { onChange, ...field } }) => (
																			<TextField
																				{...field}
																				style={{ background: 'white' }}
																				className='value_input'
																				value={span.attribute_value}
																				onChange={event => {
																					handleSpanInput(index, event.target.value, "attribute_value")
																				}}
																				// error={span.attribute_value_error === '' ? false : true}
																				// helperText={span.attribute_value_error}
																				variant='outlined'
																				label=''
																				fullWidth
																				FormHelperTextProps={{
																					style: {
																						margin: 0,
																						backgroundColor: '#f4f4f4',
																						width: '100%',
																						paddingTop: '2px',
																						fontSize: '10px'
																					}
																				}}
																			/>
																		)}
																	/>
																</div>

																{
																	(index === 0 ?
																		<Tooltip title='Add More' placement='top'>
																			<IconButton onClick={() => addNewSpan()}>
																				<Icon>add_circle_outline</Icon>
																			</IconButton>
																		</Tooltip>
																		:
																		<Tooltip title='Remove Span' placement='top'>
																			<IconButton disabled={Boolean(span.has_related_value_in_assets)} onClick={() => deleteSpan(index)} >
																				<Icon>cancel_circle</Icon>
																			</IconButton>
																		</Tooltip>
																	)
																}
															</div>
														</div>
													</div>
												)
											})
										}
									</div>
								</div>
							</div>
							<div className='btn_holder'>
								<Button
									color="inherit"
									onClick={handleAlertModal}
									className='back_btn secondary_btn'
								>
									Cancel
								</Button>
								<Button onClick={handleSubmit(manageFormData)} className='next_btn'>
									Proceed
								</Button>
							</div>
						</div>
					</Box>
				</div>
			</Drawer>
			{openAlerModal &&
				<AlertModal  openAlerModal module="model" action={editFromState ? 'updating' : 'adding'} handleCloseAlertModal={handleCloseAlertModal} handleAlertModal={handleAlertModal}/>
			}
			</>
		</div>

	)
}

export default AddModelDialog
