import React, { useEffect, useState, forwardRef } from 'react'
import {
  Tabs, Tab, Box, Typography, Checkbox, InputLabel,
  MenuItem, FormControl, Select, FormGroup, FormControlLabel
} from '@mui/material'
import { Popover, Button, Icon, TextField } from '@material-ui/core';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import FuseLoading from '@fuse/core/FuseLoading';
import { exportToCSV } from "@fuse/utils/generateCsv";
import exportExcel from "@fuse/utils/excel";
import multiFormatFileManager from "@fuse/utils/multiFormatFileManager";
import axios from "@fuse/utils/axios";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import useToast from "@fuse/hooks/useToast";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import {formatDateForContract, titleCase, ucFirst } from 'helper/common';
import { getAllRoles, getAllUser } from 'app/api/user';
import { getAllAsset, getAllAssetAccessory, getAllAssetAccessoryCategory, getAllAssetCategory, getAllAssetCustomAttribute, getAllAssetManufacturer, getAllAssetModel, getAllAssetServiceProvider, getAllAssetStatus } from 'app/api/asset';
import { getAllContracPaymentTerm, getAllContracServiceLevelAgrement, getAllContract, getAllContractStatus, getAllContracType } from 'app/api/contract';
import { getAllDepartment, getAllLocation, getAllLocationType } from 'app/api/location';
import { getAllPriority, getAllServiceRequest, getAllStatus } from 'app/api/service-request';
import { SearchDropDownListPaginationComponent } from 'helper/SearchDropDownPaginationList';
import { openBulkArchiveModal, closeBulkArchiveModal } from '../../../app/main/assets/store/formViewDialogReducer';
import { setGroupViewTabValue } from "../../../app/main/reports/store/reportViewDialogReducer";
import { actions, assets_network_status, assets_source, contract_master, contract_status, source } from 'helper/constant';

const StyledMenu = styled((props) => (
  <Menu
      elevation={0}
      anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
      }}
      transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
      }}
      {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 170,
      // minHeight: 240,
      maxHeight:240,
      color:
          theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
          'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
          padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
          '& .MuiSvgIcon-root': {
              fontSize: 18,
              color: theme.palette.text.secondary,
              marginRight: theme.spacing(1.5),
          },
          '&:active': {
              backgroundColor: alpha(
                  theme.palette.primary.main,
                  theme.palette.action.selectedOpacity,
              ),
          },
      },
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/**
 * hideButton {Boolean} - Indicates whether the "Add Asset" button was clicked from the contract profile asset section.
 * Defaults to false. Depending on its value, certain UI elements such as Add, Archived, Service Tickets,
 * Export, and Activities Tab will be hidden.
 */
const AgGridFilterGroupView = forwardRef(({ tableRef, setRowGroupPanelShow, groupByArrFromList,
  tableName, onClick = () => { }, logs = [], displayTableName = '', allArchiveId = [], bulkAssetArchive = () => { },
  handleServiceTicketCreation = () => { }, customFilterData = null, clearRedirectionFilter = () => { }, forActivity = false,
  assetActivity = false,onClickAddButton = () => {},onClickAddReportButton = () => {}, addPermission = true,addServiceTicketPermission=false, hideButton=false,
  handleAssetImport=()=>{},disableImportBtn=false, loadingActivityLog=false, activityModuleName
}) => {

  const [gridRef, setGridRef] = useState(null);
  const [columns, setColumns] = useState([])
  const [tabValue, setTabValue] = useState(0);
  const [dateInputValOne, setDateInputValOne] = useState({
    date: null,
    error: false
  })
  const [dateInputValTwo, setDateInputValTwo] = useState({
    date: null,
    error: false
  })
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [filterColumnHeaderAndValueMap, setFilterColumnHeaderAndValueMap] = useState({});
  const [selectAll, setSelectAll] = useState(true);
  const dispatch = useDispatch();
  const toast = useToast(dispatch);
  const [ fileDownloadLoader, setFileDownloadLoader ] = useState(null);
  const groupByModule = ['asset', 'Contract', 'service_request', 'subscription', 'Manufacture', 'Model',
                        'Accessory', 'service_provider', 'subscription_provider', 'user', 'shipping', 'service_level_agreement',
                        'asset activity','asset_category', 'custom_attributes','asset_status', 'accessory_category','payment_term','contract_tag','Contract Types',
                        'location_type','department'
                      ]
  const dropdownFilterColumns = ['Company Name', 'User', 'User Name', 'Agent Name', 'Status', 'Contract Number','Contract Tag', 'Model',
    'Model Name', 'Model Number', 'Category', 'Manufacturer', 'Location', 'Type', 'Service Provider','Service Provider Name', 'Client Name', 'Serial Number',
    'Priority', 'Status', 'Location Type Name','Location Type', 'Department Name','Accessory Category', 'Mac Address', 'Source', 'Network Status', 'Category Name','Status Name',
    'Contract Status', 'Master','New/renew', 'Email','Role Name', 'Asset Category','Model Attribute', 'Asset Status', 'Payment Term Name','Manufacturer Name','Attribute Name',
     'Contract Tag Name', 'Contract Type Name','Service Level Agreement Name', 'Ticket Id', 'Location Name', 'Department', 'Action', 'User Role', 'Name','Accessory', 'Accessory Name']

  const dropDownFilterModule = ['asset','Model','Archived Asset', 'Manufacturer', 'asset_category', 'asset_status', 'custom_attributes', 'department',
  'user','Insight log','service_provider','service_request','Archived Service Tickets', 'activity_logs',
    'accessory_category', 'Accessory', 'Contract', 'Archived Contract','payment_term','contract_tag','Contract Types','service_level_agreement','shipping', 'location_type']

  const module_name = useSelector((state) => state.reportApp.filterParamsReducer.module_name)

  const hideActivity = {
    'Archived Contract': true,
    'Archived Asset': true,
    'Insight log': true,
    'Archived Service Tickets': true,
    'Custom Report': true,
    'Data Report': true,
    'user_audit_logs': true,
    'Asset_add_from_contract': true
  };
  function handleChangeTab(event, newValue) {
    onClick(newValue)
    setTabValue(newValue)
    dispatch(setGroupViewTabValue(newValue))
  }

  // function getHideColumn() {
  //   (tableRef?.current?.columnApi?.getColumnState()).map(item => {
  //     console.log("87658764853763876", item)
  //     if(item.hide){
  //       setRowSelectionCheckbox(prevArray => ({
  //         ...prevArray,
  //         [columnHeaderAndValueMap[item]]: false
  //       }))
  //     }
  //   })
  // }

  useEffect(() => {
    setGridRef(tableRef)
    setColumns((tableRef?.current?.columnApi?.getAllColumns())?.filter(item => item.colDef.headerName !== "Actions"))
    forActivity && setTabValue(1)
  }, [])
  /* =========================================== */

  /** ************Row Selection***************** */
  const [rowSelected, setRowSelected] = useState(false)
  /* =========================================== */

  /** ************Filter*********************** */
  const [filterAction, setFilterAction] = useState({
    "Contains": "contains",
    "Not Contains": "notContains",
    "Equal": "equals",
    "Not Equal": "notEqual",
    "Starts With": "startsWith",
    "Ends With": "endsWith",
    "Before": "lessThan",
    "After": "greaterThan",
  })

  const othersFilterAction = {
    "Contains": "contains",
    "Not Contains": "notContains",
    "Equal": "equals",
    "Not Equal": "notEqual",
    "Starts With": "startsWith",
    "Ends With": "endsWith"
  }

  const dateFilterActionForContract = {
    "Equal": "equals",
    "Not Equal": "notEqual",
    "Before": "lessThan",
    "After": "greaterThan",
  }

  const dateFilterAction = {
    "Equal": "equals",
    "Not Equal": "notEqual",
  }

  const filterTemp = {
    column: '',
    action: '',
    inputVal: '',
    operator: '',
    actionTwo: '',
    inputValTwo: '',
    error: {
      actionError: false,
      actionTwoError: false
    }
  }
  // const filterAction = {
  //   "Contains": "contains",
  //   "Not Contains": "notContains",
  //   "Equal": "equals",
  //   "Not Equal": "notEqual",
  //   "Starts With": "startsWith",
  //   "Ends With": "endsWith"
  // }

  const columnHeaderAndValueMap = (() => {
    return columns?.reduce((res, curr) => {
      res[curr.colDef.headerName] = curr.colDef.field;
      return res
    }, {})
  })()

  const [openCustomFilterUi, setOpenCustomFilterUi] = useState(false)
  const [allFilter, setAllFilter] = useState([{ ...filterTemp }])
  const [initialFilterApplied, setInitialFilterApplied] = useState(false)

  function openFilter() {
    setOpenCustomFilterUi(true)
  }

  const handleColumnSelect = (event, index) => {
    // if(event.target.value?.includes('Date')){
    //   setFilterAction({
    //     "Equal": "equals",
    //     "Not Equal": "notEqual",
    //   })
    // }else{
    //   setFilterAction({
    //     "Contains": "contains",
    //     "Not Contains": "notContains",
    //     "Equal": "equals",
    //     "Not Equal": "notEqual",
    //     "Starts With": "startsWith",
    //     "Ends With": "endsWith"
    //   })
    // }
    setSelectedColumnName(event.target.value)
    setAllFilter(prevFilters => {
      prevFilters[index].column = event.target.value;
      prevFilters[index].inputVal = '';
      prevFilters[index].inputValTwo = '';
      return [...prevFilters];
    });
  }

  const getKeyName = (obj, searchValue) => {

    if(!searchValue) return ''

    for (const [key, value] of Object.entries(obj)) {
      if (value === searchValue) {
          return key 
      }
    }
  }

  const flattenFilter = (filterObj) => {
    const filterArr = []
    for (const [key, value] of Object.entries(filterObj)){
      if(filterObj[key]?.condition1 || filterObj?.condition2){
        if(key?.includes('date')){
          filterArr.push({
            ...filterTemp,
            column: getKeyName(columnHeaderAndValueMap, key),
            action: getKeyName(filterAction, filterObj[key]?.condition1?.type),
            inputVal: {date:new Date((tableName === 'Contract'  && (getKeyName(columnHeaderAndValueMap, key) === 'End Date' ||  getKeyName(columnHeaderAndValueMap, key) === 'Billing Cycle Date')) ? filterObj[key]?.condition1.dateFrom : filterObj[key]?.condition1.filter)?.toISOString(), error: false},
            operator: filterObj[key]?.operator,
            actionTwo: getKeyName(filterAction, filterObj[key]?.condition2?.type),
            inputValTwo: {date: new Date((tableName === 'Contract'  && (getKeyName(columnHeaderAndValueMap, key) === 'End Date' ||  getKeyName(columnHeaderAndValueMap, key) === 'Billing Cycle Date')) ? filterObj[key]?.condition2.dateFrom : filterObj[key]?.condition2?.filter)?.toISOString(), error: false}
          })
        }else{
          filterArr.push({
            ...filterTemp,
            column: getKeyName(columnHeaderAndValueMap, key),
            action: getKeyName(filterAction, filterObj[key]?.condition1?.type),
            inputVal: filterObj[key]?.condition1?.filter,
            operator: filterObj[key]?.operator,
            actionTwo: getKeyName(filterAction, filterObj[key]?.condition2?.type),
            inputValTwo: filterObj[key]?.condition2?.filter
          })
        }
      }else if(key?.includes('date')){
          // setDateInputValOne({date: new Date().toISOString(), error: false})
          filterArr.push({
            ...filterTemp,
            column: getKeyName(columnHeaderAndValueMap, key),
            action: getKeyName(filterAction, filterObj[key]?.type),
            inputVal: {date:new Date((tableName === 'Contract'  && (getKeyName(columnHeaderAndValueMap, key) === 'End Date' ||  getKeyName(columnHeaderAndValueMap, key) === 'Billing Cycle Date')) ? filterObj[key]?.dateFrom : filterObj[key]?.filter)?.toISOString(), error: false},
            operator: filterObj[key]?.operator,
            actionTwo:'',
            inputValTwo: ''
          })
        }else{
          filterArr.push({
            ...filterTemp,
            column: getKeyName(columnHeaderAndValueMap, key),
            action: getKeyName(filterAction, filterObj[key]?.type),
            inputVal: filterObj[key]?.filter,
            operator: filterObj[key]?.operator,
            actionTwo:'',
            inputValTwo: '',
            id:filterObj[key]?.id
          })
        }

      setAllFilter(filterArr)
      
      // console.log(value, key)
    }
  }

  useEffect(()=>{
    if(hideButton) return
    if(customFilterData) return
    requestIdleCallback(()=>{
      if(!tableRef?.current) return
      const appliedFilter = tableRef?.current?.api?.getFilterModel()
      if(Object.keys(appliedFilter || {})?.length != 0 && !initialFilterApplied){
        const filter = tableRef?.current.filterModelArr
        setOpenCustomFilterUi(true)
        flattenFilter(filter)
        setInitialFilterApplied(true)
      }
    })
  }, [tableRef?.current?.api?.getFilterModel()])

  const handleFilterActionSelect = (event, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].action = event.target.value;
      prevFilters[index].error.actionError = false;
      return [...prevFilters];
    });
  }

  const handleFilterInput = (event, index, custom=false) => {
    if((allFilter[index].column?.includes('Date') || allFilter[index].column?.includes('Time'))){
      setAllFilter(prevFilters => {
        prevFilters[index].inputVal = event
        return [...prevFilters];
      });
    } else if((dropdownFilterColumns?.includes(allFilter[index].column?.trimEnd()) && dropDownFilterModule?.includes(tableName)) || (customFilterData && ['network_status', 'contract_status']?.includes(Object.keys(customFilterData)[0]))){

      setAllFilter(prevFilters => {
        prevFilters[index].inputVal = (customFilterData && ['network_status', 'contract_status']?.includes(Object.keys(customFilterData)[0]) && custom) ? ucFirst(event.target.value) : event
        return [...prevFilters];
      });
    } else {
      setAllFilter(prevFilters => {
        prevFilters[index].inputVal = event?.target?.value ?? event;
        return [...prevFilters];
      });
    }
  }

  const handleAndOrBtn = (event, operator, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].operator = operator.toUpperCase();
      return [...prevFilters];
    });
  }

  const handleFilterActionSelectTwo = (event, index) => {
    setAllFilter(prevFilters => {
      prevFilters[index].actionTwo = event.target.value;
      prevFilters[index].error.actionTwoError = false;
      return [...prevFilters];
    });
  }

  const handleFilterInputTwo = (event, index) => {
    if((allFilter[index]?.column?.includes('Date') || allFilter[index]?.column?.includes('Time'))){
      setAllFilter(prevFilters => {
        prevFilters[index].inputValTwo = event;
        return [...prevFilters];
      });
    } else if((dropdownFilterColumns?.includes(allFilter[index].column?.trimEnd()) && dropDownFilterModule?.includes(tableName))){
      setAllFilter(prevFilters => {
        prevFilters[index].inputValTwo = event
        return [...prevFilters];
      });
    } else {
      setAllFilter(prevFilters => {
        prevFilters[index].inputValTwo = event?.target?.value ?? event;
        return [...prevFilters];
      });
    }
  }

  const addAnotherFilter = () => {
    setFilterAction({
      "Contains": "contains",
      "Not Contains": "notContains",
      "Equal": "equals",
      "Not Equal": "notEqual",
      "Starts With": "startsWith",
      "Ends With": "endsWith"
    })
    setAllFilter(prevArray => [...prevArray, { ...filterTemp }])
  }

  function formatDate(inputDate) {
    const dateObj = new Date(inputDate);

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    const month = monthNames[dateObj.getMonth()];
    const day = (`0${  dateObj.getDate()}`).slice(-2);
    const year = dateObj.getFullYear();

    const outputDate = `${month  } ${  day  }, ${  year}`;

    return outputDate;
  }

  function formatDateForAsset(date){
    if(date instanceof Date){
      return date
    }
    return (new Date(date.date)).toISOString()
  }

  function convertToISODate(inputDate) {
    const dateObj = new Date(inputDate);

    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    const month = dateObj.getMonth();
    const day = (`0${  dateObj.getDate()}`).slice(-2);
    const year = dateObj.getFullYear();

    const outputDate = `${month  } ${  day  }, ${  year}`;
    return `${year}-${month}-${day}`;
  }

  function handleApplyFilter(customFilterColumn) {
    requestIdleCallback(()=>{if (gridRef?.current) {
      // Get the grid API
      const gridApi = gridRef.current.api;
      // for both side filled
      const filterModelArr = {}
      allFilter.forEach((item, index) => {
        if (item.inputVal && item.inputValTwo) {
          if (item.inputVal && item.inputValTwo && !item.operator) {
            toast.error('Please select and/or')
            return
          }

          // if (item.inputVal && item.inputValTwo && (!filterAction[item.action] || !filterAction[item.actionTwo])) {
          //   toast.error('Please select operator')
          //   return
          // }
          if(item.action === ''){
            setAllFilter(prevFilters => {
              prevFilters[index].error.actionError = true;
              return [...prevFilters];
            });
            toast.error("Please select operator")
          }
          if(item.actionTwo === ''){
            setAllFilter(prevFilters => {
              prevFilters[index].error.actionTwoError = true;
              return [...prevFilters];
            });
            toast.error("Please select operator")
          }
          if((item.column?.includes("Date") || item.column?.includes("Time"))){
            filterModelArr[columnHeaderAndValueMap[item.column] ?? customFilterColumn] =  {
              filterType: "date",
              operator: item.operator,
              condition1: {
                filterType: "date",
                type: filterAction[item.action],
                ...(tableName === 'Contract' && (item.column === 'End Date' || item.column === 'Billing Cycle Date') && {
                  dateFrom: formatDateForContract(item.inputVal?.date ?? item.inputVal),
                  dateTo:  formatDateForContract(item.inputVal?.date ?? item.inputVal)
                }
              ),
              ...((tableName !== 'Contract' || (tableName === 'Contract' && (item.column !== 'End Date' || item.column !== 'Billing Cycle Date')) ) && {
                filter:  ((tableName !='asset' && tableName != 'user_audit_logs') ? formatDate(item.inputVal?.date ?? item.inputVal) : item.inputVal),
                }
              )
              },
              condition2: {
                filterType: "date",
                type: filterAction[item.actionTwo],
                ...(tableName === 'Contract' && (item.column === 'End Date' || item.column === 'Billing Cycle Date') && {
                  dateFrom:  formatDateForContract(item.inputValTwo?.date ?? item.inputValTwo),
                  dateTo: formatDateForContract(item.inputValTwo?.date ?? item.inputValTwo),
                }),
                ...((tableName !== 'Contract' || (tableName === 'Contract' && (item.column !== 'End Date' || item.column !== 'Billing Cycle Date')) ) && {
                  filter: ((tableName !='asset' && tableName != 'user_audit_logs') ? formatDateForContract(item.inputValTwo?.date ?? item.inputValTwo) : item.inputValTwo),
                })
              }
            }
          } else {
            filterModelArr[columnHeaderAndValueMap[item.column] ?? customFilterColumn] = {
              filterType: "text",
              operator: item.operator,
              condition1: {
                filterType: "text",
                type: filterAction[item.action],
                filter: typeof  item.inputVal === 'object' ?   item.inputVal.label : item.inputVal,
                id: item.inputVal.id
              },
              condition2: {
                filterType: "text",
                type: filterAction[item.actionTwo],
                filter: typeof  item.inputValTwo === 'object' ?   item.inputValTwo.label : item.inputValTwo,
                id: item.inputValTwo.id
              }
            }
          }
            
        }else if(item.inputVal){
          if(item.action === ''){
            setAllFilter(prevFilters => {
              prevFilters[index].error.actionError = true;
              return [...prevFilters];
            });
            toast.error("Please select operator")
            return
          }
          if((item.column?.includes("Date") || item.column?.includes("Time"))){
            if(filterAction[item.action] != 'equals' && filterAction[item.action] !='notEqual' && filterAction[item.action] !='lessThan'  && filterAction[item.action] !='greaterThan'){
              toast.error(`Date filter can't work with ${item.action}`)
              return
            }

            filterModelArr[columnHeaderAndValueMap[item.column] ?? customFilterColumn] = {
              filterType: "date",
              type: filterAction[item.action],
              ...(tableName === 'Contract' && (item.column === 'End Date' || item.column === 'Billing Cycle Date') && {
                dateFrom: formatDateForContract(item.inputVal?.date ?? item.inputVal),
                dateTo:  formatDateForContract(item.inputVal?.date ?? item.inputVal)
              }),
              ...((tableName !== 'Contract' || (tableName === 'Contract' && (item.column !== 'End Date' || item.column !== 'Billing Cycle Date')) ) && {
                filter: ((tableName !='asset' && tableName != 'user_audit_logs') ? formatDate(item.inputVal?.date ?? item.inputVal) : item.inputVal),
              })
            }
          } else {
            filterModelArr[columnHeaderAndValueMap[item.column] ?? customFilterColumn] = {
              filterType: "text",
              type: filterAction[item.action],
              filter: typeof  item.inputVal === 'object' ?   item.inputVal.label : item.inputVal,
              id: item.inputVal.id
            }
          }
        }
      })

      tableRef.current = {
        ...tableRef.current,
        filterModelArr
      }

      gridApi.setFilterModel(filterModelArr);

    }})
    
  }

  const clearFilter = () => {
    const gridApi = gridRef?.current?.api;
    if(gridApi){
    gridApi.setFilterModel({});
    gridRef.current = {...gridRef.current,filterModelArr:{}}
    setOpenCustomFilterUi(false)
    setAllFilter([{ ...filterTemp }])
    clearRedirectionFilter()
    }
  }

  useEffect(() => {
    let key
    let value
    let condition

    if (customFilterData === 'all') {
      clearFilter()
    }else if (customFilterData) {
      if((customFilterData?.type === 'End Date' || customFilterData?.type === 'Billing Cycle Date') && tableName === 'Contract'){
        key ={
          target: {
            value: customFilterData?.type
          }
        }
      const date = customFilterData.end_date1
      const [day, month, year] = date.split('-').map(Number);
      value= {
        date: new Date(year, month - 1, day),
        error: false
      } 
      
      condition={
        target: {
          value: customFilterData.action1
        }
      }
      const date2 = customFilterData.end_date2
      const [day2, month2, year2] = date2.split('-').map(Number);
      const value2 = {
        date: new Date(year2, month2 - 1, day2),
        error: false
      }
      handleColumnSelect({
        ...key
       }, 0 )
      handleFilterInputTwo(value2,0)
      handleFilterActionSelectTwo({
        target: {
          value: customFilterData.action2
        }
      },0)
      handleAndOrBtn(undefined, "or", 0)
      }else{
        key ={
          target: {
            value: titleCase(Object.keys(customFilterData)[0])
          }
        }
        
        if(Object.values(customFilterData)[1]){
          value={
            label: Object.values(customFilterData)[0],
            id: Object.values(customFilterData)[1],
          }
        }else{
          value={
            target: {
              value: Object.values(customFilterData)[0]
            }
          }
        }
        condition={
          target: {
            value: "Equal"
          }
        }

        handleColumnSelect({
          ...key
         }, 0 )
      }

      handleFilterInput(value, 0, true)
      handleFilterActionSelect({
        ...condition
      }, 0)
      handleApplyFilter(Object.keys(customFilterData)[0])
      setOpenCustomFilterUi(true)
    }
  }, [customFilterData, gridRef])
  /* =========================================== */


  /** ************GroupBy*********************** */
  const [groupByEl, setGroupByEl] = useState(null);
  const openGroupByPopUp = Boolean(groupByEl);
  const [rowSelectionCheckboxGroupBy, setRowSelectionCheckboxGroupBy] = useState({})
  const [groupByArr, setGroupByArr] = useState([])
  const [groupByArrAsset, setGroupByArrAsset] = useState([])

  const setDefaultRowSelectionCheckboxGroupBy = () => {
    return (() => {
      const initialState = {};
      Object.keys(columnHeaderAndValueMap || {}).map(item => {
        initialState[item] = false;
      });
      return initialState;
    })
  }
  useEffect(() => {
    setRowSelectionCheckboxGroupBy(setDefaultRowSelectionCheckboxGroupBy());
  }, [columns])

  useEffect(() => {
    if (!(gridRef?.current?.columnApi?.getRowGroupColumns().length)) {
      setGroupByArr([])
      setGroupByArrAsset([])
      setRowSelectionCheckboxGroupBy(setDefaultRowSelectionCheckboxGroupBy());
    }
  }, [gridRef, groupByEl])

  useEffect(() => {
    if (!groupByArrFromList.length) {
      setRowSelectionCheckboxGroupBy(setDefaultRowSelectionCheckboxGroupBy());
      setRowGroupPanelShow(false)
      setGroupByArr([])
      setGroupByArrAsset([])
    } else {
      const newGroup = []
      Object.keys(rowSelectionCheckboxGroupBy).map((item) => {
        if (groupByArrFromList.find(ele => ele.colDef?.headerName == item)) {
          newGroup.push(item)
          setRowSelectionCheckboxGroupBy(prevArray => ({
            ...prevArray,
            [item]: true
          }))
        } else {
          setRowSelectionCheckboxGroupBy(prevArray => ({
            ...prevArray,
            [item]: false
          }))
        }
      })
      setRowGroupPanelShow('always')
      setGroupByArr(newGroup)
      setGroupByArrAsset(newGroup)
      // gridRef?.current?.columnApi?.setRowGroupColumns(col_ids);
    }
  }, [groupByArrFromList])

  // useEffect(()=>{
  //   handleGroupByCreate()
  // },[groupByArr])

  const handleGroupByCancel = () => {
    setRowGroupPanelShow('never')
    setGroupByArr([])
    setGroupByArrAsset([])
    const { columnApi } = gridRef.current;
    columnApi.setRowGroupColumns([]);
    setRowSelectionCheckboxGroupBy(setDefaultRowSelectionCheckboxGroupBy());
    setGroupByEl(null)
  }

  const handleGroupByCreate = () => {
    setRowGroupPanelShow('always')
    const col_ids = []
    for (const key of groupByArr) {
      if (columnHeaderAndValueMap[key]) {
        col_ids.push(columnHeaderAndValueMap[key])
      }
    }

    gridRef?.current?.columnApi?.setRowGroupColumns(col_ids);
    setGroupByArrAsset(col_ids)
    setGroupByEl(null)
  }
  const dropdownClick = (event) => {
    setGroupByEl(groupByEl ? null : event.currentTarget)
  }

  const handleFilterClose = () => {
    setGroupByEl(null)
  }
  /* =========================================== */


  /** ************Hide Column*********************** */
  const [rowSelectionCheckbox, setRowSelectionCheckbox] = useState({})
  const [filterElHideColumn, setFilterElHideColumn] = useState(null);
  const [moreActions, setMoreActions] = useState(null);
  const [totalShowColumn, setTotalShowColumn] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [ openAssets, setOpenAssets] = useState(false)
  const [ openContract, setOpenContract ] = useState(false);
  const [ columnNameSearchValue, setColumnNameSearchValue ] = useState(null);

  useEffect(() => {
    setDefaultHideColumnCheckBox()
  }, [columns])

  function isHideColumnApply() {
    const columnState = gridRef?.current?.columnApi.getColumnState()
    const colPresent = columnState?.find(column => column.hide == true)
    if (colPresent) return true
    return false
  }

  useEffect(() => {
    !isHideColumnApply() && setDefaultHideColumnCheckBox()
  }, [gridRef, filterElHideColumn])


  const setDefaultHideColumnCheckBox = () => {
    setRowSelectionCheckbox(() => {
      return columns?.reduce((res, curr) => {
        res[curr.colDef.headerName] = true
        return res
      }, {})
    })
  }


  const checkAllColumnSelectStatus = () => {
    if(((Object.values(rowSelectionCheckbox)).filter((item=>item===false))).length === 0) setSelectAll(true)
    else setSelectAll(false)
  }

  const removeAllSelectedCheckbox = () => {
    setRowSelectionCheckbox(() => {
      return columns?.reduce((res, curr) => {
        res[curr.colDef.headerName] = false
        return res
      }, {})
    })
  }

  useEffect(()=>{
    if(!filterElHideColumn) setColumnNameSearchValue(null)
  },[filterElHideColumn])

  const dropdownClickHideColumn = (event) => {
    setFilterElHideColumn(groupByEl ? null : event.currentTarget)
  }
  const handleFilterCloseHideColumn = () => {
    setFilterElHideColumn(null)
  }
  const openFilterHideColumn = Boolean(filterElHideColumn);
  const id_two = 'simple-popper_two'

  const handleHideColumn = () => {
    for (const key in rowSelectionCheckbox) {
      if (!rowSelectionCheckbox[key]) {
        gridRef.current.columnApi.setColumnVisible(columnHeaderAndValueMap[key], false);

      } else {
        gridRef.current.columnApi.setColumnVisible(columnHeaderAndValueMap[key], true);
      }
    }
    setFilterElHideColumn(null)
  }


  const dropdownClickMoreAction = (event) => {
    setMoreActions(event.currentTarget)
  }
  const handleFilterCloseMoreActions = () => {
    setMoreActions(null)
  }
  const openMoreAction = Boolean(moreActions);
  const id_more_action = 'simple-popper_more'

  useEffect(() => {
    setTotalShowColumn((Object.values(rowSelectionCheckbox).filter(val => val == true)).length)
    checkAllColumnSelectStatus()
  }, [rowSelectionCheckbox])

  const handleHideColumnCloseButton = () => {
    for (const key in rowSelectionCheckbox) {
      gridRef.current.columnApi.setColumnVisible(columnHeaderAndValueMap[key], true);
    }
    setDefaultHideColumnCheckBox()
    setFilterElHideColumn(null)
  }
  /* =========================================== */


  /** ************ServiceTicket***************** */
  // function handleServiceTicketCreation() {
  //   console.log('handleServiceTicketCreation');
  // }
  /* =========================================== */

  /** ************Exports***************** */
  const [exportType, setExportType] = useState('')
  const handleChangeExport = (event) => {
    setExportType(event.target.value);
  };

  useEffect(async () => {
    if (exportType) {
      setFileDownloadLoader(true)
      await multiFormatFileManager(exportType,  tableName === 'activity_logs' ? `${activityModuleName.toLowerCase()}_activity_logs`  : tableName , gridRef)
      setFileDownloadLoader(false)
      setExportType('')
    }

  }, [exportType])


  /* =========================================== */
  const handleFilterDelete = (index) => {
    const newFilterArr = [...allFilter]
    newFilterArr.pop()
    setAllFilter(newFilterArr)
  }
  /* =============================================================*/

  /** *******************************For Asset Service Ticket************** */

  useEffect(() => {
    if (allArchiveId.length) setRowSelected(true)
    else setRowSelected(false)
  }, [allArchiveId])

  function handleOpen() {
    dispatch(openBulkArchiveModal())
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};

function filterKeysStartingWith(value) {
  const filteredObj = {};
  Object.keys(columnHeaderAndValueMap).forEach(key => {
      if (key.toLowerCase()?.includes(value.toLowerCase())) {
          // console.log("1234",columnHeaderAndValueMap[key], value)
          filteredObj[key] = columnHeaderAndValueMap[key];
      }
  });
  return filteredObj;
}  

const columnNameSearch = (e) =>{
  const val = filterKeysStartingWith(e.target.value)
  setFilterColumnHeaderAndValueMap(val)
  setColumnNameSearchValue(e.target.value)
}

useEffect(() =>{
  // const columns = gridRef?.current?.columnApi?.getColumnState()
  // const columns = tableRef?.current?.columnApi.getAllColumns()

  // const columns = tableRef?.current?.columnApi.getAllColumns()?.filter(item => item.colDef.headerName !== "Actions")
  requestIdleCallback(()=>{if (gridRef?.current) {
    for (const item of columns) {
      if (!item?.visible) {
        setRowSelectionCheckbox(prevArray => ({
          ...prevArray,
          [item.colDef.headerName]: false
        }))
      } 
    }
  }})
},[gridRef?.current])
// console.log('tablename', tableName)
  return (
    <div style={{padding: '10px 0'}}>
      {fileDownloadLoader && <FuseLoading />}
      <Box sx={{ width: '100%' }}>
        <div className='flex flex-wrap justify-between items-center mb-10'>
          <TableHeaderTile tableName={tableName} displayTableName={displayTableName}/>
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ backgroundColor: '#e9ebed', padding: '8px', borderRadius: '8px', marginBottom: '10px' }}>
            <Tabs value={tableName == 'Archived Asset' ? null : tabValue} onChange={handleChangeTab} aria-label="basic tabs example" className='category_tabs'>
              <Tab className="tabs_exampleText" label={tableName == 'asset' ? 'All Assets' : displayTableName} />
              {(!hideActivity[hideButton ? 'Asset_add_from_contract' : tableName]) && <Tab className="tabs_exampleText" label="Activities" />}
            </Tabs>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {displayTableName !== 'Data Report' && <>
            {hideButton ? <></> : ['Contract','asset','shipping','Manufacturer','Model','user','Accessory', 'asset_category']?.includes(tableName) && addPermission ? 
              tabValue === 0 && (
                <div className='primary_maroon_btn icon_primary_btn filter_icon'>
                  <i className='ti ti-plus icon' onClick={handleClick} />
                  <StyledMenu
                        // className="quick_dropdown"
                        id="demo-customized-menu quick_dropdown"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() =>
                          {
                            handleClose()
                            onClickAddButton()
                          }}
                          disableRipple 
                          className='assets_items'
                          >
                            {/* <EditIcon /> */}
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_2228_901)">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9 12H15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 9V15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2228_901">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            <span style={{ marginLeft: '4px', fontSize: '12px', fontWeight: '500', lineHeight: '16px', color: '#1D2939', fontFamily: '"Inter", sans-serif' }}>Add {ucFirst(tableName ==='shipping' ? 'location': tableName?.replace(/_/g, ' '))}</span>
                        </MenuItem>
                        <MenuItem onClick={() => 
                            {
                              handleClose()
                              onClickAddReportButton()
                            }}s
                            disableRipple 
                            className='assets_items'
                          >
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_2228_901)">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9 12H15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 9V15" stroke="#562658" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2228_901">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            <span style={{ marginLeft: '4px', fontSize: '12px', fontWeight: '500', lineHeight: '16px', color: '#1D2939', fontFamily: '"Inter", sans-serif' }}>Add Report</span>
                        </MenuItem>
                  </StyledMenu>
                </div> 
              )
              : (!['Archived Contract','Archived Asset', 'Archived Service Tickets','user_audit_logs', 'Insight log']?.includes(tableName) && addPermission)  &&
              
                tabValue === 0  && (<div className='primary_maroon_btn icon_primary_btn filter_icon' onClick={onClickAddButton}>
                <i className='ti ti-plus icon'/>
                </div>)
            }
            {(rowSelected && !hideButton && addPermission) &&
              <Button onClick={handleOpen} className='create_button mr-12'><i className='ti ti-archive' />Archive</Button>
            }
            {hideButton &&
              <Button disabled={!disableImportBtn } onClick={handleAssetImport} className='create_button mr-12'>{/* <i className='ti ti-ticket' />*/}Import</Button>
            }
            {(rowSelected && !hideButton && addServiceTicketPermission) &&
              <Button onClick={handleServiceTicketCreation} className='create_button mr-12'><i className='ti ti-ticket' />Service Ticket</Button>
            }
            {/* Filter and Archive*/}
            {!rowSelected &&
              <div className="filter_icon" onClick={openFilter}>
                <i style={{ fontSize: '18px' }} className='ti ti-filter' />
              </div>
            }
            <div onClick={dropdownClickHideColumn} className='filter_icon'>
              <i className='ti ti-layout-columns' />
            </div>
            <Popover
              id={id_two}
              open={openFilterHideColumn}
              anchorEl={filterElHideColumn}
              onClose={handleFilterCloseHideColumn}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              style={{ marginTop: "1px" }}
            >
              <div className='dropdown_listGroup'>
                <div>
                  <Typography className='group_columnText'>Show Columns({totalShowColumn})</Typography>
                </div>
                <div style={{ height: '190px', overflowY: 'scroll' }} className='thinscrollbar_holder'>
                  <div style={{ display: 'flex', flexWrap:'wrap', alignItems: 'center' }}>
                    <div style={{width:'100%', padding:'0 12px 12px'}}>
                      <div className='cstm_input_container'>
                        <input
                          variant="outlined"
                          placeholder="Search column..."
                          fullWidth
                          inputProps={{ readOnly: false }}
                          required
                          onChange={columnNameSearch}
                          value={columnNameSearchValue}
                        />
                      </div>
                      <div style={{ display:'flex', alignItems: 'center', paddingTop: '8px' }}>
                        <Checkbox className='checkbox_padding'
                          sx={{
                            color: '#eaecf0',
                            '&.Mui-checked': {
                              color: '#f66d69',
                            },
                          }}
                          checked={selectAll} 
                          onChange={(e) => {
                            setSelectAll(e.target.checked)
                            if(e.target.checked) setDefaultHideColumnCheckBox()
                            else removeAllSelectedCheckbox()  

                          }} 
                          />

                        <Typography style={{display: 'inline-flex', alignItems: 'center'}} className='list_text'>Select All</Typography>
                      </div>
                    </div>
                  </div>

                  {( columnNameSearchValue ? Object.keys(filterColumnHeaderAndValueMap): Object.keys(columnHeaderAndValueMap))?.map((item, index) => {
                    return (
                      <div className='checkbox_list'>
                        <span style={{ marginRight: '5px' }}>
                          {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_2657_16034)">
                              <path d="M6.00016 3.99984C6.36835 3.99984 6.66683 3.70136 6.66683 3.33317C6.66683 2.96498 6.36835 2.6665 6.00016 2.6665C5.63197 2.6665 5.3335 2.96498 5.3335 3.33317C5.3335 3.70136 5.63197 3.99984 6.00016 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.00016 8.66683C6.36835 8.66683 6.66683 8.36835 6.66683 8.00016C6.66683 7.63197 6.36835 7.3335 6.00016 7.3335C5.63197 7.3335 5.3335 7.63197 5.3335 8.00016C5.3335 8.36835 5.63197 8.66683 6.00016 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.00016 13.3333C6.36835 13.3333 6.66683 13.0349 6.66683 12.6667C6.66683 12.2985 6.36835 12 6.00016 12C5.63197 12 5.3335 12.2985 5.3335 12.6667C5.3335 13.0349 5.63197 13.3333 6.00016 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.0002 3.99984C10.3684 3.99984 10.6668 3.70136 10.6668 3.33317C10.6668 2.96498 10.3684 2.6665 10.0002 2.6665C9.63197 2.6665 9.3335 2.96498 9.3335 3.33317C9.3335 3.70136 9.63197 3.99984 10.0002 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.0002 8.66683C10.3684 8.66683 10.6668 8.36835 10.6668 8.00016C10.6668 7.63197 10.3684 7.3335 10.0002 7.3335C9.63197 7.3335 9.3335 7.63197 9.3335 8.00016C9.3335 8.36835 9.63197 8.66683 10.0002 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M10.0002 13.3333C10.3684 13.3333 10.6668 13.0349 10.6668 12.6667C10.6668 12.2985 10.3684 12 10.0002 12C9.63197 12 9.3335 12.2985 9.3335 12.6667C9.3335 13.0349 9.63197 13.3333 10.0002 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_2657_16034">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg> */}
                        </span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox className='checkbox_padding'
                            sx={{
                              color: '#eaecf0',
                              '&.Mui-checked': {
                                color: '#f66d69',
                              },
                            }}
                            checked={rowSelectionCheckbox[item]} onChange={() => {
                              setRowSelectionCheckbox(prevArray => ({
                                ...prevArray,
                                [item]: !rowSelectionCheckbox[item]
                              }))

                            }} />

                          <Typography className='list_text'>{item}</Typography>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
                <Box sx={{ display: 'flex', marginTop: '6px', padding: '4px 12px' }}>
                  <Button onClick={handleHideColumnCloseButton} className='cancel_button w-full'>Reset</Button>
                  <Button className='create_button w-full' onClick={handleHideColumn}>Create</Button>
                </Box>
              </div>
            </Popover>

            {rowSelected && <>
              <div className="filter_icon" onClick={dropdownClickMoreAction}>
                <i className='ti ti-dots-vertical' />
              </div>

              <Popover
                id={id_more_action}
                open={openMoreAction}
                anchorEl={moreActions}
                onClose={handleFilterCloseMoreActions}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className='cstm_dropwdown_holder'>
                  <div className='each_holder' onClick={openFilter}>
                    <i className='ti ti-eye' />
                    <span>Filter</span>
                  </div>
                  <div className='each_holder' onClick={dropdownClick}>
                    <i className='ti ti-archive' />
                    <span>Group By</span>
                    <i className='ti ti-chevron-down ml-2' />
                  </div>
                </div>
              </Popover>
            </>}

            {/* GroupBy and Create Service Tickets */}
            <Box sx={{ display: 'flex', alignItems: "center", marginRight: '5px' }}>
              {!rowSelected && <>
                <Button className='group_field' onClick={dropdownClick}>
                  Group By <Icon>
                    expand_more
                  </Icon>
                </Button>
              </>}
              <Popover
                id='simple-popper'
                open={openGroupByPopUp}
                anchorEl={groupByEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                style={{ marginTop: "1px" }}
              >
                <div className='dropdown_listGroup'>
                  <div>
                    <Typography className='group_columnText'>Group Column</Typography>
                  </div>
                  <div style={{ height: '202px', overflowY: 'scroll' }} className='thinscrollbar_holder'>
                    {Object.keys(columnHeaderAndValueMap || {}).map((item, index) => {
                      return (
                        <div className='checkbox_list'>
                          <span style={{ display: 'inline-block', marginRight: '5px', paddingRight: '5px' }}>
                            {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_2657_16034)">
                                <path d="M6.00016 3.99984C6.36835 3.99984 6.66683 3.70136 6.66683 3.33317C6.66683 2.96498 6.36835 2.6665 6.00016 2.6665C5.63197 2.6665 5.3335 2.96498 5.3335 3.33317C5.3335 3.70136 5.63197 3.99984 6.00016 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.00016 8.66683C6.36835 8.66683 6.66683 8.36835 6.66683 8.00016C6.66683 7.63197 6.36835 7.3335 6.00016 7.3335C5.63197 7.3335 5.3335 7.63197 5.3335 8.00016C5.3335 8.36835 5.63197 8.66683 6.00016 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.00016 13.3333C6.36835 13.3333 6.66683 13.0349 6.66683 12.6667C6.66683 12.2985 6.36835 12 6.00016 12C5.63197 12 5.3335 12.2985 5.3335 12.6667C5.3335 13.0349 5.63197 13.3333 6.00016 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.0002 3.99984C10.3684 3.99984 10.6668 3.70136 10.6668 3.33317C10.6668 2.96498 10.3684 2.6665 10.0002 2.6665C9.63197 2.6665 9.3335 2.96498 9.3335 3.33317C9.3335 3.70136 9.63197 3.99984 10.0002 3.99984Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.0002 8.66683C10.3684 8.66683 10.6668 8.36835 10.6668 8.00016C10.6668 7.63197 10.3684 7.3335 10.0002 7.3335C9.63197 7.3335 9.3335 7.63197 9.3335 8.00016C9.3335 8.36835 9.63197 8.66683 10.0002 8.66683Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.0002 13.3333C10.3684 13.3333 10.6668 13.0349 10.6668 12.6667C10.6668 12.2985 10.3684 12 10.0002 12C9.63197 12 9.3335 12.2985 9.3335 12.6667C9.3335 13.0349 9.63197 13.3333 10.0002 13.3333Z" stroke="#667085" strokeWidth="1.28" strokeLinecap="round" strokeLinejoin="round" />
                              </g>
                              <defs>
                                <clipPath id="clip0_2657_16034">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg> */}
                          </span>
                          <FormGroup>
                            <FormControlLabel 
                              className='list_text'
                              control={
                                <Checkbox className='checkbox_padding'
                                  sx={{
                                    color: '#eaecf0',
                                    '&.Mui-checked': {
                                      color: '#f66d69',
                                    },
                                  }}
                                  checked={rowSelectionCheckboxGroupBy[item]} onChange={() => {

                                    setRowSelectionCheckboxGroupBy(prevArray => ({
                                      ...prevArray,
                                      [item]: !rowSelectionCheckboxGroupBy[item]
                                    }))
                                    const newArr = [...groupByArr]
                                    const indexToRemove = newArr.indexOf(item);
                                    if (indexToRemove !== -1) {
                                      newArr.splice(indexToRemove, 1);
                                    } else {
                                      newArr.push(item)
                                    }
                                    setGroupByArr(newArr)
                                  }} 
                                />
                              } 
                              label={item} />
                          </FormGroup>
                        </div>
                      )
                    })
                    }
                  </div>
                  <Box sx={{ display: 'flex', marginTop: '6px', padding: '4px 12px' }}>
                    <Button onClick={handleGroupByCancel} className='cancel_button w-full my-0'>Cancel</Button>
                    <Button className='create_button w-full my-0' onClick={handleGroupByCreate}>Group</Button>
                  </Box>
                </div>
              </Popover>
            </Box>
            </>}
            {/* Exporting */}
            {hideButton ? <></> : !['Accessory', 'asset_category', 'custom_attributes', 'asset_status', 'accessory_category','payment_term',
              'contract_tag', 'Contract Types', 'location_type', 'department', 'service_level_agreement', 'Insight log']?.includes(tableName) &&
              <Box sx={{ minWidth: 108 }}>
                <FormControl fullWidth className='cstm_select_formcontrol'>
                  {/* <InputLabel id="demo-simple-select-label">Export As</InputLabel> */}
                  <Select
                    displayEmpty
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className='export_field'
                    value={exportType}
                    onChange={handleChangeExport}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <span className="placeholder_title">Export As</span>;
                      }
                      return selected;
                    }}
                  >
                    <MenuItem disabled value="">
                      <span style={{ display: 'none' }} className="placeholder_title">Export As</span>
                    </MenuItem>
                    <MenuItem value="CSV">CSV</MenuItem>
                    <MenuItem value="Excel">Excel</MenuItem>
                    <MenuItem value="PDF">PDF</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            }
          </Box>
        </Box>

        <CustomTabPanel className='custom_tab' value={tabValue} index={tabValue}>
          {openCustomFilterUi && <Box className="filter_box">
            <><Typography sx={{ color: "#1D2939", fontSize: '12px', fontWeight: '500', lineHeight: '16px', fontFamily: '"Inter", sans-serif', marginBottom: '10px' }}>FILTER</Typography>

              <Box sx={{}}>
                {allFilter.map((item, index) => {
                  return (
                    <>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: "#FCFCFD", border: '1px solid #EAECF0', padding: '10px', borderRadius: "4px", marginBottom: '5px' }}>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ minWidth: 120, margin: '5px' }}>
                            <FormControl fullWidth className='serialNumberText_field cstm_select_formcontrol h-full p-4'>
                              <InputLabel id="demo-simple-select-label">Column Name</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                style={{maxWidth: '150px'}}
                                className='serialNumber_field h-full'
                                id="demo-simple-select"
                                value={item.column}
                                label="Column Name"
                                onChange={(e) => { handleColumnSelect(e, index) }}
                                MenuProps={{
                                  sx: {
                                    maxHeight: 350,
                                    '& .MuiMenuItem-root': {
                                      whiteSpace: 'normal',
                                    },
                                  },
                                }}
                              >
                                {Object.keys(columnHeaderAndValueMap || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                              </Select>
                            </FormControl>
                          </Box>
                          <Box sx={{ minWidth: 120, margin: '5px' }}>
                            <FormControl fullWidth className='serialNumberText_field cstm_select_formcontrol h-full' error={item.error.actionError}>
                              <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{width:'160px'}}
                                className='serialNumber_field h-full'
                                value={item.action}
                                label="Operator"
                                onChange={(e) => { handleFilterActionSelect(e, index) }}
                                MenuProps={{
                                  sx: {
                                    maxHeight: 350,
                                    '& .MuiMenuItem-root': {
                                      whiteSpace: 'normal',
                                    },
                                  },
                                }}
                              >
                                {(item.column?.includes("Date") || item.column?.includes("Time")) ? Object.keys((tableName === 'Contract' && (item.column === 'End Date' || item.column === 'Billing Cycle Date')) ? dateFilterActionForContract : dateFilterAction).map(item => (<MenuItem value={item}>{item}</MenuItem>)): Object.keys(othersFilterAction).map(item => (<MenuItem value={item}>{item}</MenuItem>))}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box sx={{ margin: '5px' }}>
                            <FormControl style={{ width: 160 }} className='filterText_field deep_input_holder h-full'>
                              {/* <Controller
                                        name="filter_input"
                                        className="mb-16"
                                        defaultValue={''}
                                        control={control}
                                        render={({ field }) => (
                                            <> */}
                            {(!(item.column?.includes("Date") || item.column?.includes("Time"))  && !(dropdownFilterColumns?.includes(item.column?.trimEnd()) && dropDownFilterModule?.includes(tableName))) && <TextField
                              // {...field}
                              variant='outlined'
                              placeholder='Filter Value'
                              required
                              value={item.inputVal}
                              onChange={(e) => { handleFilterInput(e, index) }}
                              style={{ background: 'white', height: '100%' }}
                              className='textfield_holder'
                            />}
                            {(['User', 'User Name','Agent Name']?.includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="User"
                                searchEnabled
                                listCall={getAllUser}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                emitItem={(value) => {
                                    handleFilterInput( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                             {(['Status', 'Asset Status', 'Status Name']?.includes(item.column) && ['asset', 'Archived Asset', 'asset_status', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
																			label="Filter Value"
																			module="Asset Status"
																			searchEnabled
                                      defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
																			listCall={getAllAssetStatus}
																			emitItem={(value) => {
                                          handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
																			}}
																			apiParams={{}}
																		/>}
                            {(item.column === 'Contract Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Contract Number"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllContract}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                            {(['Contract Tag', 'Contract Tag Name']?.includes(item.column) && ['Contract', 'Archived Contract', 'contract_tag','asset', 'Archived Asset']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Contract Tag"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllContractStatus}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                            {(['Model Name', 'Model']?.includes(item.column?.trimEnd()) && ['Model', 'activity_logs', 'Accessory', 'asset', 'Archived Asset']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Name"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetModel}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'model_name'}}
                              />}
                              {(item.column === 'Model Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Number"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetModel}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'model_no'}}
                              />}
                              {(['Asset Category','Category', 'Category Name']?.includes(item.column?.trimEnd()) && ['asset','asset_category', 'Model', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Category"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetCategory}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Manufacturer', 'Manufacturer Name']?.includes(item.column?.trimEnd()) && ['Model', 'asset', 'Manufacturer', 'Accessory', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Manufacturer"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetManufacturer}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(['Accessory', 'Accessory Name']?.includes(item.column?.trimEnd()) && ['Accessory', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Accessory"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetAccessory}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Location', 'Location Name']?.includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Location"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllLocation}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(['Type', 'Contract Type Name']?.includes(item.column) && ['Contract', 'Archived Contract', 'Contract Types', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Type"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: typeof item.inputVal === 'object' ? item.inputVal.id : item.id,label: typeof item.inputVal === 'object' ? item.inputVal.label : item.inputVal,key: `${typeof item.inputVal === 'object' ? item.inputVal.id : item.id}|${typeof item.inputVal === 'object' ? item.inputVal.label : item.inputVal}`} : null}
                                listCall={getAllContracType}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Service Provider', 'Service Provider Name']?.includes(item.column) && ['Contract', 'Archived Contract', 'service_provider', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Service Provider"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: typeof item.inputVal === 'object' ? item.inputVal.id : item.id,label: typeof item.inputVal === 'object' ? item.inputVal.label : item.inputVal,key: `${typeof item.inputVal === 'object' ? item.inputVal.id : item.id}|${typeof item.inputVal === 'object' ? item.inputVal.label : item.inputVal}`} : null}
                                listCall={getAllAssetServiceProvider}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Serial Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Asset"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAsset}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Priority' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Priority"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllPriority}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Status' && dropDownFilterModule?.includes(tableName) && ['service_request', 'Archived Service Tickets']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Status"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllStatus}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Location Type Name', 'Location Type']?.includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Location Type"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllLocationType}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(['Department','Department Name' ]?.includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Department"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllDepartment}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column?.trimEnd() === 'Accessory Category' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Accessory Category"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAssetAccessoryCategory}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Mac Address' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Mac Address"
                                searchEnabled
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                listCall={getAllAsset}
                                emitItem={(value) => {
                                  handleFilterInput(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'mac_id'}}
                              />}
                              {(item.column?.replace(/\s/g, '') === 'Source' && ['asset', 'Manufacturer','asset_category','Accessory','custom_attributes', 'accessory_category', 'shipping']?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputVal}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInput(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(assets_source || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column?.replace(/\s/g, '') === 'Source' && ['service_provider', 'user']?.includes(tableName) ) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputVal}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInput(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(source || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column === 'Network Status' && dropDownFilterModule?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputVal}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInput(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(assets_network_status || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                               {(item.column === 'Contract Status' && ['Contract', 'Archived Contract','asset', 'Archived Asset']?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputVal}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInput(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(contract_status || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                               {(item.column === 'Master' && dropDownFilterModule?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputVal}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInput(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(contract_master || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column === 'New/renew' && dropDownFilterModule?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputVal}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInput(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {['New', 'Renew'].map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column === 'Status' && tableName === 'user') && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputVal}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInput(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {['Active', 'Inactive'].map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                               {(item.column === 'Email' && tableName === 'user') && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="User Email"
                                searchEnabled
                                listCall={getAllUser}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                emitItem={(value) => {
                                    handleFilterInput( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'email'}}
                            />}
                             {(item.column === 'Name' && tableName === 'activity_logs') && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="User Name"
                                searchEnabled
                                listCall={getAllUser}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                emitItem={(value) => {
                                  handleFilterInput( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'name'}}
                            />}
                            {(['Role Name', 'User Role']?.includes(item.column?.trimEnd()) && ['user','activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Role Name"
                                searchEnabled
                                listCall={getAllRoles}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                emitItem={(value) => {
                                    handleFilterInput( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                            {(['Model Attribute', 'Attribute Name']?.includes(item.column?.trimEnd()) && ['custom_attributes','activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Attribute"
                                searchEnabled
                                listCall={getAllAssetCustomAttribute}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                emitItem={(value) => {
                                  handleFilterInput( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                            {(item.column === 'Payment Term Name' && ['payment_term', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Payment Term"
                                searchEnabled
                                listCall={getAllContracPaymentTerm}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                emitItem={(value) => {
                                  handleFilterInput( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                            {(item.column === 'Service Level Agreement Name' && ['service_level_agreement', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Service Level Agreement"
                                searchEnabled
                                listCall={getAllContracServiceLevelAgrement}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                emitItem={(value) => {
                                  handleFilterInput( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                            {(item.column === 'Ticket Id' && ['service_request', 'Archived Service Tickets', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Ticket Id"
                                searchEnabled
                                listCall={getAllServiceRequest}
                                defaultValue={item.inputVal ? {id: item.id,label: item.inputVal,key: `${item.id}|${item.inputVal}`} : null}
                                emitItem={(value) => {
                                  handleFilterInput( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                            {(item.column === 'Action' && tableName === 'activity_logs') && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputVal}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInput(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(actions || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              
                            {/* { groupByModule?.includes(tableName) &&
                              <TextField
                              // {...field}
                                variant='outlined'
                                placeholder='Filter Value'
                                required
                                value={item.inputVal}
                                onChange={(e) => { handleFilterInput(e, index) }}
                              />
                            } */}
                            {(item.column?.includes("Date") || item.column?.includes("Time")) && <CustomDatePickerV2
                              state={item.inputVal}
                              setState={setDateInputValOne}
                              required={false}
                              label=''	
                              onChangeFromParent = {(e)=>{handleFilterInput(e, index)}}									
                            />}	
                            {/* </> */}
                            {/* )}
                      /> */}
                            </FormControl>
                          </Box>
                        </Box>
                        <div style={{ margin: '5px' }} className="two_btns_holder">
                          {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='add_OrTabs'> */}
                          <Button className={`add_tabs_text ${allFilter[index].operator === 'AND' ? 'active' : ''}`} onClick={(e) => { handleAndOrBtn(e, "and", index) }}>and</Button>
                          <Button className={`dd_tabs_text ${allFilter[index].operator === 'OR' ? 'active' : ''}`} label="Or" onClick={(e) => { handleAndOrBtn(e, "or", index) }}>or</Button>
                          {/* </Tabs> */}
                        </div>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ minWidth: 120, margin: '5px' }}>
                            <FormControl fullWidth className='serialNumberText_field cstm_select_formcontrol' error={item.error.actionTwoError}>
                              <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className='serialNumber_field'
                                value={item.actionTwo}
                                label="Operator"
                                onChange={(e) => { handleFilterActionSelectTwo(e, index) }}
                                MenuProps={{
                                  sx: {
                                    maxHeight: 350,
                                    '& .MuiMenuItem-root': {
                                      whiteSpace: 'normal',
                                    },
                                  },
                                }}
                              >
                                
                                {(item.column?.includes("Date") || item.column?.includes("Time")) ? Object.keys((tableName === 'Contract' && (item.column === 'End Date' || item.column === 'Billing Cycle Date')) ? dateFilterActionForContract : dateFilterAction).map(item => (<MenuItem value={item}>{item}</MenuItem>)): Object.keys(othersFilterAction).map(item => (<MenuItem value={item}>{item}</MenuItem>))}
                              </Select>
                            </FormControl>
                          </Box>

                        <Box sx={{ margin: '5px' }}>
                          <div style={{ width: 160 }} className='filterText_field deep_input_holder'>
                          {(!(item.column?.includes("Date") || item.column?.includes("Time")) && !(dropdownFilterColumns?.includes(item.column?.trimEnd()) && dropDownFilterModule?.includes(tableName))) && <TextField
                              // {...field}
                              variant='outlined'
                              placeholder='Filter Value'
                              required
                              value={item.inputValTwo}
                              onChange={(e) => { handleFilterInputTwo(e, index) }}
                            />}
                            {(['User', 'User Name', 'Agent Name']?.includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="User"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllUser}
                                emitItem={(value) => {
                                  handleFilterInputTwo( value ? {id: value.id,label:value?.label} : null, index)    
                                }}
                                apiParams={{}}
                            />}
                             {(['Status', 'Asset Status', 'Status Name']?.includes(item.column) && ['asset', 'Archived Asset', 'asset_status','activity_logs' ]?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Asset Status"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetStatus}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Contract Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Contract Number"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllContract}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Contract Tag', 'Contract Tag Name']?.includes(item.column) && ['Contract', 'Archived Contract', 'contract_tag', 'asset', 'Archived Asset']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Contract Tag"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllContractStatus}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(['Model Name', 'Model']?.includes(item.column?.trimEnd()) && ['Model', 'activity_logs', 'Accessory', 'asset', 'Archived Asset']?.includes(tableName))  && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Name"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetModel}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'model_name'}}
                              />}
                              {(item.column === 'Model Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Number"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetModel}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'model_no'}}
                              />}
                              {(['Asset Category','Category', 'Category Name']?.includes(item.column?.trimEnd()) && ['asset','asset_category','Model', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Category"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetCategory}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Manufacturer', 'Manufacturer Name']?.includes(item.column?.trimEnd()) && ['Model', 'asset', 'Manufacturer','Accessory', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Manufacturer"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetManufacturer}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(['Location', 'Location Name']?.includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Location"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllLocation}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Type', 'Contract Type Name']?.includes(item.column) && ['Contract', 'Archived Contract', 'Contract Types', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Type"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllContracType}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Service Provider', 'Service Provider Name']?.includes(item.column) && ['Contract', 'Archived Contract', 'service_provider', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Service Provider"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetServiceProvider}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Serial Number' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Asset"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAsset}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Priority' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Priority"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllPriority}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                               {(item.column === 'Status' && dropDownFilterModule?.includes(tableName) && ['service_request', 'Archived Service Tickets']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Status"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllStatus}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Location Type Name', 'Location Type']?.includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Location Type"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllLocationType}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(['Department','Department Name']?.includes(item.column) && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Department"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllDepartment}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column?.trimEnd() === 'Accessory Category' && dropDownFilterModule?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Accessory Category"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetAccessoryCategory}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                              {(item.column === 'Mac Address' && tableName === 'asset') && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Mac Address"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAsset}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'mac_id'}}
                              />}
                              {(item.column?.replace(/\s/g, '') === 'Source' && ['asset', 'Manufacturer', 'asset_category', 'Accessory','custom_attributes', 'accessory_category', 'shipping']?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputValTwo}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInputTwo(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(assets_source || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column?.replace(/\s/g, '') === 'Source' && ['service_provider', 'user']?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputValTwo}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInputTwo(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(source || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column === 'Network Status' && dropDownFilterModule?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputValTwo}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInputTwo(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(assets_network_status || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column === 'Contract Status' && ['Contract', 'Archived Contract','asset', 'Archived Asset']?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputValTwo}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInputTwo(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(contract_status || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column === 'Master' && dropDownFilterModule?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputValTwo}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInputTwo(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(contract_master || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column === 'New/renew' && dropDownFilterModule?.includes(tableName)) && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputValTwo}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInputTwo(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {['New', 'Renew'].map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column === 'Status' && tableName === 'user') && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputValTwo}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInputTwo(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {['Active', 'Inactive'].map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(item.column === 'Email' && tableName === 'user') && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="User Email"
                                searchEnabled
                                listCall={getAllUser}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                emitItem={(value) => {
                                  handleFilterInputTwo( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'email'}}
                            />}
                            {(item.column === 'Name' && tableName === 'activity_logs') && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="User Name"
                                searchEnabled
                                listCall={getAllUser}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                emitItem={(value) => {
                                  handleFilterInputTwo( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{column: 'name'}}
                            />}
                            {(['Role Name', 'User Role']?.includes(item.column?.trimEnd()) && ['user','activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Role Name"
                                searchEnabled
                                listCall={getAllRoles}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                emitItem={(value) => {
                                  handleFilterInputTwo( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                            {(['Model Attribute', 'Attribute Name']?.includes(item.column?.trimEnd()) && ['custom_attributes','activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Model Attribute"
                                searchEnabled
                                listCall={getAllAssetCustomAttribute}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                emitItem={(value) => {
                                  handleFilterInputTwo( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                            {(item.column === 'Payment Term Name' && ['payment_term', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Payment Term"
                                searchEnabled
                                listCall={getAllContracPaymentTerm}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                emitItem={(value) => {
                                  handleFilterInputTwo( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                            {(item.column === 'Service Level Agreement Name' && ['service_level_agreement', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Service Level Agreement"
                                searchEnabled
                                listCall={getAllContracServiceLevelAgrement}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                emitItem={(value) => {
                                  handleFilterInputTwo( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}

                            {(item.column === 'Ticket Id' && ['service_request', 'Archived Service Tickets', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Ticket Id"
                                searchEnabled
                                listCall={getAllServiceRequest}
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                emitItem={(value) => {
                                  handleFilterInputTwo( value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                            />}
                            {(item.column === 'Action' && tableName === 'activity_logs') && 
                                <Select
                                  labelId="demo-simple-select-label"
                                  style={{width:'160px'}}
                                  className='serialNumber_field'
                                  id="demo-simple-select"
                                  value={item.inputValTwo}
                                  placeholder="Filter Value"
                                  onChange={(e) => {handleFilterInputTwo(e.target.value , index)  }}
                                  MenuProps={{
                                    sx: {
                                      maxHeight: 350,
                                      '& .MuiMenuItem-root': {
                                        whiteSpace: 'normal',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(actions || {}).map(item => (<MenuItem value={item}>{item}</MenuItem>))}

                                </Select>
                              }
                              {(['Accessory', 'Accessory Name']?.includes(item.column?.trimEnd()) && ['Accessory', 'activity_logs']?.includes(tableName)) && <SearchDropDownListPaginationComponent
                                label="Filter Value"
                                module="Accessory"
                                searchEnabled
                                defaultValue={item.inputValTwo ? {id: item.id,label: item.inputValTwo,key: `${item.id}|${item.inputValTwo}`} : null}
                                listCall={getAllAssetAccessory}
                                emitItem={(value) => {
                                  handleFilterInputTwo(value ? {id: value.id,label:value?.label} : null, index)  
                                }}
                                apiParams={{}}
                              />}
                            {/* { !groupByModule?.includes(tableName) &&
                              <TextField
                              // {...field}
                                variant='outlined'
                                placeholder='Filter Value'
                                required
                                value={item.inputValTwo}
                                onChange={(e) => { handleFilterInputTwo(e, index) }}
                              />
                            } */}
                            {(item.column?.includes("Date") || item.column?.includes("Time")) && <CustomDatePickerV2
                              state={item.inputValTwo}
                              setState={setDateInputValTwo}
                              required={false}
                              label=''	
                              onChangeFromParent = {(e)=>{handleFilterInputTwo(e, index)}}									
                            />}
                          </div>
                        </Box>  
                      </Box>                  
                      </Box>
                      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', alignItems: 'flex-start', marginTop: '6px' }}>
                        <>
                          {(allFilter.length - 1 === index) && <Button
                              className='add_anotherButton mt-0 mr-3 p-1'
                              onClick={addAnotherFilter}
                              startIcon={
                                <Icon fontSize="small" title="Add Another">
                                  add
                                </Icon>}>
                              Add Another</Button>
                            }
                            <div className="delete_filter_holder">
                              {(index !== 0) && <Icon onClick={() => { handleFilterDelete(index) }}>delete_outline_outlined</Icon>}
                            </div>
                          </>
                        </Box>
                      {/* </Box> */}
                    </>
                  )
                })}
                {/* <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  className='add_anotherButton'
                  onClick={addAnotherFilter}
                  startIcon={
                    <Icon fontSize="small" title="Add Another">
                      add
                    </Icon>}>
                  Add Another</Button>
              </Box> */}
              </Box>
              <Box>
                <Button onClick={handleApplyFilter} className='apply_filterButton'>Apply Filter</Button>
                <Button onClick={clearFilter} className='apply_filterButton'>Clear Filter</Button>
              </Box>
            </>
          </Box>}

        </CustomTabPanel>
        {/* {!groupByModule?.includes(tableName) && <CustomTabPanel className='custom_tab' value={tabValue} index={1}>
          {tabValue === 1 && <ActivityAgGridListing module={tableName} logs={logs} loadingActivityLog={loadingActivityLog}  />}
        </CustomTabPanel>} */}
      </Box>
    </div>
  )
})

export default AgGridFilterGroupView;

function TableHeaderTile({ displayTableName, tableName }){
    const asset_count = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.asset_count
  );
  const groupByInAsset = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.groupByInAsset
  );
  return(
    <Typography
      className='allAssets_text mb-0'
    >{
      (displayTableName == 'All Assets' && groupByInAsset) ? 'All Assets Grouping': displayTableName}
      {tableName == 'asset' && <span className='devices_text'>({asset_count})</span>}
    </Typography>
  )
}