import axios from '@fuse/utils/axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core';
import React, { useState,useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { countries } from '@fuse/utils/countryName'
import { Autocomplete } from '@material-ui/lab';
import Editor from '@fuse/components/Editor';
// import LocationTypeDialog from './QuickAddLocationType';
import { Stack, Box, Stepper, Step, StepButton, InputAdornment, Button, DialogTitle, Divider, Icon, IconButton, TextField, TextareaAutosize, Typography, Drawer, CardContent, Paper, Grid, Item, CircularProgress, Tooltip, Dialog,DialogContent, DialogActions } from '@mui/material'
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import AddManufacturerFromQuickAction from './AddManufacturerFromQuickAction'
import AddCategoryFromQuickAction from './AddCategoryFromQuickAction'
import AddAttributeFromQuickAction from './AddAttributeFromQuickAction';
import CustomDatePickerV2 from '@fuse/components/customDatePicker/DatePickerV2'
import { usePermission } from '@fuse/hooks';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))


const AddModelFromQuickAction = ({
    handleOpenCloseAddModalEvent,
    openAddStatusModalStateData,
    newDataAddedEvent
}) => {

    const spanTemp = {
		attribute: null,
        attributeError: '',
        attribute_value_error: '',
		attribute_value: '',
	}

    const classes = useStyles()
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const { hasPermission } = usePermission()
    const [ manufacturerMenu, setManufacturerMenu ] = useState([])
    const [ categoryMenu, setCategoryMenu ] = useState([])
    const [ dynamicAttributesMenu, setDynamicAttributesMenu ] = useState([])
    const [ allSpan, setAllSpan ] = useState([])
    const [ manufacturerSearch, setManufacturerSearch ] = useState('')
    const [ manufacturerError, setManufacturerError ] = useState('')
    const [ categoryError, setCategoryError ] = useState('')
    const [categorySearch, setCategorySearch] = useState('')
    // {id: 6, entity_external_platform_id: '4213376', manufacturer_name: 'HP'}
    const [ openAddManufacturerModal, setOpenAddManufacturerModal ] = useState(false)
    const [ openAddCategoryModal, setOpenAddCategoryModal ] = useState(false)
    const [ openAddAttributeModal, setOpenAddAttributeModal ] = useState(false)
    const [ selectedSpanDataIndex, setSelectedSpanDataIndex ] = useState(-1);
    const [ modelImageName, setModelImageName ] = useState('')
    const [ modelImageLocation, setModelImageLocation ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const addManufacturerPermission = hasPermission('add-manufacture');
    const addCategoryPermission = hasPermission('add-category');
    const addModelAttributePermission = hasPermission('add-model_attribute');

    const [eolDate,setEolDate] = useState({
		date: null,
		error: false
	})
	const [eoslDate,setEoslDate] = useState({
		date: null,
		error: false
	})
    
    const addNewSpan = () => {
        const newSpan = {
            ...spanTemp
        }
		setAllSpan((allSpan) => [...allSpan, newSpan])
	}

    const fetchAllCategoriesManufacturer = () => {
        axios.get(`/assets/fetch-category-manufacturer-suggestion`).then(response => {
            const data = response.data.data
            setManufacturerMenu(AlphabeticalSorting(data.manufacturers,'manufacturer_name'))
            setCategoryMenu(AlphabeticalSorting(data.categories,'category_name'))
        }).catch(err => {
            console.log('29=>',err)
        }) 
    }

    async function fetchAvailableDynamicField(){
		try {
            const res = await axios.get('/model-custom-attributes');
            const { data } = res.data;
            setDynamicAttributesMenu(AlphabeticalSorting(data, 'attribute_name'));
        } catch (err) {
            console.log(err);
        }
	}


    useEffect(() => {
        fetchAllCategoriesManufacturer()
        fetchAvailableDynamicField()
        addNewSpan()
    },[])

    const handleSpanInput = (index, value, propName) => {
		setAllSpan((spans) => {
			spans[index][propName] = value
            if(propName === "attribute") {
                if(value !== null) {
                    spans[index]["attributeError"] = ''
                }
            } 
            if(propName === "attribute_value") {
                if(value.trim() !== '') {
                    spans[index]["attribute_value_error"] = ''
                } else {
                    spans[index]["attribute_value_error"] = 'Attribute value is required!'
                }
            }

			return [...spans]
		} )
	}

    const defaultValues = {
        modelNumber: '',
        modelName: '',
        manufacturer: null,
        description: '',
        category: null,
        imageLink: '',
        imageName: '',
    }

    const schema = yup.object({
        modelNumber: yup.string().trim().required('Model Number is required!'),
        modelName: yup.string().trim().required('Model Name is required!'),
        manufacturer: yup.object().shape({
            id: yup.string().required('Manufacturer is required!'),
            // entity_external_platform_id: yup.string().required('Manufacturer is required!'),
            manufacturer_name: yup.string().required('Manufacturer is required!'),
        }),
        // manufacturer:  yup.object().nullable(),
        category: yup.object().shape({
            id: yup.string().required('Category is required!'),
            // entity_external_platform_id: yup.string().required('Category is required!'),
            category_name: yup.string().required('Category is required!'),
        }),
        description: yup.string().trim().nullable(),
        // imageLink:  yup.string().trim().nullable(),
        // imageName: yup.string().required('Location type is required!')
    })

    const { 
        control: modelFormControl, 
        formState:{errors: modelFormErrors}, 
        handleSubmit: modelFormSubmit, 
        trigger,
        setValue,
        getValues,
        clearErrors,
        setErrors,
        reset: modelFormReset 
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });


    function statusFormErrorData(error){
        console.log('statusFormErrorData: ',error);
    }

    


    const onSubmit = async () => {
        await trigger();
        let allSpanCopy = allSpan;
        let dynamic_attributes = [];
        const data = getValues();
        if(Object.keys(modelFormErrors).length !== 0) {
            for(let index = 0; index < allSpanCopy.length; index++){
                if(allSpanCopy[index].attribute !== null && allSpanCopy[index].attribute_value.trim() !== ''){
    
                    dynamic_attributes.push({
                        attribute_id: allSpanCopy[index].attribute.id,
                        attribute_value: String(allSpanCopy[index].attribute_value.trim())
                    })
                }
            }
            setAllSpan([...allSpanCopy])
            return;
        } else {
            let has_attribute_id_error = false;
            let has_attribute_value_error = false;
            for(let index = 0; index < allSpanCopy.length; index++){
                if(allSpanCopy[index].attribute !== null && allSpanCopy[index].attribute_value.trim() !== ''){
    
                    dynamic_attributes.push({
                        attribute_id: allSpanCopy[index].attribute.id,
                        attribute_value: String(allSpanCopy[index].attribute_value.trim())
                    })
                }
            }
            
            if(has_attribute_id_error || has_attribute_value_error) {
                setAllSpan([...allSpanCopy])
                return;
            } else {
                const payload = {
                    model_no: data.modelNumber,
                    model_name: data.modelName,
                    manufacturer_id: data.manufacturer.id,
                    category_id: data.category.id,
                    entity_external_platform_id: String(Math.trunc(Math.random()*20000)),
                    eol_date: eolDate.date ?? '',
                    eosl_date: eoslDate.date ?? '',
                    image_link: modelImageLocation,
                    image_name: modelImageName,
                }
                if(dynamic_attributes.length){
                    payload.dynamic_attributes = dynamic_attributes
                }
                axios.post('/models/add', payload).then(res => {
                    if(res.status == 201){
                        toast.error(res.data.message)
                        return
                    } 
                    toast.success(res.data.message)
                    newDataAddedEvent(res.data.data.new_model)
                }).catch(err => {
                    console.log('err: ',err);
                })
            }
        }
        
       
    }

    const handleOpenCloseAddManufacturer = () => {
        setOpenAddManufacturerModal(!openAddManufacturerModal)
    }

    const handleOpenCloseAddCategory = () => {
        setOpenAddCategoryModal(!openAddCategoryModal)
    }

    const handleOpenCloseAddAssetAttribute = (indexNumber) => {
        if(openAddAttributeModal === false) {
            setSelectedSpanDataIndex(indexNumber)
        } else {
            setSelectedSpanDataIndex(-1)
        }
        setOpenAddAttributeModal(!openAddAttributeModal);
    }

    const newManufacturerAddedEvent = (createdData) => {
        setOpenAddManufacturerModal(!openAddManufacturerModal)
        setValue('manufacturer', {id: createdData.id, entity_external_platform_id: createdData.entity_external_platform_id, manufacturer_name: createdData.manufacturer_name})
        clearErrors('manufacturer')
        fetchAllCategoriesManufacturer()
    } 
    const manageManufacturerSearch = (value) => {
        if(!value) return ''
        const currentManufacturer = manufacturerMenu.filter(item => item.manufacturer_name === value)
        setManufacturerSearch(currentManufacturer[0])
    }

    const manageCategorySearch = (value) => {
        if(!value) return ''
        const currentCategory = categoryMenu.filter(item => item.category_name === value)
        setCategorySearch(currentCategory[0])
    }


    const newCategoryAddedEvent  = (createdData) => {
        setOpenAddCategoryModal(!openAddCategoryModal)
        setCategorySearch({id: createdData.id, entity_external_platform_id: createdData.entity_external_platform_id, category_name: createdData.category_name})
        setValue('category', {
            id: createdData.id,
            category_name: createdData.category_name
        })
        clearErrors('category')
        fetchAllCategoriesManufacturer()
    }


    const newAttributeAddedEvent = (createdData) => {
        setOpenAddAttributeModal(!openAddAttributeModal)
        let allSpanCopy = allSpan
        allSpanCopy[selectedSpanDataIndex] = {
            attribute:{
                attribute_name: createdData.attribute_name,
                id: createdData.id,
                source: 'Manual'
            },
            attributeError: '',
            attribute_value_error: '',
            attribute_value: ''
        }
        setSelectedSpanDataIndex(-1)
        setAllSpan([...allSpanCopy])
    }

    const onChangeModelImageUpload = (event) => {
        setLoading(true)
        const selectedFile = event.target.files[0]        
        if(!selectedFile) return
		if (selectedFile.size > 2000000) {
			toast.error("File size cannot exceed 2Mb!");
            setLoading(false)
			return;
		} else{
            let formData = new FormData()
            formData.append("file", selectedFile)
            axios.post('/assets/hardware-asset-image-upload', formData ).then(response => {
                if(response.status === 201) {
                    toast.error(response.data.message)
                }
                else{
                    setModelImageName(selectedFile.name)
                    setModelImageLocation(response.data.data.imageLocation)
                }
            }).catch(err => {
                console.log('136=>',err);
                toast.error('Something went wrong!')
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const deleteSpan = (index) => {
		setAllSpan(allSpan.filter(span => allSpan[index] !== span )  )
	}

    

    return (
        <>
            <Dialog
                className="cstm_dialog_modal medium text-center"
                open={openAddStatusModalStateData}
                // onClose={handleOpenCloseAddModalEvent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div className='heading_holder flexbox_holder items-center w-full'>
                        <h2 className="main_heading">Add Model</h2>
                        <Button className='transparent_icon_btn' onClick={handleOpenCloseAddModalEvent}>
                            <i className="icon ti ti-x" />
                        </Button>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-left">
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Model Number
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="modelNumber"
                                className="mb-16"
                                control={modelFormControl}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            variant='outlined'
                                            // label='Model Number'
                                            fullWidth
                                            required
                                            error={!!modelFormErrors.modelNumber}
                                            helperText={modelFormErrors?.modelNumber?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px',
                                                    fontSize: '10px'
                                                }
                                            }}
                                            style={{ marginBottom: '10px',background: 'white' }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Model Name
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="modelName"
                                className="mb-16"
                                control={modelFormControl}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            variant='outlined'
                                            // label='Model Number'
                                            fullWidth
                                            required
                                            error={!!modelFormErrors.modelName}
                                            helperText={modelFormErrors?.modelName?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px',
                                                    fontSize: '10px'
                                                }
                                            }}
                                            style={{ marginBottom: '10px',background: 'white' }}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className='autocomplete_holder mb-10 relative'>
                            <Typography className='status_text'>
                                Manufacturer
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="manufacturer"
                                className="mb-16"
                                control={modelFormControl}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={manufacturerMenu}
                                            disablePortal
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">{children}
                                                    {addManufacturerPermission && <div className='p-16'>
                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddManufacturer()}>
                                                            <i className='ti ti-plus' />
                                                            <span>Add Manufacturer</span>
                                                        </Button>
                                                    </div>}
                                                </Paper>
                                            )}
                                            getOptionLabel={option => option.manufacturer_name ?? ''}
                                            renderInput={(params) => {
                                                return (
                                                <div ref={params.InputProps.ref}>
                                                    <TextField
                                                        {...params}
                                                        style={{background: 'white'}}
                                                        // label="Location"
                                                        required
                                                        defaultValue={defaultValues.manufacturer}
                                                        error={!!modelFormErrors.manufacturer}
                                                        helperText={modelFormErrors?.manufacturer?.message && 'Manufacturer is required!'}
                                                        FormHelperTextProps={{
                                                            style: { 
                                                                margin : 0, 
                                                                backgroundColor: '#f4f4f4',
                                                                width: '100%',
                                                                paddingTop: '2px',
                                                                fontSize: '10px'
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                                        
                            />
                        </div>


                        <div className='autocomplete_holder mb-10 relative'>
                            <Typography className='status_text'>
                                Category
                                <span className='required_span'>*</span>
                            </Typography>
                            <Controller
                                name="category"
                                className="mb-16"
                                control={modelFormControl}
                                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                                    <>
                                        <Autocomplete
                                            {...field}
                                            style={{ width: '100%' }}
                                            options={categoryMenu}
                                            disablePortal
                                            onChange={(event, value) => field.onChange(value)}
                                            PaperComponent={({ children }) => (
                                                <Paper className="autocomplete_holder">{children}
                                                    {addCategoryPermission && <div className='p-16'>
                                                        <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddCategory()}>
                                                            <i className='ti ti-plus' />
                                                            <span>Add Category</span>
                                                        </Button>
                                                    </div>}
                                                </Paper>
                                            )}
                                            getOptionLabel={option => option.category_name ?? ''}
                                            renderInput={(params) => {
                                                return (
                                                <div ref={params.InputProps.ref}>
                                                    <TextField
                                                        {...params}
                                                        style={{background: 'white'}}
                                                        // label="Location"
                                                        required
                                                        defaultValue={defaultValues.category}
                                                        error={!!modelFormErrors.category}
                                                        helperText={modelFormErrors?.category?.message && 'Category is required!'}
                                                        FormHelperTextProps={{
                                                            style: { 
                                                                margin : 0, 
                                                                backgroundColor: '#f4f4f4',
                                                                width: '100%',
                                                                paddingTop: '2px',
                                                                fontSize: '10px'
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                                        
                            />
                        </div>

                        <Typography className='status_text'>
                            Date
                            {/* <span className='required_span'>*</span> */}
                        </Typography>

                        <Grid container spacing={1}>
                            <Grid item md={8} lg={6}>
                                <div className='mb-10'>
                                    <CustomDatePickerV2
                                        state={eolDate}
                                        setState={setEolDate}
                                        required={false}
                                        label='EOL Date'								
                                    />
                                </div>  
                            </Grid>
                            <Grid item md={8} lg={6}>
                                <div className='mb-10'>
                                    <CustomDatePickerV2
                                        state={eoslDate}
                                        setState={setEoslDate}
                                        required={false}	
                                        label='EOSL Date'											
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <div className='mb-10'>
                            <Typography className='status_text'>
                                Description
                                {/* <span className='required_span'>*</span> */}
                            </Typography>
                            <Controller
                                name='description'
                                defaultValue={defaultValues.description} 
                                control={modelFormControl}
                                render={( { field} ) => (
                                    <>
                                        <TextField 
                                            {...field}
                                            style={{background: 'white',marginBottom: '10px'}}
                                            variant='outlined'
                                            // label='Status Description' 
                                            fullWidth
                                            InputProps={{
                                                inputComponent: TextareaAutosize,
                                            }}
                                            // required
                                            error={!!modelFormErrors.description}
                                            helperText={modelFormErrors?.description?.message}
                                            FormHelperTextProps={{
                                                style: { 
                                                    margin : 0, 
                                                    backgroundColor: '#f4f4f4',
                                                    width: '100%',
                                                    paddingTop: '2px',
                                                    fontSize: '10px'
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className='mb-10'>
                            <Typography variant='subtitle1' className='status_text'>Upload Image</Typography>
                            <div style={{display:'flex', alignItems: 'center'}}>
                                <input
                                    accept="image/*"
                                    id="model_image_upload"
                                    type="file"
                                    onChange = {(event) => onChangeModelImageUpload(event)}
                                    hidden
                                />
                                <label htmlFor='model_image_upload' className='light_grey_btn'>
                                    Browse
                                </label>
                                <div style={{marginLeft: '10px'}}>
                                    { loading ? 
                                        <>
                                        <div className={classes.loader}>
                                        <CircularProgress />
                                        </div>
                                        </> : <Typography>{modelImageName}</Typography>
                                    }
                                </div>
                            </div>
                            <p className='status_text mt-10'>{"Accepts jpg, jpeg, png, gif. 2Mb max (rec. 700x430px)"}</p>
                        </div>
                        <div>
                            {    
								allSpan.map((span, index) => {
									return ( 
										<div style={{ display: 'flex', justifyContent:'center', width:'100%', border:'1px solid #ddd', borderRadius: '5px', padding: '10px', marginTop: '15px' ,marginBottom: '10px' }} key={index}>
											<div style={{display:'flex', flexDirection: 'column', width:'100%'}}>
												<div style={{ display: 'flex', justifyContent:'center', width:'100%' }}>

													<div style={{ margin: '5px', width: '100%' }} className='autocomplete_holder relative'>
                                                        <Typography className='status_text'>
                                                            Attribute
                                                        </Typography>
														<Controller
															name="allSpan"
															className="mb-16"
															control={modelFormControl}
															render={({ field: { ref, ...field }, fieldState: { error } }) => (
																<>
																	<Autocomplete
																		{...field}
																		style={{ width: '100%' }}
																		options={dynamicAttributesMenu}
																		// disablePortal
                                                                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
																		getOptionLabel={option => option.attribute_name ?? ''}
																		// onChange={(event, value) => field.onChange(value)}
																		value={span.attribute}
																		disabled={Boolean(span.has_related_value_in_assets)}
																		onChange={(event,value) => {
																			// onChange(event)
																			handleSpanInput(index, value, "attribute")
																		}}
																		getOptionDisabled={option => Boolean(allSpan.find((i)=>i.attribute?.id == option.id))}
																		PaperComponent={({ children }) => (
																			<Paper className="autocomplete_holder">{children}
                                                                                {addModelAttributePermission && <div className='p-16'>
                                                                                    <Button className='secondary_btn w-full' onMouseDown={() => handleOpenCloseAddAssetAttribute(index)}>
                                                                                        <i className='ti ti-plus' />
                                                                                        <span>Add Attribute</span>
                                                                                    </Button>
                                                                                </div>}
                                                                            </Paper>
																		)}
																		renderInput={params => {
																			return (
																				<div >
																					<TextField
																						{...params}
																						style={{background: 'white'}}
																						label=''
																						variant='outlined'
                                                                                        error={span.attributeError === '' ? false : true}
                                                                                        helperText={span.attributeError}
																						fullWidth
                                                                                        FormHelperTextProps={{
                                                                                            style: { 
                                                                                                margin : 0, 
                                                                                                backgroundColor: '#f4f4f4',
                                                                                                width: '100%',
                                                                                                paddingTop: '2px',
                                                                                                fontSize:'10px'
                                                                                            }
                                                                                        }}
																					/>
																				</div>
																			)
																		}}
																	/>
																</>
															)}
														/>
													</div>

													<div style={{ margin: '5px', width: '100%' }}>
                                                        <Typography className='status_text'>
                                                            Value
                                                        </Typography>
														<Controller
															name='allSpan' 
															control={modelFormControl}
															render={( { field: { onChange, ...field } } ) => (
																<TextField 
																	{...field}
																	style={{background: 'white'}}
                                                                    className='value_input'
																	value={span.attribute_value}
																	onChange={event => {
																		handleSpanInput(index, event.target.value, "attribute_value")
																	}}
                                                                    error={span.attribute_value_error === '' ? false : true}
                                                                    helperText={span.attribute_value_error}
																	variant='outlined'
																	label='' 
																	fullWidth
                                                                    FormHelperTextProps={{
                                                                        style: { 
                                                                            margin : 0, 
                                                                            backgroundColor: '#f4f4f4',
                                                                            width: '100%',
                                                                            paddingTop: '2px',
                                                                            fontSize:'10px'
                                                                        }
                                                                    }}
																/>
															) }
														/>
													</div>
													
													{ 
														(index === 0 ? 
															<Tooltip title='Add More' placement='top'>
																<IconButton onClick={() => addNewSpan()}>
																	<Icon>add_circle_outline</Icon>
																</IconButton>
															</Tooltip>
															:
															<Tooltip title='Remove Span' placement='top'>
																<IconButton disabled={Boolean(span.has_related_value_in_assets)} onClick={() => deleteSpan(index)} >
																	<Icon>cancel_circle</Icon>
																</IconButton>
															</Tooltip>
														)
													}
												</div>
											</div>
										</div>
									)
								})
							} 
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="action_footer_holder">
                    <Button onClick={handleOpenCloseAddModalEvent} className="secondary_btn">Cancel</Button>
                    <Button onClick={onSubmit} className="primary_btn">
                        Save and Select
                    </Button>
                </DialogActions>
            </Dialog>
            {
                openAddManufacturerModal && <AddManufacturerFromQuickAction
                    handleOpenCloseAddModalEvent={handleOpenCloseAddManufacturer}
                    openAddStatusModalStateData={openAddManufacturerModal}
                    newDataAddedEvent={newManufacturerAddedEvent}
                />
            }
            {
                openAddCategoryModal && <AddCategoryFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddCategory}
                    openAddStatusModalStateData={openAddCategoryModal}
                    newDataAddedEvent={newCategoryAddedEvent}
                />
            }
            {
                openAddAttributeModal && <AddAttributeFromQuickAction 
                    handleOpenCloseAddModalEvent={handleOpenCloseAddAssetAttribute}
                    openAddStatusModalStateData={openAddAttributeModal}
                    newDataAddedEvent={newAttributeAddedEvent}
                />
            }
        </>
        

        
    )
}


export default AddModelFromQuickAction;