import { useState, useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts'
import { Button } from '@material-ui/core';
import { Box, Select, FormControl, MenuItem, fabClasses } from '@mui/material'
import Grid from '@mui/material/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from '@fuse/utils/axios'
import customColor, { getLastDayOfMonth, getMonth } from 'helper/common';
import useRouter from '@fuse/hooks/useRouter';
import { useDispatch } from "react-redux";
import { setCustomFilterData } from 'app/main/contracts/store/filterParamsReducer';

function DashboardChart(props) {
  const [toggle, setToggle] = useState(true)
  const [toggleOne, setToggleOne] = useState(true)
  const [isLoadingForContractExpiration, setIsLoadingForContractExpiration] = useState(false)
  const [isLoadingForContractBilling, setIsLoadingForContractBilling] = useState(false)
  const [isLoadingForCategory, setIsLoadingForCategory] = useState(false)
  const [isLoadingForLocation, setIsLoadingForLocation] = useState(false)
  const [isAllZeroForContractExpiration, setIsAllZeroForContractExpiration] = useState(false)
  const [isAllZeroForContractBilling, setIsAllZeroForContractBilling] = useState(false)
  const [isLoadingServiceProvider, setIsLoadingServiceProvider] = useState(false)
  const [isLoadingContractType, setIsLoadingContractType] = useState(false)
  const [customFilterDataInput, setCustomFilterDataInput] = useState()

  const router = useRouter()
  const dispatch = useDispatch();
  const getPercentWidth = (w, n, x) => {
    const percentWidth = Math.round((x / (w / n)) * 100)
    return `${percentWidth}%`
  }

  const [expirationYear, setExpirationYear] = useState((new Date()).getFullYear());
  const [contractBillingYear, setContractBillingYear] = useState((new Date()).getFullYear());
  const [year, setYear] = useState((new Date()).getFullYear());
  const currentYear = (new Date()).getFullYear() + 5;
  const years = Array.from({ length: (2010 - currentYear) / -1 + 1}, (_, i) => currentYear + (i * (-1))).sort();
  const [contractExpirationChartOption, setContractExpirationChartOption] = useState({
    series: [{
      name: 'Count',
      data: []
    }],
    options: {
      annotations: {
        points: [{
          x: 'Bananas',
          seriesIndex: 0,
          label: {
            offsetY: 0,
            style: {
              color: '#fff',
            },
          }
        }]
      },
      chart: {
        height: 350,
        type: 'bar',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          distributed: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 0
      },
      grid: {
        borderColor: '#EAECF0',
        strokeDashArray: 5,
        row: {
          colors: ['#fff']
        }
      },
      xaxis: {
        labels: {
          show: true,
          rotate: 0,
          enabled: false,
          style: {
            cssClass: 'apexcharts-xaxis-label',
          }
        },
        tooltip: {
          enabled: false
        },
        categories: [],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: 'Series',
          style: {
            fontSize: '10px',
            fontWeight: '500',
            fontFamily: 'Inter',
            color: '#98A2B3',
            lineHeight: '16px'
          },
        },
      },
      // fill: {
      //   colors: ['#ffd480', '#e6e6ff', '#ffbb99'],
      //   opacity: 1
      // }
    }
  })

  const [contractBillingChartOption, setContractBillingChartOption] = useState({
    series: [{
      name: 'Count',
      data: []
    }],
    options: {
      annotations: {
        points: [{
          x: 'Bananas',
          seriesIndex: 0,
          label: {
            offsetY: 0,
            style: {
              color: '#fff',
            },
          }
        }]
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          distributed: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        row: {
          colors: ['#fff']
        }
      },
      xaxis: {
        labels: {
          show: true,
          rotate: 0,
          enabled: false,
          style: {
            cssClass: 'apexcharts-xaxis-label',
          }
        },
        tooltip: {
          enabled: false
        },
        categories: [],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: 'Amount',
          style: {
            fontSize: '10px',
            fontWeight: '500',
            fontFamily: 'Inter',
            color: '#98A2B3',
            lineHeight: '16px'
          },
        },
      },
    }
  })

  const [serviceProviderChartOption, setServiceProviderChartOption] = useState({
    // series: [],
    series: [44, 55, 13, 43, 22, 23, 34, 37],
    options: {
      chart: {
        width: 300,
        height: 300,
        type: 'pie',
        align: 'left',
        // offsetY:60,
        // offsetX:0,
        // offsetX: -120,
      },
      legend: {
        show: true,
        markers: {
          radius: 0,
        },
        // offsetY: -120,
        // offsetX: -180,
        position: 'right',
        horizontalAlign: 'right',
        // itemMargin:{
        //   horizontal:40,
        //   vertical:50,
        // }
      },
      yaxis: {
        show: true,
        title: {
          text: 'Asset Count',
          style: {
            fontSize: '10px',
            fontWeight: '500',
            fontFamily: 'Inter',
            color: '#98A2B3',
            lineHeight: '16px'
          },
        },
      },
      // pie: {
      //   dataLabels: {
      //     offset: -50,
      //   }, 
      // },
      points: [{
        mouseEnter: undefined,
        mouseLeave: undefined,
        label: {
          borderColor: '#c2c2c2',
          borderWidth: 1,
          borderRadius: 2,
          text: undefined,
          textAnchor: 'middle',
          offsetX: 0,
          offsetY: -15,
          mouseEnter: undefined,
          mouseLeave: undefined,
          style: {
            background: 'red',
            color: '#777',
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: undefined,
            cssClass: 'apexcharts-point-annotation-label',
            padding: {
              left: 5,
              right: 5,
              top: 0,
              bottom: 2,
            }
          },
        },
      }],
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E', 'Team F', 'Team G', 'Team H'],
      series: [2, 2, 6],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 280
          },
        }
      }],
    },
  })

  const [typeChartOption, setTypeChartOption] = useState({
    // series: [],
    series: [44, 55, 13, 43, 22, 23, 34, 37],
    options: {
      chart: {
        width: 300,
        height: 300,
        type: 'pie',
        align: 'left',
        // offsetY:60,
        // offsetX:0,
        // offsetX: -120,
      },
      legend: {
        show: true,
        markers: {
          radius: 0,
        },
        // offsetY: -120,
        // offsetX: -180,
        position: 'right',
        horizontalAlign: 'right',
        // itemMargin:{
        //   horizontal:40,
        //   vertical:50,
        // }
      },
      yaxis: {
        show: true,
        title: {
          text: 'Asset Count',
          style: {
            fontSize: '10px',
            fontWeight: '500',
            fontFamily: 'Inter',
            color: '#98A2B3',
            lineHeight: '16px'
          },
        },
      },
      // pie: {
      //   dataLabels: {
      //     offset: -50,
      //   }, 
      // },
      points: [{
        mouseEnter: undefined,
        mouseLeave: undefined,
        label: {
          borderColor: '#c2c2c2',
          borderWidth: 1,
          borderRadius: 2,
          text: undefined,
          textAnchor: 'middle',
          offsetX: 0,
          offsetY: -15,
          mouseEnter: undefined,
          mouseLeave: undefined,
          click: undefined,
          style: {
            background: 'red',
            color: '#777',
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: undefined,
            cssClass: 'apexcharts-point-annotation-label',
            padding: {
              left: 5,
              right: 5,
              top: 0,
              bottom: 2,
            }
          },
        },
      }],
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E', 'Team F', 'Team G', 'Team H'],
      series: [2, 2, 6],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 280
          },
        }
      }],
    },
  })

  const [pieChartOption, setPieChartOption] = useState({
    // series: [],
    series: [44, 55, 13, 43, 22, 23, 34, 37],
    options: {
      chart: {
        width: 300,
        height: 300,
        type: 'pie',
        align: 'left',
        // offsetY:60,
        // offsetX:0,
        // offsetX: -120,
      },
      legend: {
        show: true,
        markers: {
          radius: 0,
        },
        // offsetY: -120,
        // offsetX: -180,
        position: 'right',
        horizontalAlign: 'right',
        // itemMargin:{
        //   horizontal:40,
        //   vertical:50,
        // }
      },
      yaxis: {
        show: true,
        title: {
          text: 'Asset Count',
          style: {
            fontSize: '10px',
            fontWeight: '500',
            fontFamily: 'Inter',
            color: '#98A2B3',
            lineHeight: '16px'
          },
        },
      },
      // pie: {
      //   dataLabels: {
      //     offset: -50,
      //   }, 
      // },
      points: [{
        mouseEnter: undefined,
        mouseLeave: undefined,
        click: undefined,
        label: {
          borderColor: '#c2c2c2',
          borderWidth: 1,
          borderRadius: 2,
          text: undefined,
          textAnchor: 'middle',
          offsetX: 0,
          offsetY: -15,
          mouseEnter: undefined,
          mouseLeave: undefined,
          click: undefined,
          style: {
            background: 'red',
            color: '#777',
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: undefined,
            cssClass: 'apexcharts-point-annotation-label',
            padding: {
              left: 5,
              right: 5,
              top: 0,
              bottom: 2,
            }
          },
        },
      }],
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E', 'Team F', 'Team G', 'Team H'],
      series: [2, 2, 6],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 280
          },
        }
      }],
    },
  })


  // Graph: 2
  const [columnChart2Option, setColumnChart2Option] = useState({
    series: [{
      name: 'Count',
      data: []
    }],
    options: {
      annotations: {
        points: [{
          x: 'Bananas',
          seriesIndex: 0,
          label: {
            offsetY: 0,
            style: {
              color: '#fff',
            },
          }
        }]
      },
      chart: {
        height: 350,
        type: 'bar',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          distributed: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0
      },
      grid: {
        row: {
          colors: ['#fff']
        }
      },
      xaxis: {
        labels: {
          show: true,
          rotate: 0,
          enabled: false,
          style: {
            cssClass: 'apexcharts-xaxis-label',
          }
        },
        tooltip: {
          enabled: false
        },
        categories: [],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: 'Asset Count',
          style: {
            fontSize: '10px',
            fontWeight: '500',
            fontFamily: 'Inter',
            color: '#98A2B3',
            lineHeight: '16px'
          },
        },
      },
      // fill: {
      //   colors: ['#ffd480', '#e6e6ff', '#ffbb99']
      // }
    }
  })

  const [pieChart2Option, setPieChart2Option] = useState({
    // series: [],
    series: [44, 55, 13, 43, 22, 23, 34, 37],
    options: {
      chart: {
        width: 300,
        height: 300,
        type: 'pie',
        align: 'left',
      },
      fill: {
        colors: ['#ffd480', '#e6e6ff', '#ffbb99'],

      },
      legend: {
        // offsetY: -120,
        // offsetX: 80,
        position: 'right',
        horizontalAlign: 'right',
        show: true,
        markers: {
          radius: 0,
        },
        // itemMargin:{
        //   horizontal:40,
        //   vertical:50,
        // }
      },
      // labels: [],
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E', 'Team F', 'Team G', 'Team H'],
      series: [2, 2, 6],
      colors: ['#ffd480', '#e6e6ff', '#ffbb99'],
      points: [{
        mouseEnter: undefined,
        mouseLeave: undefined,
        click: undefined,
        label: {
          borderColor: '#c2c2c2',
          borderWidth: 1,
          borderRadius: 2,
          text: undefined,
          textAnchor: 'middle',
          offsetX: 0,
          offsetY: -15,
          mouseEnter: undefined,
          mouseLeave: undefined,
          click: undefined,
          style: {
            background: 'red',
            color: '#777',
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: undefined,
            cssClass: 'apexcharts-point-annotation-label',
            padding: {
              left: 5,
              right: 5,
              top: 0,
              bottom: 2,
            }
          },
        },
      }],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 280
          },
        }
      }]
    },
  })

  function contractExpirationGraph(filterYear) {
    setIsLoadingForContractExpiration(true)
    axios.get(`/dashboard/dashboard-contract-expiration?year=${filterYear ?? expirationYear}`).then((res) => {

      const { contractCount, categories } = res.data.data
      const percentWidth = getPercentWidth(250, 10, contractCount.length)
      const isAllZero = contractCount.every(item => item === 0);
      const updatedArray = contractCount.map(value => (value === 0 ? 0.01 : value));
      setIsAllZeroForContractExpiration(isAllZero)
      setContractExpirationChartOption({
        ...contractExpirationChartOption,
        series: [{
          name: 'Count',
          data: updatedArray
        }],
        options: {
          ...contractExpirationChartOption.options,
          chart: {
            ...contractExpirationChartOption.options.chart,
            events: {
              dataPointSelection: function(event, chartContext, config) {
                  const lastDay = getLastDayOfMonth(filterYear ?? expirationYear, config.dataPointIndex === 11 ? config.dataPointIndex -1 : config.dataPointIndex);
                  const month1 = getMonth(config.dataPointIndex === 11 ? 0 : config.dataPointIndex + 1)
                  const month2 = getMonth(config.dataPointIndex === 0 ? 11 : config.dataPointIndex-1)

                  const customFilter ={
                    type:'End Date',
                    end_date1: `01-${month1}-${ config.dataPointIndex === 11 ? (filterYear ?? expirationYear) + 1 : (filterYear ?? expirationYear)}`,
                    action1: 'Before',
                    action2: 'After',
                    end_date2: `${lastDay}-${month2}-${config.dataPointIndex === 0 ? (filterYear ?? expirationYear) - 1 : (filterYear ?? expirationYear)}`,
                  }
                  dispatch(setCustomFilterData(customFilter))
                  router.push(`/contracts`)
              }
            },
          },
          plotOptions: {
            bar: {
              ...contractExpirationChartOption.options.plotOptions.bar,
              columnWidth: percentWidth,
            }
          },
          colors: customColor(contractCount, 28),
          fill: {
            colors: customColor(contractCount, 28)
          },
          xaxis: {
            ...contractExpirationChartOption.options.xaxis,
            categories: categories,
            labels: {
              // trim: true,
              // rotate: 0,
              style: {
                cssClass: 'apexcharts-xaxis-label',
              },
             // rotate: -55,
              //rotateAlways: true,
              trim: true,
              hideOverlappingLabels: true,
              show: true,
              minHeight: 100,
              maxHeight: 100,
            }
          },
          tooltip: {
            custom({ series, seriesIndex, dataPointIndex, w }) {
              return (
                `${'<div class="custom_apex_tooltip">' +
                '<div class="square" style="background-color: '}${w.globals.fill.colors[dataPointIndex]};"></div>` +
                `<span>` +
                `<span>Series</span>` + `: ${w.globals.initialSeries[seriesIndex].data[dataPointIndex] !== 0.01 ? w.globals.initialSeries[seriesIndex].data[dataPointIndex] : 0
                }</span>` +
                `</div>`
              );
            }
          }
        }
      })
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setIsLoadingForContractExpiration(false)
    })
  }

  const getYAxisFormatter = (val) => {
    return `$${Number(val).toLocaleString(undefined, {  minimumFractionDigits: 2 , maximumFractionDigits: 2 })}`;
  };

  function contractBilligAmoutGraph(filterYear) {
    setIsLoadingForContractBilling(true)
    axios.get(`/dashboard/dashboard-contract-bill-amount-graph?year=${filterYear ?? contractBillingYear}`).then((res) => {

      const { contractCount, categories } = res.data.data
      const percentWidth = getPercentWidth(250, 10, contractCount.length)
      const isAllZero = contractCount.every(item => item === 0);
      setIsAllZeroForContractBilling(isAllZero)
      setContractBillingChartOption({
        ...contractBillingChartOption,
        series: [{
          name: 'Count',
          data: contractCount
        }],
        options: {
          ...contractBillingChartOption.options,
          chart: {
            ...contractExpirationChartOption.options.chart,
            events: {
              click: (event, chartContext, config) => {
                if(config.dataPointIndex !== -1){
                  const month1 = getMonth(config.dataPointIndex === 11 ? 0 : config.dataPointIndex + 1)
                  const month2 = getMonth(config.dataPointIndex === 0 ? 11 : config.dataPointIndex-1)
                  const lastDay = getLastDayOfMonth(filterYear ?? contractBillingYear, month2);

                  const customFilter ={
                    type:'Billing Cycle Date',
                    end_date1: `01-${month1}-${ config.dataPointIndex === 11 ? (filterYear ?? contractBillingYear) + 1 : (filterYear ?? contractBillingYear)}`,
                    action1: 'Before',
                    action2: 'After',
                    end_date2: `${lastDay}-${month2}-${config.dataPointIndex === 0 ? (filterYear ?? contractBillingYear) - 1 : (filterYear ?? contractBillingYear)}`,
                  }
                  dispatch(setCustomFilterData(customFilter))
                  router.push(`/contracts`)
                }
              }
            },
          },
          xaxis: {
            ...contractBillingChartOption.options.xaxis,
            categories: categories,
            labels: {
              // trim: true,
              // rotate: 0,
              style: {
                cssClass: 'apexcharts-xaxis-label',
              },
             // rotate: -55,
              //rotateAlways: true,
              trim: true,
              hideOverlappingLabels: true,
              show: true,
            }
          },
          yaxis: {
            title: {
              text: 'Amount',
              style: {
                fontSize: '10px',
                fontWeight: '500',
                fontFamily: 'Inter',
                color: '#98A2B3',
                lineHeight: '16px'
              },
            },
            labels: {
              formatter: getYAxisFormatter
            }
          },
          tooltip: {
						custom({ series, seriesIndex, dataPointIndex, w }) {
						  return (
							`${'<div class="custom_apex_tooltip">' +
							'<div class="square" style="background-color: '}${  w.globals.colors[seriesIndex]  };"></div>` +
							`<span>` +
							`<span>Amount</span>` + `: ${ 
								getYAxisFormatter(series[seriesIndex][dataPointIndex])
							}</span>` +
							`</div>`
						  );
						}
					}
        }
      })
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setIsLoadingForContractBilling(false)
    })
  }

  function locationGraph() {
    axios.get('/dashboard/asset-location-graph').then((res) => {
      const { series_data, series_labels } = res.data.data
      const percentWidth = getPercentWidth(250, 10, series_data.length)
      setColumnChart2Option({
        ...columnChart2Option,
        series: [{
          name: 'Count',
          data: series_data
        }],
        options: {
          ...columnChart2Option.options,
          chart: {
            ...columnChart2Option.options.chart,
            events: {
              click: (event, chartContext, config) => {
                dispatch(setCustomFilterData({ Location: series_labels[config.dataPointIndex] }))
                router.push(`/assets-list`)
              }
            },
          },
          plotOptions: {
            bar: {
              ...columnChart2Option.options.plotOptions.bar,
              columnWidth: percentWidth,
            }
          },
          colors: customColor(series_data),
          fill: {
            colors: customColor(series_data)
          },
          xaxis: {
            ...columnChart2Option.options.xaxis,
            categories: series_labels,
            labels: {
              // trim: true,
              // rotate: 0,
              style: {
                cssClass: 'apexcharts-xaxis-label',
              },
              rotate: -55,
              rotateAlways: true,
              trim: true,
              hideOverlappingLabels: true,
              show: true,
              minHeight: 100,
              maxHeight: 100,
            },
          },
          tooltip: {
            custom({ series, seriesIndex, dataPointIndex, w }) {
              return (
                `${'<div class="custom_apex_tooltip">' +
                '<div class="square" style="background-color: '}${w.globals.fill.colors[dataPointIndex]};"></div>` +
                `<span>` +
                `<span>${series_labels[dataPointIndex]}</span>` + `: ${w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                }</span>` +
                `</div>`
              );
            }
          },

        }
      })
      setPieChart2Option({
        ...pieChart2Option,
        series: series_data,
        options: {
          ...pieChart2Option.options,
          colors: customColor(series_data),
          fill: {
            colors: customColor(series_data)
          },
          labels: series_labels,
          tooltip: {
            custom({ series, seriesIndex, dataPointIndex, w }) {
              return (
                `${'<div class="custom_apex_tooltip">' +
                '<div class="square" style="background-color: '}${w.globals.fill.colors[seriesIndex]};"></div>` +
                `<span>` +
                `<span>${w.globals.labels[seriesIndex]}</span>` + `: ${series[seriesIndex]
                }</span>` +
                `</div>`
              );
            }
          }
        }
      })
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      //setIsLoadingForLocation(false)
    })
  }

  function getContractServiceProvider() {
    let lengthServiceProvider = 0;
    let serviceProviderLabel = []
    let serviceProviderId = []
    let serviceProviderContractCount = []
    setIsLoadingServiceProvider(true)
    axios.get("dashboard/dashboard-contract-service-provider").then(res => {
      const { data } = res.data
      data.map((item) => {
        serviceProviderLabel.push(item?.service_provider_name)
        serviceProviderId.push(item?.id)
        serviceProviderContractCount.push(item.contract_headers.length)
        lengthServiceProvider += item.contract_headers.length
      })

      setServiceProviderChartOption({
        ...serviceProviderChartOption,
        series: serviceProviderContractCount,

        options: {
          ...serviceProviderChartOption.options,
          labels: serviceProviderLabel,
          chart: {
            ...serviceProviderChartOption.options.chart,
            events: {
              dataPointSelection: function (event, chartContext, config) {
                setCustomFilterDataInput({ service_provider: serviceProviderLabel[config.dataPointIndex], id: serviceProviderId[config.dataPointIndex] })
              }
            },
          },
          colors: customColor(serviceProviderContractCount),
          fill: {
            colors: customColor(serviceProviderContractCount)
          },
          tooltip: {
            custom({ series, seriesIndex, dataPointIndex, w }) {
              return (
                `${'<div class="custom_apex_tooltip">' +
                '<div class="square" style="background-color: '}${w.globals.fill.colors[seriesIndex]};"></div>` +
                `<span>` +
                `<span>${w.globals.labels[seriesIndex]}</span>` + `: ${series[seriesIndex]
                }</span>` +
                `</div>`
              );
            }
          }
        }
      })
      setIsLoadingServiceProvider(false)
    }).catch (err => {
      console.log(err)
    })
  }

  function getContractHeaderType() {
    let lengthContractType = 0;
    let contractTypeLabel = []
    let contractTypeId = []
    let typeContractCount = []

    setIsLoadingContractType(true)
    axios.get("dashboard/dashboard-contract-header-type").then(res => {

      const { data } = res.data
      data.map((item) => {
        contractTypeLabel.push(item?.display_name)
        contractTypeId.push(item?.id)
        typeContractCount.push(item.contract_headers.length)
        lengthContractType += item.contract_headers.length
      })

      setTypeChartOption({
        ...typeChartOption,
        series: typeContractCount,

        options: {
          ...typeChartOption.options,
          labels: contractTypeLabel,
          chart: {
            ...typeChartOption.options.chart,
            events: {
              dataPointSelection:
                  (event, chartContext, config) => {
                    setCustomFilterDataInput({ type: contractTypeLabel[config.dataPointIndex], id: contractTypeId[config.dataPointIndex]})
                  }
            },
          },
          colors: customColor(typeContractCount),
          fill: {
            colors: customColor(typeContractCount)
          },
          tooltip: {
            custom({ series, seriesIndex, dataPointIndex, w }) {
              return (
                `${'<div class="custom_apex_tooltip">' +
                '<div class="square" style="background-color: '}${w.globals.fill.colors[seriesIndex]};"></div>` +
                `<span>` +
                `<span>${w.globals.labels[seriesIndex]}</span>` + `: ${series[seriesIndex]
                }</span>` +
                `</div>`
              );
            }
          }
        }
      })
      setIsLoadingContractType(false)
    
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    if(customFilterDataInput){
    dispatch(setCustomFilterData(customFilterDataInput))
    router.push(`/contracts`)
    }
  },[customFilterDataInput])

  useEffect(() => {
    contractExpirationGraph()
    contractBilligAmoutGraph()
    dispatch(setCustomFilterData(null))
    locationGraph()
    getContractServiceProvider()
    getContractHeaderType()
  }, []);

  const handleChangeExpirationYear = (event) => {
    setExpirationYear(event.target.value);
    contractExpirationGraph(event.target.value)
  };

  const handleChangeBillingYear = (event) => {
    setContractBillingYear(event.target.value);
    contractBilligAmoutGraph(event.target.value)
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
            <Card className='card_chartBox translate-20-holder' style={{ marginRight: '5px' }}>
          {!isLoadingForContractExpiration && 
          <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '13px' }}>
                <div className='assets_statusText'>
                  <h5>Contract Expiration</h5>
                  <p>You can see all the contract expiration related data here</p>
                </div>
                <div>
                  <FormControl fullWidth className='assets_listSelect small_select_holder'>
                    {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className='assets_listFiled'
                      value={expirationYear}
                      onChange={handleChangeExpirationYear}
                      MenuProps={{
                        sx: {
                          maxHeight: 230,
                          '& .MuiMenuItem-root': {
                            whiteSpace: 'normal',
                          },
                        },
                    }}
                      >
                       {years.length &&  years.map((year) =>
                      <MenuItem value={year}>{year}</MenuItem>
                      )}
                      {/* <div className='px-16'>
                              <Button className='secondary_btn w-full' onClick={() => handleOpenAddStatus()}>
                                  <i className='ti ti-plus' />
                                  <span>Add Status</span>
                              </Button>
                          </div> */}
                    </Select>
                  </FormControl>
                </div>
              </Box>
              <hr style={{ margin: '0px' }} />
              <CardContent>
                  <div style={{ position: 'relative' }}>
                  {(!isLoadingForContractExpiration && !isAllZeroForContractExpiration) ?
                   <div className='chart_height' style={{ display: toggle ? 'block' : 'none' }} id="chart">
                      <div>
                        <ReactApexChart id="myChart"
                          series={contractExpirationChartOption.series}
                          options={contractExpirationChartOption.options} type="bar" height={300}
                          />
                      </div>
                      <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ padding: '6px 8px' }} className='chart_button'>
                          Month
                        </div>
                      </Box>
                    </div>
                     : <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '10px', fontSize:'16px' }}>
                     <span >No records available for the selected year range.</span>
                   </Box>
                   }
                  </div>
                {/* <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                  <Button className='chart_button'>
                    Category
                  </Button>
                </Box> */}
              </CardContent>
              </>
          }
            </Card>
        </Grid>
        <Grid item xs={6}>
          {!isLoadingServiceProvider &&
            <Card className='card_chartBox translate-20-holder'>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '13px' }}>
                <div className='assets_statusText'>
                  <h5>Contract Provider Distribution</h5>
                  <p>You can see all the service provider related data here</p>
                </div>
              </Box>
              <hr style={{ margin: '0px' }} />
              <CardContent>
                <div style={{ position: 'relative' }}>
                  {/* {toggleOne ? */}<div className='chart_height' style={{ display: toggleOne ? 'block' : 'none' }} id="chart">
                    <div>
                      <ReactApexChart series={serviceProviderChartOption.series}
                        options={serviceProviderChartOption.options} type="pie" width="100%" height={300} className="pie_chart" />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          }
        </Grid>
        <Grid item xs={6}>
          {!isLoadingForContractBilling &&
            <Card className='card_chartBox translate-20-holder' style={{ marginRight: '5px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '13px' }}>
                <div className='assets_statusText'>
                  <h5>Contract Bill Amount</h5>
                  <p>You can see all the contract billing related data here</p>
                </div>
                <div>
                  <FormControl fullWidth className='assets_listSelect small_select_holder'>
                    {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className='assets_listFiled'
                      value={contractBillingYear}
                      onChange={handleChangeBillingYear}
                      MenuProps={{
                        sx: {
                          maxHeight: 230,
                          '& .MuiMenuItem-root': {
                            whiteSpace: 'normal',
                          },
                        },
                    }}
                    >
                      {years.length &&  years.map((year ) =>
                      <MenuItem value={year}>{year}</MenuItem>
                      )}
                      {/* <div className='px-16'>
                              <Button className='secondary_btn w-full' onClick={() => handleOpenAddStatus()}>
                                  <i className='ti ti-plus' />
                                  <span>Add Status</span>
                              </Button>
                          </div> */}
                    </Select>
                  </FormControl>
                </div>
              </Box>
              <hr style={{ margin: '0px' }} />
              <CardContent>
                  <div style={{ position: 'relative' }}>
                {(!isLoadingForContractBilling && !isAllZeroForContractBilling) ?
                    <div className='chart_height' style={{ display: toggle ? 'block' : 'none' }} id="chart">
                      <div>
                        <ReactApexChart id="myChart"
                          series={contractBillingChartOption.series}
                          options={contractBillingChartOption.options} type="line" height={300}
                        />
                      </div>
                      <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ padding: '6px 8px' }} className='chart_button'>
                          Month
                        </div>
                      </Box>
                    </div>
                    : <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '10px', fontSize:'16px' }}>
                    <span >No records available for the selected year range.</span>
                  </Box>
                  }
                  </div>
                {/* <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                  <Button className='chart_button'>
                    Category
                  </Button>
                </Box> */}
              </CardContent>
            </Card>
          }
        </Grid>
        <Grid item xs={6}>
          {!isLoadingContractType &&
            <Card className='card_chartBox translate-20-holder'>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '13px' }}>
                <div className='assets_statusText'>
                  <h5>Contract Header Type Distribution</h5>
                  <p>You can see all the header type distribution related data here</p>
                </div>
              </Box>
              <hr style={{ margin: '0px' }} />
              <CardContent>
                <div style={{ position: 'relative' }}>
                  {/* {toggleOne ? */}<div className='chart_height' style={{ display: toggleOne ? 'block' : 'none' }} id="chart">
                    <div>
                      <ReactApexChart series={typeChartOption.series}
                        options={typeChartOption.options} type="pie" width="100%" height={300} className="pie_chart" />
                    </div>
                    <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ padding: '6px 8px' }} className='chart_button'>
                        
                      </div>
                    </Box>
                  </div>
                </div>
                {/* <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                <Button className='chart_button'>
                  Location
                </Button>
              </Box> */}
              </CardContent>
            </Card>
          }
        </Grid>
      </Grid>
    </>
  );
}
export default DashboardChart