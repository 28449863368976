import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef,useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@material-ui/core';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import { countries } from '@fuse/utils/countryName';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { setAssetsForRedirection } from '../../assets/store/filterParamsReducer';
import { openReportViewerDialog, setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { isArray } from "lodash";
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import DeleteConfirmModal from 'helper/DeleteConfirm';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	  },
	  root: {
		// minWidth: 275,
	  },
	  title: {
		fontSize: 14,
	  },
	  pos: {
		marginBottom: 12,
	  },
});

function ListView(props) {
   	const classes = useStyles();
	const table_name = "MANUFACTURER";
	const router = useRouter();
	const gridRef = useRef();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [gridApi, setGridApi] = useState(null);
	const [ windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [ displayButton, setDisplayButton ] = useState(false);
	const [ manufacturerDetails, setManufacturerDetails] = useState({})
	const [ openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [loadingActivityLog,setLoadingActivityLog] = useState(false);
	const skipHeader = [
		"id", "manufacturer_id", "platform_customer_id", "created_at", "updated_at_", 
		"entity_external_platform_id", "created_date", "updated_date","original_info",
		"models", "related_asset"
	]
	const tableHeaders = [
		"manufacturer", "url", "phone_number", "address_line_1", "address_line_2", "city", "country", "State/Province", "zip", "source", "number_of_assets"
	];
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [filterApplied, setFilterApplied] = useState(false);

	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const tab_value = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.tab_value
  );

	const handleClickOpen = () => {
		setOpenDeleteDialog(true);
	};

	const handleClose = () => {
		setOpenDeleteDialog(false);
	};

	function handelEdit(data){
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}
	
	function handelDelete(id){
		axios.delete(`/manufacturers/delete/${id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message);
			}
			else{
				handleClose()
				toast.success(res.data.message)
				props.refresh()
			}
		}).catch((err)=>{
			console.log(err)
			toast.error(err.response.data.message)
		})
	}


	function handelDeletev2() {
		if(manufacturerDetails.id !== undefined) {
			axios.delete(`/manufacturers/delete/${manufacturerDetails.id}`).then((res)=>{
				if(res.status === 201){
					toast.error(res.data.message);
				}
				else{
					handleClose()
					toast.success(res.data.message)
					props.refresh()
				}
			}).catch((err)=>{
				console.log(err)
				toast.error(err.response.data.message)
			})
		}
	}

	const handleDeleteDialogOpen = (data) => {
		handleClickOpen()
		setManufacturerDetails(data)
	}

	function formatString(str){
		if(!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const onGridReady = (params) =>{
		setGridApi(params);
		// props.endUsersAccounts.forEach((list) => {
		// 	for(let [fieldName, value] of Object.entries(list)){
		// 		if(value === '' || value == null ) list[fieldName] = `No ${formatString(fieldName)}`
		// 	}
		// })
		if(router.query.report_name == undefined) {
			loadInitialState (params)
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}
		else{
			setRowGroupPanelShow('always')
			getReport(params);
		}
	}
	
	function onFilterChanged(event){		
		const rows = event.api.getFilterModel();
		// to show No result found in column filter
		// dispatch(setfilterlist(rows));

		dispatch(setfilterlist(rows));
		saveView("N/A", rows, gridRef.current.filterModelArr)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "manufacturer";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState (params){
		axios.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then(results=>{
			const { data } = results.data;
			if (data !== null) {
				const order = JSON.parse(data.order);
				const filter = JSON.parse(data.filter);
				gridRef.current = {...gridRef.current,filterModelArr:filter}
				params?.columnApi.applyColumnState({ state: order });
				params?.api.setFilterModel(filter);
				if(Object.keys(filter)?.length){
				  setFilterApplied(true)
				}
			  }
		})
		.catch(err=>{
			console.log(err);
		})
  }

	function saveView(rows, filter, filterModelArr=null) {
    if(isArray(rows) && !rows.length ) return
    let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filterModelArr ?? filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 

    axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
  }

	const onColumnMoved = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
			if(router.query.report_name == undefined) {
				saveView(rows);
				dispatch(setrowGroupModel(rows))
			}
		}
	}
	function onSortChanged(params){
		const rows = params.columnApi.getColumnState();
    if (router.query.report_name == undefined) {
			dispatch(setrowGroupModel(rows))
      saveView(rows);
    }
		
  }

  function onColumnRowGroupChanged(event) {
	if(event.columns.length == 0) {
		setGroupByArr([])
	}else{
		setGroupByArr(event.columns)
	}
    if (router.query.report_name == undefined) {
      const rows = event.columnApi.getColumnState();
      const first_row = rows[0];
      if (first_row.colId !== "ag-Grid-AutoColumn") {
        dispatch(setrowGroupModel(rows));
        saveView(rows);
      } else {
        dispatch(setrowGroupModel(rows));
      }
    }
    // auto size group columns
    // onRowGroupOpenedAutoSize();
  }

	const onColumnPinned = (params) =>{
		const rows = params.columnApi.getColumnState();
		if(router.query.report_name == undefined) {			
			saveView(rows);
			dispatch(setrowGroupModel(rows))

		}
	}

	function onCellValueChanged(e){
		let manufacturer_name = e.data.manufacturer_name
		let phone_number = e.data.original_info.phone_number
		let address_line_1 = e.data.original_info.address_line_1 
		let address_line_2 = e.data.original_info.address_line_2 
		let city = e.data.original_info.city 
		let state = e.data.original_info.state
		let zip = e.data.original_info.zip
		let country = e.data.original_info.country
		let url = e.data.url.includes('www') ? e.data.url : "www." + e.data.url
		if(url.split('.').length != 3){
      toast.error('Please enter a valid URL')
			props.refresh()
			return
		}
		
		if(e.colDef.field == "manufacturer_name"){
			manufacturer_name = e.newValue
		}
		if(e.colDef.field == "phone_number"){
			phone_number = e.newValue
		}
		if(e.colDef.field == "address_line_1"){
			address_line_1 = e.newValue
		}
		if(e.colDef.field == "address_line_2"){
			address_line_2 = e.newValue
		}
		if(e.colDef.field == "city"){
			city = e.newValue
		}
		if(e.colDef.field == "state"){
			state = e.newValue
		}
		if(e.colDef.field == "zip"){
			zip = e.newValue
		}
		if(e.colDef.field == "url"){
			url = e.newValue
		}
		if(e.colDef.field == "country"){
			country = e.newValue
		}
		const payload = {
			manufacturer_name: e.data.manufacturer_name,
			phone_number: phone_number || '',
			address_line_1: address_line_1 || '',
			address_line_2: address_line_2 || '',
			city: city || '',
			state: state ||'',
			zip: zip || '',
			country: country || '',
			url: e.data.url.includes('www') ? e.data.url : "www."+e.data.url,
			edit_id: Number(e.data.id)
		}
		if(!payload.manufacturer_name){
			toast.error('Manufacturer Name is required')
			props.refresh()
			return
		} else if(!payload.url){
			toast.error('URL is required')
			props.refresh()
			return
		}
		if(phone_number && !phone_number.match(/[0-9]{3}-[0-9]{3}-[0-9]{4}$/)){
			toast.error('Phone number should be in 000-000-0000 format')
			props.refresh()
			return
		}
		axios.post('/manufacturers/edit', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
				toast.success('Manufacturer updated Successfully.')
				props.refresh()
			}		  
		}).catch(err => {
		  console.log('212=>',err);
		  toast.error('Something went wrong!')
		})
	}

	useEffect(() => {
		return () => {
			console.log("unmount")
				dispatch(setQuickFilter(null))
		}
	}, [])
	const onFirstDataRendered = (params) => {
		params.columnApi.sizeColumnsToFit(4500);
	};
	
	const onRangeSelectionChanged = (params) => {

		let columnCount = 0 ;
		let startRowIndex = 0 ;
		let endRowIndex =  0 ;
		
		const rows = params.api.getCellRanges();

		if(rows.length != 0){
			columnCount = params.api.getCellRanges()[0].columns.length ;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex ;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex ;			
		}

		if(columnCount > 1 || startRowIndex != endRowIndex  ){
			setDisplayButton(true);
		}else{
			setDisplayButton(false);
		}
	}

	const generateHeaderLabel = (header) => {
		if(header === "manufacturer_name") {
			return 'Manufacturer';
		}else if( header === "state" ) {
			return 'State/Province';
		}else {
			return header.split('_').map(word => {
				if(word === 'url'){
					return word.toUpperCase()
				}else{
					return word.charAt(0).toUpperCase() + word.substring(1)
				}
			}).join(' ')
		}
		
	}

	const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

	function redirectToAssetProfile(event){
		if(event.value == 0){
      return
    }
		// dispatch(setAssetsForRedirection(event.data.related_asset))
		// localStorage.setItem('manufacturer_id',event.data.id)
		openInNewTab(`/assets-list?manufacturer_id=${event.data.id}`)
		// router.push('/assets');
	}

	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	function handleAddManufacturer(){
		dispatch(openFormViewerDialog())
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data
		);
	},[query_data])

	useEffect(() => {
		const onBtStopEditing = () => {
			gridRef?.current?.api?.stopEditing();
		}
		window.addEventListener('click',onBtStopEditing);
		return () => {
			window.removeEventListener('click', onBtStopEditing);
		};
	},[])

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	//***************Logs***************//
	const [logs,setLogs] = useState([]);

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				name: item.user_name,
				manufacturer_name: item?.manufacturer?.manufacturer_name || item.manufacturer_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
			}
		})
	}

	function getLogs(){
		setLoadingActivityLog(true)
		axios.get('/manufacturers/activities')
		.then(({data})=>{
			setLoadingActivityLog(false)
			setLogs(formatLogs(data.data.activities));
			// setLogs(data.data.activities);
		})
		.catch(err=>{
			console.log(err)
			console.log('cannot fetch logs');
		})
	}

	useEffect(()=>{
		(tabValue == 1) && getLogs()
	},[tabValue])

	useEffect(()=>{
    setTabValue(tab_value)
  }, [tab_value])

  const handleAddReport = () => {
    dispatch(openReportViewerDialog())
  }
	return (	
		<>
		    {(tabValue == 0) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'Manufacturer'} logs={logs} displayTableName = {'Manufacturers'}
				 onClick={handleChildClick} onClickAddButton={handleAddManufacturer}
				 addPermission = {props.createManufacturersPermission}
				 onClickAddReportButton={handleAddReport}
				 
				 />}
				{(tabValue == 1) && (<ActivityAgGridListing module={'Manufacture'} logs={logs} displayTableName = {'Manufacturers'}
				addPermission = {props.createManufacturersPermission}
				loadingActivityLog={loadingActivityLog}
				/>)}

				{(tabValue == 0) && <div 
					className="ag-theme-alpine" 
					style={{
						width: "100%",
						height:windowHeight+105,
						fontSize:'12px'
					}}
				>
					<AgGridReact
						ref={gridRef}
						suppressRowClickSelection={true}
						onGridReady = { onGridReady }
						onFirstDataRendered={onFirstDataRendered}
						animateRows = { true }
						rowData={props.endUsersAccounts}
						rowHeight = {30}
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}

						// Row Grouping conditions
						showOpenedGroup={true}
						suppressDragLeaveHidesColumns={true}
						suppressMakeColumnVisibleAfterUnGroup={true}
						rowGroupPanelShow={ rowGroupPanelShow }

						onColumnMoved = { onColumnMoved }
						onColumnPinned = { onColumnPinned }
						onColumnVisible = { onColumnVisible }
						onSortChanged = { onSortChanged }
						groupHeaderHeight ={1}
						// headerHeight = {15}
						floatingFiltersHeight = {40}

						// status bar enabled on range selection
						enableRangeSelection={ true }
						frameworkComponents={frameworkComponents}
						statusBar={{
							statusPanels: [
							{ 
								statusPanel: 'agAggregationComponent' },
							],
						}}

						// to check the the no. of rows selected during range selection
						onRangeSelectionChanged = { onRangeSelectionChanged }						

						onFilterChanged = { onFilterChanged }	
						
						// on column group changed
						onColumnRowGroupChanged = { onColumnRowGroupChanged }

						cacheQuickFilter={true}
						
						pagination={true}
						defaultColDef={{
							// flex: 1,
							// minWidth: 100,
							enableRowGroup :true,
							sortable: true,
							resizable: true
						}}
					>
						<AgGridColumn
							lockPosition={true}
							rowSelection={'single'}
							width={100}
							minWidth={100}
							maxWidth={100}
							suppressSizeToFit={true}
							headerName="Actions"
							pinned= 'right' 
							className="action_field"
							lockPinned={true}
							cellRendererFramework={({data}) =>{
								if(data){
									return (
										<>
											{(props.updateManufacturersPermission ) && 
												<Tooltip id="edit" title="Edit" placement="top">
													{/* <Icon 
														onClick={() => handelEdit(data)} 
														style={{
																color: '#1d2939',
																paddingBottom: '3px',
																paddingRight:'30px',
																fontSize:'15px',
																cursor:'pointer'
															}}
														>
															edit
														</Icon> */}
													<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-pencil" onClick={() => handelEdit(data)} />
												</Tooltip>
											}

											{(props.deleteManufacturersPermission ) && 
												<Tooltip id="delete" title="Delete" placement="top">
													{/* <Icon 
														onClick={() => handleDeleteDialogOpen(data)} 
														style={{
															color: '#1d2939',
															paddingBottom: '3px',
															paddingRight:'30px',
															fontSize:'15px',
															cursor:'pointer'
														}}
													>
														delete
													</Icon> */}
													<i style={{color: '#1D2939', fontSize: '20px', padding: '0 6px', cursor: 'pointer'}} className="ti ti-trash" onClick={() => handleDeleteDialogOpen(data)} />
												</Tooltip>
											}
										</>
									)
								}else{
									return (<></>)
								}
							}}
						>
						</AgGridColumn>
						{tableHeaders.map(header => {
							if(!skipHeader.includes(header)){
								if(header == 'country'){
									return (
										<AgGridColumn 
											key = {header}
											field = {header}
											suppressSizeToFit = {true}
											suppressRowClickSelection = {true}
											editable = {props.updateManufacturersPermission}										
											onCellValueChanged = {onCellValueChanged}
											headerName = {generateHeaderLabel(header)}  
											sortable = {true}
											filter = "text"
											flex = {1}
											minWidth={200}
											// floatingFilter = {true}
											
											cellEditorParams= {{values:countries.map(item=>`${item.name}`)}}
											cellRenderer= 'GenderRenderer'
											cellEditor= 'agRichSelectCellEditor'
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										>
										</AgGridColumn>
									)
								} else if(header == 'number_of_assets'){
									return (
											<AgGridColumn 
												key = {header}
												field = {header}
												suppressSizeToFit = {true}
												suppressRowClickSelection = {true}
												headerName = {generateHeaderLabel(header)}  
												sortable = {true}
												filter = "text"
												flex = {1}
												minWidth={200}
												// floatingFilter = {true}
												
												headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
												cellRendererFramework={(event) =>{
													return (<a 
														style={{
															height:'10px',
															width:'10px',
															color: 'black',
															cursor: 'pointer',
															marginTop: '2px',
															borderRadius: '50%',
															display: 'inline-block',
														}} 
														onClick={()=> redirectToAssetProfile(event)} 
														variant="subtitle1"
													>
														{<><span>{event.value}</span></>}
													</a>)
												}}
											>
											</AgGridColumn>
									)
								}
								else if(header == 'source'){
									return (
											<AgGridColumn 
												key = {header}
												field = {header}
												suppressSizeToFit = {true}
												suppressRowClickSelection = {true}
												headerName = {generateHeaderLabel(header)}  
												sortable = {true}
												filter = "text"
												flex = {1}
												minWidth={200}
												headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
												// floatingFilter = {true}
												
											>
											</AgGridColumn>
									)
								}
								return(
									<AgGridColumn 
										key = {header} 
										field = {header}
										suppressSizeToFit = {true}
										editable = {props.updateManufacturersPermission}										
										onCellValueChanged = {onCellValueChanged}
										headerName = {generateHeaderLabel(header)}  
										sortable = {true} 
										filter = "text"
										flex = {1}
										minWidth={200}
										headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										// floatingFilter = {true} 
										
									></AgGridColumn>
								)
							}							
						})}
					</AgGridReact>
					{/* <DeleteModelDialog
						openDeleteDialog={openDeleteDialog}
						manufacturerDetails={manufacturerDetails}
						handelDelete={handelDelete}
						handleClose={handleClose}
					/> */}

					{
						openDeleteDialog &&


						<DeleteConfirmModal 
							openAlerModal={openDeleteDialog}
							module={'Manufacturer'}
							handleCloseAlertModal={handleClose} 
							handleAlertModal={handelDeletev2}
						/>
					}
				</div>}
		</>			
	);
}

export default ListView

function DeleteModelDialog(props) {
	return (
	  <div>
		<Dialog
		  open={props.openDeleteDialog}
		  onClose={props.handleClose}
		  aria-labelledby="alert-dialog-title"
		  aria-describedby="alert-dialog-description"
		>
		  <DialogTitle id="alert-dialog-title">
			{"Delete Manufacturer"}
		  </DialogTitle>
		  <DialogContent>
			<DialogContentText id="alert-dialog-description">
			  <h4>Are you sure to delete this Manufacturer named as <b>{props.manufacturerDetails?.manufacturer_name}</b>?</h4>
			</DialogContentText>
		  </DialogContent>
		  <DialogActions>
			<Button onClick={props.handleClose}>Cancel</Button>
			<Button onClick={() => props.handelDelete(props.manufacturerDetails.id)} autoFocus>
			  Delete
			</Button>
		  </DialogActions>
		</Dialog>
	  </div>
	)  
}
