import React, { useRef,useState,useEffect } from 'react'
import { downloadFile } from '@fuse/utils/fileUtils';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { Modal } from '@material-ui/core'
import { Icon, IconButton} from '@mui/material'
import { withRouter } from 'react-router-dom';
import { Tooltip } from "@material-ui/core";
import axios from '@fuse/utils/axios';
import FilePreviewModal from 'helper/filePreview';


function FilesTab({serviceRequestId}) {
    const tableHeaders = ["file_name", "upload_date"];
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 430);
    const gridRef = useRef();
    const [ serviceRequestFiles, setServiceRequestFiles ] = useState([])

    const [filePreviewLink, setFilePreviewLink] = useState('')
	const [openFilePreviewLink, setOpenFilePreviewLink] = useState(false)
	const [fileName, setfileName] = useState('')

    const onGridReady = params => {
		// Following line to make the currently visible columns fit the screen  
		params.api.sizeColumnsToFit();

		// Following line dynamic set height to row on content
		params.api.resetRowHeights();
	};


    function setHeaderName(header) {
      return header
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
    }

    function fetchFiles(){
      if(!serviceRequestId) return
      axios.get(`/service/request/get-files/${serviceRequestId}`).then((res)=>{
          const { files } = res.data.data;
          setServiceRequestFiles(files)
      }).catch((err)=>{   
          console.log(err);
      })
    }

    useEffect(()=>{
        fetchFiles()
    },[serviceRequestId])

    function handleClose() {
      setOpenFilePreviewLink(false)
      setFilePreviewLink('')
      setfileName('')
    }

    return (
        <>
        {serviceRequestFiles.length === 0 && 
                    <div className='no_data_holder'>
                        <img src='assets/images/nodata.svg' alt='icon' />
                        <p className='bold_txt'>It's all empty here!</p>
                        <p className='normal_txt'>No data to show yet</p>
                    </div>
                }

        {serviceRequestFiles.length > 0 && 
            <div
             className="ag-theme-alpine"
             style={{
               width: "100%",
               height: windowHeight + 105,
               fontSize: '12px'
             }}
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={serviceRequestFiles}
                    animateRows
                    onGridReady={onGridReady}
                    paginationPageSize={10}
                    pagination
                    defaultColDef={{
                        resizable: true
                    }}
                >
                    <AgGridColumn
              lockPosition={true}
              rowSelection={"single"}
              width={120}
              minWidth={120}
              maxWidth={120}
              suppressSizeToFit={true}
              headerName="Actions"
              pinned="right"
              className="action_field"
              lockPinned={true}
              cellRendererFramework={({ data }) => {
                if (data) {
                  return (
                    <>
                      { <Tooltip id="view" title="View" placement="top">
                        <Icon
                          onClick={() => {
                            setOpenFilePreviewLink(true)
                            setFilePreviewLink(data.file_link)
                            setfileName(data?.file_name)
                        }}
                          style={{
                            color: "#1d2939",
                            paddingBottom: "5px",
                            paddingRight: "30px",
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                        >
                          visibility
                        </Icon>
                      </Tooltip>}

                      <Icon 
                        onClick={()=>downloadFile(data.file_link,data.file_name)} 
                        style={{cursor: 'pointer',color: '#1D2939', fontSize: '18px', padding: '4px 4px'}} 
                        title="Download"
                        >
                            download
                        </Icon> 
                            
                    </>
                  );
                } else {
                  return <></>;
                }
              }}
            ></AgGridColumn>
                {tableHeaders.map(header => {
                    return (
                        <AgGridColumn
                      field={header}
                      key={header}
                      flex = {1}
                      minWidth={200}
                      headerName={setHeaderName(header)}
                      sortable={true}
                      headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}                      
                      filter="text"
                    ></AgGridColumn>
                    )
                })}
                </AgGridReact>

                {
                  openFilePreviewLink && <FilePreviewModal
                    open={openFilePreviewLink}
                    close={handleClose}
                    filePreviewLink={filePreviewLink}
                    fileName={fileName}
                  />
                }
           </div>
        }
        </>
    )
}

export default withRouter(FilesTab)
